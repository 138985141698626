import { all, fork } from 'redux-saga/effects';
import {
  fetchTableActionTriad,
  fetchDetailActionTriad,
  submitFormActionTriad,
} from '../helpers';
import {
  WAREHOUSE_QUANTITIES_CREATE,
  WAREHOUSE_QUANTITIES_DELETE,
  WAREHOUSE_QUANTITIES_FETCH_DETAIL,
  WAREHOUSE_QUANTITIES_FETCH_LIST,
  WAREHOUSE_QUANTITIES_UPDATE,
} from '../action_codes';
import {
  warehouseQuantitiesActionError,
  warehouseQuantitiesActionSuccess,
  warehouseQuantitiesFetchDetailSuccess,
  warehouseQuantitiesFetchError,
  warehouseQuantitiesFetchListSuccess,
} from './actions';

const fetchWarehouseQuantitiesWatcher = fetchTableActionTriad(
  WAREHOUSE_QUANTITIES_FETCH_LIST,
  'warehouse-quantities/',
  warehouseQuantitiesFetchListSuccess,
  warehouseQuantitiesFetchError
).watcherFunction;

const fetchWarehouseQuantitiesDetail = fetchDetailActionTriad(
  WAREHOUSE_QUANTITIES_FETCH_DETAIL,
  warehouseQuantitiesFetchDetailSuccess,
  warehouseQuantitiesFetchError
).watcherFunction;

const createWarehouseQuantityWatcher = submitFormActionTriad(
  WAREHOUSE_QUANTITIES_CREATE,
  'POST',
  warehouseQuantitiesActionSuccess,
  warehouseQuantitiesActionError
).watcherFunction;

const updateWarehouseQuantityWatcher = submitFormActionTriad(
  WAREHOUSE_QUANTITIES_UPDATE,
  'PUT',
  warehouseQuantitiesActionSuccess,
  warehouseQuantitiesActionError
).watcherFunction;

const deleteWarehouseQuantityWatcher = submitFormActionTriad(
  WAREHOUSE_QUANTITIES_DELETE,
  'DELETE',
  warehouseQuantitiesActionSuccess,
  warehouseQuantitiesActionError
).watcherFunction;

export default function* rootSaga() {
  yield all([
    fork(fetchWarehouseQuantitiesWatcher),
    fork(fetchWarehouseQuantitiesDetail),
    fork(createWarehouseQuantityWatcher),
    fork(updateWarehouseQuantityWatcher),
    fork(deleteWarehouseQuantityWatcher),
  ]);
}
