import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Container } from './Container';

const DnDSortableView = ({ children, onDrop }) => (
  <div>
    <DndProvider backend={HTML5Backend}>
      <Container onDrop={onDrop}>
        {children}
      </Container>
    </DndProvider>
  </div>
);

export default DnDSortableView;
