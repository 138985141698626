import axios from 'axios';
import { hmacAdapter } from './common';

const adapter = hmacAdapter(axios);

const LoginApi = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API_URL,
  adapter,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'X-Apikey': process.env.REACT_APP_API_KEY,
    'Access-Control-Allow-Origin': '*',
  },
});

export default LoginApi;
