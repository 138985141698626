import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUser } from './Utils';
import { isAuthGuardActive } from '../constants/defaultValues';
import hasPermission from '../roleConstraints';

const ProtectedRoute = ({ component: Component, permissions, ...rest }) => {
  const setComponent = (props) => {
    if (isAuthGuardActive) {
      const currentUser = getCurrentUser();
      if (currentUser) {
        if (hasPermission(permissions, currentUser)) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/unauthorized',
              state: { from: props.location },
            }}
          />
        );
      }
      return (
        <Redirect
          to={{
            pathname: '/user/login',
            state: { from: props.location },
          }}
        />
      );
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={setComponent} />;
};
const UserRole = {
  Admin: 'Administrators',
  Warehouse: 'Sklad',
  Accountant: 'Uctovne',
  User: 'Users',
};

export { ProtectedRoute, UserRole };
