import {
  all,
  fork,
  takeLatest,
  takeEvery,
  put,
  call,
  cancelled,
} from 'redux-saga/effects';
import Axios from 'axios';
import invariant from 'invariant';
import { fetchActionAsync, submitActionAsync } from './apiAdapter';
import { interpretResponseCode } from '../../errorHandling';
import { fetchTableAsync } from '../helpers';

/* eslint-disable func-names */

function* fetchTableRoutine({ payload }, url, sliceName) {
  const { sort, page, limit } = payload;
  invariant(url, 'url not specified');
  invariant(sliceName, 'sliceName not specified');
  if (sort) {
    console.error('Sort parameter no longer supported, use multiSort instead');
  }
  if (page === 0 && limit) {
    console.error(payload);
    throw Error('Zero page not allowed, paging is 1-based');
  }

  const finalUrl = typeof url === 'function' ? url(payload) : url;
  const cancelSource = Axios.CancelToken.source();
  try {
    const response = yield call(
      fetchTableAsync,
      finalUrl,
      payload,
      cancelSource.token,
      null
    );
    if (response.message) {
      yield put({
        type: 'display/exception',
        payload: { message: response.message },
      });
    } else {
      const { items, metadata } = response;
      yield put({
        type: `${sliceName}/setTable`,
        payload: { items, metadata },
      });
    }
  } catch (error) {
    const message = interpretResponseCode(error);
    yield put({
      type: 'display/exception',
      payload: { message },
    });
  } finally {
    if (yield cancelled()) {
      cancelSource.cancel();
      // console.log(cancelSource.token);
    }
  }
}

const createCrudSaga = ({ sliceName, rootUrl, extensions = [] }) => {
  function* callFetchTableRoutine(action) {
    yield fetchTableRoutine(action, rootUrl, sliceName);
  }
  const fetchTableWatcher = function* () {
    // console.log('fetchTableWatcher: ', `${sliceName}/fetchData`);
    yield takeLatest(`${sliceName}/fetchData`, callFetchTableRoutine);
  };

  const fetchDetailAsync = function* (action) {
    yield fetchActionAsync(
      action,
      action.payload.url,
      `${sliceName}/setObjectDetail`
    );
  };

  const fetchDetailWatcher = function* () {
    yield takeLatest(`${sliceName}/fetchDetail`, fetchDetailAsync);
  };

  const submitDataAsync = function* (action) {
    // console.log('submitDataAsync', action);
    yield submitActionAsync(action);
    console.log('attempting to clear list');
    yield put({ type: `${sliceName}/clearList` });
  };

  const submitWatcher = function* () {
    // console.log('watching', `${sliceName}/submitData`);
    yield takeEvery(`${sliceName}/submitData`, submitDataAsync);
  };

  return function* () {
    yield all(
      [
        fork(fetchTableWatcher),
        fork(fetchDetailWatcher),
        fork(submitWatcher),
      ].concat(extensions.map((e) => fork(e)))
    );
  };
};

export default createCrudSaga;
