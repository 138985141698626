/* eslint-disable no-unused-vars */
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import ordersSagas from './orders/saga';
import globalsSagas from './globals/saga';
import warehousesSagas from './warehouses/saga';
import reportsSagas from './reports/saga';
import settingsSagas from './settings/saga';
import materialSagas from './materials/saga';
import deliveryListReduxWrapper from './warehouses/delivery_list_wrapper';
import auditSagas from './audits/saga';
import movementSagas from './movement/saga';
import createCrudSaga from './factories/sagaFactories';
import productionCrudExtensions from './production/productionCrudExtensions';
import { createReportSaga } from './factories/reportReduxFactory';
import { createRelatedMovementsSaga } from './relatedMovements';
import { createProfileSagas } from './profile';
import accountingSaga from './accounting/saga';
import createRecountsSaga from './recounts/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    ordersSagas(),
    globalsSagas(),
    warehousesSagas(),
    reportsSagas(),
    settingsSagas(),
    materialSagas(),
    deliveryListReduxWrapper.saga(),
    auditSagas(),
    movementSagas(),
    createCrudSaga({
      sliceName: 'ingredients',
      rootUrl: 'recipe-ingredients/',
    })(),
    createCrudSaga({
      sliceName: 'products',
      rootUrl: 'materials/',
    })(),
    createCrudSaga({
      sliceName: 'production',
      rootUrl: 'production/',
      extensions: productionCrudExtensions.extraSagas,
    })(),
    createCrudSaga({
      sliceName: 'accounting',
      rootUrl: 'accounting-logs/',
    })(),
    createReportSaga({
      sliceName: 'reportCardMaterial',
    })(),
    createReportSaga({
      sliceName: 'reportMovements',
    })(),
    createReportSaga({
      sliceName: 'reportCardCode',
    })(),
    createReportSaga({
      sliceName: 'reportSummary',
    })(),
    createRelatedMovementsSaga()(),
    createProfileSagas()(),
    accountingSaga(),
    createRecountsSaga()(),
  ]);
}
