import { pathOr } from 'rambda';
import createCrudReducerSlice from '../factories/reducerFactories';
/* eslint-disable no-param-reassign */
/* no-param-reassign required: https://redux-toolkit.js.org/usage/immer-reducers */

const createRecountsReducer = () => {
  return createCrudReducerSlice({
    sliceName: 'recounts',
    extraFields: {
      count: 0,
    },
    extraReducers: {
      setCount: (state, action) => {
        state.count = pathOr(null, 'payload.response.count', action);
      },
    },
    initialSort: ['-created_at'],
  });
};

export default createRecountsReducer;
