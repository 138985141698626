const buildTableQueryParams = (page, limit, multiSort, filter) => {
  const params = new URLSearchParams();
  if (page !== null && page !== undefined) {
    params.append('page', page);
  }
  if (limit) {
    params.append('limit', limit);
  }
  if (multiSort && multiSort.length > 0) {
    const sortList = multiSort.join(',');
    params.append('order_by', sortList);
  }

  if (filter) {
    Object.keys(filter).forEach((key) => {
      const value = filter[key];
      if (value) {
        if (Array.isArray(value)) {
          value.forEach((v) => params.append(key, v));
        } else {
          params.append(key, value);
        }
      }
    });
  }
  return params;
};

const makeOrderingQuery = (order) => {
  if (order && order.length > 0) {
    return { order_by: order.join(',') };
  }
  return null;
};

export { buildTableQueryParams, makeOrderingQuery };
