export const loginPath = '/user/login';

export const orderListPath = '/orders/list';
export const orderDetailPath = '/orders/detail/:orderId';
export const orderNewPath = '/orders/new';

export const warehousesStockInPath = '/warehouses/stock-in';
export const warehousesStockOutPath = '/warehouses/stock-out';
export const warehousesMovementEditPath =
  '/warehouses/movements/edit/:warehouseMovementId';
export const warehousesStockMovements = '/warehouses/movements';
export const warehousesInventoryPath = '/warehouses/inventory';
export const warehousesInvoicesListPath = '/warehouses/invoices';
export const warehousesInvoicesNewPath = '/warehouses/invoices/new';
export const warehousesInvoicesEditPath =
  '/warehouses/invoices/edit/:invoiceId';
export const warehousesDeliveryListsListPath = '/warehouses/delivery-lists';
export const warehousesTransferPath = '/warehouses/transfer';
export const warehousesTransfersListPath = '/warehouses/transfers';
export const warehousesDeliveryListsEditPath =
  '/warehouses/delivery-lists/edit/:deliveryListId';
export const warehousesDeliveryListsNewPath = '/warehouses/delivery-lists/new';
export const warehousesInvoicesTollListPath = '/warehouses/invoices-toll';
export const warehousesInvoicesTollNewPath = '/warehouses/invoices-toll/new';
export const warehousesInvoicesTollEditPath =
  '/warehouses/invoices-toll/edit/:invoiceId';
export const warehousesInvoicesTransportListPath =
  '/warehouses/invoices-transport';
export const warehousesInvoicesTransportNewPath =
  '/warehouses/invoices-transport/new';
export const warehousesInvoicesTransportEditPath =
  '/warehouses/invoices-transport/edit/:invoiceId';

export const reportsPath = '/reports';
export const reportsMovementsPath = '/reports/movements';
export const reportsCardByMaterialPath = '/reports/cards-material';
export const reportsCardByCodePath = '/reports/cards-code';
export const reportsMovementsSummaryPath = '/reports/movements-summary';

export const homePath = '/';

export const settingsPath = '/settings';
export const settingsOrderStatesPath = '/settings/order-states';
export const settingsOrderStatusDetailPath =
  '/settings/order-states/detail/:statusId';
export const settingsOrderStatusNewPath = '/settings/order-states/new';
export const settingsMaterialTagsPath = '/settings/material-tags';
export const settingsMaterialTypesPath = '/settings/material-types';
export const settingsMaterialsListPath = '/settings/materials/list';
export const settingsMaterialsNewPath = '/settings/materials/new';
export const settingsMaterialsEditPath = '/settings/materials/edit/:materialId';

export const settingsTagListPath = '/settings/tags/list';

export const settingsSuppliersListPath = '/settings/suppliers/list';
export const settingsSuppliersNewPath = '/settings/suppliers/new';
export const settingsSuppliersEditPath = '/settings/suppliers/edit/:supplierId';

export const settingsWarehousesListPath = '/settings/warehouses/list';
export const settingsWarehousesNewPath = '/settings/warehouses/new';
export const settingsWarehousesEditPath =
  '/settings/warehouses/edit/:warehouseId';

export const settingsPurposesListPath = '/settings/purposes';
export const settingsUsersListPath = '/settings/users';
export const settingsUsersNewPath = '/settings/users/new';
export const settingsUsersEditPath = '/settings/users/edit/:userId';

export const settingsLDAPUsersSetPwdPath =
  '/settings/users/ldap/set-password/:userId';
export const settingsLDAPUsersEditPath = '/settings/users/ldap/edit/:userId';
export const settingsUserGroupsListPath = '/settings/user-groups';

export const settingsProductNew = '/settings/products/new';
export const settingsProductDetail = '/settings/products/edit/:productId';
export const settingsProductList = '/settings/products';

export const userChangePasswordPath = '/profile/change-password';

export const userLDAPChangePasswordPath = '/ldap/change-password';
export const userProfilePath = '/profile/settings';

export const productionListPath = '/production';
export const productionNewPath = '/production/new';
export const productionEditPath = '/production/edit/:productionId';

export const accountingStockInPath = '/accounting/stock-in';
export const accountingStockOutPath = '/accounting/stock-out';
export const accountingMovementsPath = '/accounting/movements';
export const accountingInventoryPath = '/accounting/inventory';

export const auditPath = '/audit';

export const recountsPath = '/recounts';
