import {
  MOVEMENT_LOOKUP_ALL_MATERIALS,
  MOVEMENT_LOOKUP_ALL_MATERIALS_SUCCESS,
  MOVEMENT_LOOKUP_CLEAR,
  MOVEMENT_LOOKUP_ERROR,
  MOVEMENT_LOOKUP_MATERIAL,
  MOVEMENT_LOOKUP_MATERIAL_SUCCESS,
  MOVEMENT_LOOKUP_SUPPLIERS,
  MOVEMENT_LOOKUP_SUPPLIERS_SUCCESS,
  MOVEMENT_LOOKUP_WAREHOUSES,
  MOVEMENT_LOOKUP_WAREHOUSES_SUCCESS,
  MOVEMENT_LOOKUP_CODE,
  MOVEMENT_LOOKUP_CODE_SUCCESS,
  MOVEMENT_LOOKUP_CODE_ERROR,
  MOVEMENT_LOOKUP_BATCHES_SUCCESS,
  MOVEMENT_LOOKUP_DETAIL,
  MOVEMENT_LOOKUP_DETAIL_SUCCESS,
  MOVEMENT_LOOKUP_DETAIL_ERROR,
} from '../action_codes';

const INIT_STATE = {
  loading: false,
  error: '',
  material: null,
  allMaterials: null,
  warehouses: null,
  suppliers: null,
  lookupResult: null,
  lookupCodeLoading: false,
  lookupCodeError: '',
  batches: null,
  detail: null,
  detailLoading: false,
  detailError: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MOVEMENT_LOOKUP_ALL_MATERIALS:
      return { ...state, loading: true };
    case MOVEMENT_LOOKUP_ALL_MATERIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        allMaterials: action.payload.data,
      };
    case MOVEMENT_LOOKUP_MATERIAL:
      return { ...state, loading: true };
    case MOVEMENT_LOOKUP_MATERIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        material: action.payload.data,
      };
    case MOVEMENT_LOOKUP_WAREHOUSES:
      return { ...state, loading: true };
    case MOVEMENT_LOOKUP_WAREHOUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        warehouses: action.payload.data,
      };
    case MOVEMENT_LOOKUP_SUPPLIERS:
      return { ...state, loading: true };
    case MOVEMENT_LOOKUP_SUPPLIERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        suppliers: action.payload.data,
      };
    case MOVEMENT_LOOKUP_CLEAR:
      return {
        ...state,
        allMaterials: null,
        material: null,
        warehouses: null,
        suppliers: null,
      };
    case MOVEMENT_LOOKUP_ERROR:
      return { ...state, loading: false, error: action.payload.messages };
    case MOVEMENT_LOOKUP_CODE:
      return {
        ...state,
        lookupLoading: true,
        lookupError: '',
        lookupResult: null,
      };
    case MOVEMENT_LOOKUP_CODE_SUCCESS:
      return {
        ...state,
        lookupLoading: false,
        lookupError: '',
        lookupResult: action.payload.result,
      };
    case MOVEMENT_LOOKUP_CODE_ERROR:
      return {
        ...state,
        lookupLoading: false,
        lookupError: action.payload.message,
        lookupResult: null,
      };
    case MOVEMENT_LOOKUP_BATCHES_SUCCESS:
      return {
        ...state,
        batches: action.payload.data,
      };
    case MOVEMENT_LOOKUP_DETAIL:
      return {
        ...state,
        detailLoading: true,
      };
    case MOVEMENT_LOOKUP_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.payload.data,
        detailLoading: false,
        detailError: '',
      };
    case MOVEMENT_LOOKUP_DETAIL_ERROR:
      return {
        ...state,
        detailLoading: false,
        detailError: action.payload.messages,
      };
    default:
      return { ...state };
  }
};
