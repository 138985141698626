import {
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_ERROR,
  FETCH_USER_DETAILS_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  PROFILE_CHANGE_PASSWORD,
  PROFILE_CHANGE_PASSWORD_ERROR,
  PROFILE_CHANGE_PASSWORD_SUCCESS,
} from '../action_codes';

const INIT_STATE = {
  currentUser: {},
  token: null,
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        error: '',
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case LOGOUT_USER:
      return { ...state, currentUser: null, error: '' };
    case FETCH_USER_DETAILS:
      return { ...state };
    case FETCH_USER_DETAILS_SUCCESS:
      return { ...state, currentUser: action.payload.user };
    case FETCH_USER_DETAILS_ERROR:
      return { ...state, error: action.payload.message };
    case PROFILE_CHANGE_PASSWORD:
      return { ...state, loading: true };
    case PROFILE_CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: '' };
    case PROFILE_CHANGE_PASSWORD_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    default:
      return { ...state };
  }
};
