import { combineReducers } from 'redux';
import settings from './settings/reducer';
import createDisplayReducer from './display/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import orders from './orders/reducer';
import globals from './globals/reducer';
import warehouses from './warehouses/reducer';
import reports from './reports/reducer';
import materials from './materials/reducer';
import deliveryListReduxWrapper from './warehouses/delivery_list_wrapper';
import audits from './audits/reducer';
import movement from './movement/reducer';
import createCrudReducerSlice from './factories/reducerFactories';
import productionCrudExtensions from './production/productionCrudExtensions';
import { createReportReducer } from './factories/reportReduxFactory';
import { createRelatedMovementsSlice } from './relatedMovements';
import { createProfileReducer } from './profile';
import accountingInventory from './accounting/reducer';
import createRecountsReducer from "./recounts/reducer";

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  orders,
  globals,
  warehouses,
  reports,
  materials,
  deliveryLists: deliveryListReduxWrapper.reducer,
  audits,
  movement,
  ingredients: createCrudReducerSlice({
    sliceName: 'ingredients',
    initialSort: ['name'],
  }),
  products: createCrudReducerSlice({
    sliceName: 'products',
    initialSort: ['name'],
  }),
  production: createCrudReducerSlice({
    sliceName: 'production',
    extraFields: productionCrudExtensions.extraFields,
    extraReducers: productionCrudExtensions.extraReducers,
  }),
  accounting: createCrudReducerSlice({
    sliceName: 'accounting',
    initialSort: ['created_at'],
  }),
  accountingInventory,
  reportCardMaterial: createReportReducer({
    sliceName: 'reportCardMaterial',
  }),
  reportCardCode: createReportReducer({
    sliceName: 'reportCardCode',
  }),
  reportMovements: createReportReducer({
    sliceName: 'reportMovements',
  }),
  reportSummary: createReportReducer({
    sliceName: 'reportSummary',
  }),
  display: createDisplayReducer(),
  relatedMovements: createRelatedMovementsSlice(),
  profile: createProfileReducer(),
  recounts: createRecountsReducer(),
});

export default reducers;
