import { pathOr } from 'rambda';

const interpretResponseCode = (error) => {
  const errorCode = pathOr(0, 'response.status', error);
  switch (errorCode) {
    case 500:
      return 'Chyba servera';
    case 422:
      return 'Chybajuce udaje';
    case 401:
      return 'Nespravne meno alebo heslo';
    case 409:
      return '';
    case 0:
      return 'Chyba spojenia';
    default:
      return 'Neznama chyba';
  }
  // console.log(JSON.stringify(error));
};

// eslint-disable-next-line import/prefer-default-export
export { interpretResponseCode };
