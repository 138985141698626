import {
  ACCOUNTING_FETCH_INVENTORY,
  ACCOUNTING_FETCH_INVENTORY_SUCCESS,
  ACCOUNTING_FETCH_INVENTORY_ERROR,
  ACCOUNTING_INVENTORY_UPDATE_FILTER,
} from '../action_codes';

export const accountingFetchInventory = (multiSort, page, limit, filter) => ({
  type: ACCOUNTING_FETCH_INVENTORY,
  payload: { multiSort, page, limit, filter },
});

export const accountingFetchInventorySuccess = (data, meta) => ({
  type: ACCOUNTING_FETCH_INVENTORY_SUCCESS,
  payload: { data, meta },
});

export const accountingFetchInventoryError = (message) => ({
  type: ACCOUNTING_FETCH_INVENTORY_ERROR,
  payload: { message },
});

export const accountingUpdateFilter = (filter) => ({
  type: ACCOUNTING_INVENTORY_UPDATE_FILTER,
  payload: { filter },
});
