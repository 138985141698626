import { all, fork } from 'redux-saga/effects';
import {
  warehouseMovementSubmitSuccess,
  warehouseMovementSubmitError,
  warehousesFetchMovementsListSuccess,
  warehousesFetchMovementsListError,
  warehousesFetchInventoryListSuccess,
  warehousesFetchInventoryListError,
  warehousesLookupDetailsSuccess,
  warehousesLookupDetailsError,
  warehousesInvoicesFetchListSuccess,
  warehousesInvoicesFetchError,
  warehousesInvoicesFetchDetailSuccess,
  warehousesInvoicesActionSuccess,
  warehousesInvoicesActionError,
  warehousesInvoicesFetchDeliveryListsSuccess,
  warehousesMovementDetailFetchSuccess,
  warehousesMovementDetailError,
  warehousesMovementDetailSubmitSuccess,
  warehousesInvoicesTollFetchListSuccess,
  warehousesInvoicesTollFetchError,
  warehousesInvoicesTollActionSuccess,
  warehousesInvoicesTollActionError,
  warehousesInvoicesTollFetchDetailSuccess,
  warehousesInvoicesTransportFetchListSuccess,
  warehousesInvoicesTransportFetchError,
  warehousesInvoicesTransportActionSuccess,
  warehousesInvoicesTransportActionError,
  warehousesInvoicesTransportFetchDetailSuccess,
  warehousesFetchAvailPurposesSuccess,
  warehousesFetchAvailPurposesFailure,
  warehousesTransferDetailFetchSuccess,
  warehousesTransferDetailError,
  warehousesFetchTransfersListSuccess,
  warehousesFetchTransfersListError,
} from './actions';
import {
  fetchDetailActionTriad,
  fetchTableActionTriad,
  submitFormActionTriad,
} from '../helpers';
import {
  WAREHOUSES_SUBMIT_MOVEMENT,
  WAREHOUSES_FETCH_MOVEMENT_LIST,
  WAREHOUSES_FETCH_INVENTORY,
  WAREHOUSES_LOOKUP_DETAILS,
  WAREHOUSES_INVOICES_FETCH_LIST,
  WAREHOUSES_INVOICES_FETCH_DETAIL,
  WAREHOUSES_INVOICES_CREATE,
  WAREHOUSES_INVOICES_UPDATE,
  WAREHOUSES_INVOICES_DELETE,
  WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS,
  WAREHOUSES_INVOICES_UPDATE_ASSIGNMENTS,
  WAREHOUSES_MOVEMENT_FETCH_DETAIL,
  WAREHOUSES_MOVEMENT_SUBMIT_DETAIL,
  WAREHOUSES_SUBMIT_CHANGE_MOVEMENT,
  WAREHOUSES_SUBMIT_REVERT_MOVEMENT,
  WAREHOUSES_INVOICES_TOLL_FETCH_LIST,
  WAREHOUSES_INVOICES_TOLL_CREATE,
  WAREHOUSES_INVOICES_TOLL_UPDATE,
  WAREHOUSES_INVOICES_TOLL_DELETE,
  WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL,
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST,
  WAREHOUSES_INVOICES_TRANSPORT_CREATE,
  WAREHOUSES_INVOICES_TRANSPORT_UPDATE,
  WAREHOUSES_INVOICES_TRANSPORT_DELETE,
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL,
  WAREHOUSES_INVOICES_TOLL_UPDATE_ASSIGNMENTS,
  WAREHOUSES_INVOICES_TRANSPORT_UPDATE_ASSIGNMENTS,
  WAREHOUSES_FETCH_AVAIL_PURPOSES,
  WAREHOUSES_SUBMIT_TRANSFER,
  WAREHOUSES_FETCH_TRANSFERS_LIST,
  WAREHOUSES_TRANSFER_FETCH_DETAIL,
} from '../action_codes';

const watchSubmitWarehouseMovement = submitFormActionTriad(
  WAREHOUSES_SUBMIT_MOVEMENT,
  'POST',
  warehouseMovementSubmitSuccess,
  warehouseMovementSubmitError
).watcherFunction;

const watchSubmitChangeWarehouseMovement = submitFormActionTriad(
  WAREHOUSES_SUBMIT_CHANGE_MOVEMENT,
  'PUT',
  warehouseMovementSubmitSuccess,
  warehouseMovementSubmitError
).watcherFunction;

const watchSubmitRevertWarehouseMovement = submitFormActionTriad(
  WAREHOUSES_SUBMIT_REVERT_MOVEMENT,
  'DELETE',
  warehouseMovementSubmitSuccess,
  warehouseMovementSubmitError
).watcherFunction;

const watchFetchWarehouseMovementsList = fetchTableActionTriad(
  WAREHOUSES_FETCH_MOVEMENT_LIST,
  'warehouse-logs/',
  warehousesFetchMovementsListSuccess,
  warehousesFetchMovementsListError
).watcherFunction;

const watchFetchWarehouseTransfersList = fetchTableActionTriad(
  WAREHOUSES_FETCH_TRANSFERS_LIST,
  () => {
    return 'warehouse-logs/transfer/';
  },
  warehousesFetchTransfersListSuccess,
  warehousesFetchTransfersListError
).watcherFunction;

const watchFetchWarehousesInventoryList = fetchTableActionTriad(
  WAREHOUSES_FETCH_INVENTORY,
  'reports/annual-inventory/',
  warehousesFetchInventoryListSuccess,
  warehousesFetchInventoryListError,
  false
).watcherFunction;

const watchFetchMaterialDetail = fetchDetailActionTriad(
  WAREHOUSES_LOOKUP_DETAILS,
  warehousesLookupDetailsSuccess,
  warehousesLookupDetailsError
).watcherFunction;

const watchInvoicesListFetch = fetchTableActionTriad(
  WAREHOUSES_INVOICES_FETCH_LIST,
  'invoices/',
  warehousesInvoicesFetchListSuccess,
  warehousesInvoicesFetchError
).watcherFunction;

const watchInvoicesTollListFetch = fetchTableActionTriad(
  WAREHOUSES_INVOICES_TOLL_FETCH_LIST,
  'invoice-tolls/',
  warehousesInvoicesTollFetchListSuccess,
  warehousesInvoicesTollFetchError
).watcherFunction;

const watchInvoicesTollCreateAction = submitFormActionTriad(
  WAREHOUSES_INVOICES_TOLL_CREATE,
  'POST',
  warehousesInvoicesTollActionSuccess,
  warehousesInvoicesTollActionError
).watcherFunction;

const watchInvoicesTollUpdateAction = submitFormActionTriad(
  WAREHOUSES_INVOICES_TOLL_UPDATE,
  'PUT',
  warehousesInvoicesTollActionSuccess,
  warehousesInvoicesTollActionError
).watcherFunction;

const watchInvoicesTollDeleteAction = submitFormActionTriad(
  WAREHOUSES_INVOICES_TOLL_DELETE,
  'DELETE',
  warehousesInvoicesTollActionSuccess,
  warehousesInvoicesTollActionError
).watcherFunction;

const watchInvoicesTollDetailFetch = fetchDetailActionTriad(
  WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL,
  warehousesInvoicesTollFetchDetailSuccess,
  warehousesInvoicesTollFetchError()
).watcherFunction;

const watchInvoicesTransportListFetch = fetchTableActionTriad(
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST,
  'invoice-transports/',
  warehousesInvoicesTransportFetchListSuccess,
  warehousesInvoicesTransportFetchError
).watcherFunction;

const watchInvoicesTransportCreateAction = submitFormActionTriad(
  WAREHOUSES_INVOICES_TRANSPORT_CREATE,
  'POST',
  warehousesInvoicesTransportActionSuccess,
  warehousesInvoicesTransportActionError
).watcherFunction;

const watchInvoicesTransportUpdateAction = submitFormActionTriad(
  WAREHOUSES_INVOICES_TRANSPORT_UPDATE,
  'PUT',
  warehousesInvoicesTransportActionSuccess,
  warehousesInvoicesTransportActionError
).watcherFunction;

const watchInvoicesTransportDeleteAction = submitFormActionTriad(
  WAREHOUSES_INVOICES_TRANSPORT_DELETE,
  'DELETE',
  warehousesInvoicesTransportActionSuccess,
  warehousesInvoicesTransportActionError
).watcherFunction;

const watchInvoicesTransportDetailFetch = fetchDetailActionTriad(
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL,
  warehousesInvoicesTransportFetchDetailSuccess,
  warehousesInvoicesTransportFetchError()
).watcherFunction;

const watchInvoicesDetailFetch = fetchDetailActionTriad(
  WAREHOUSES_INVOICES_FETCH_DETAIL,
  warehousesInvoicesFetchDetailSuccess,
  warehousesInvoicesFetchError
).watcherFunction;

const watchInvoicesCreateAction = submitFormActionTriad(
  WAREHOUSES_INVOICES_CREATE,
  'POST',
  warehousesInvoicesActionSuccess,
  warehousesInvoicesActionError
).watcherFunction;

const watchInvoicesUpdateAction = submitFormActionTriad(
  WAREHOUSES_INVOICES_UPDATE,
  'PUT',
  warehousesInvoicesActionSuccess,
  warehousesInvoicesActionError
).watcherFunction;

const watchInvoicesDeleteAction = submitFormActionTriad(
  WAREHOUSES_INVOICES_DELETE,
  'DELETE',
  warehousesInvoicesActionSuccess,
  warehousesInvoicesActionError
).watcherFunction;

const watchInvoicesDeliveryListsFetcher = fetchTableActionTriad(
  WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS,
  'deliveries/',
  warehousesInvoicesFetchDeliveryListsSuccess,
  warehousesInvoicesFetchError
).watcherFunction;

const watchInvoicesUpdateAssignments = submitFormActionTriad(
  WAREHOUSES_INVOICES_UPDATE_ASSIGNMENTS,
  'PUT',
  warehousesInvoicesActionSuccess,
  warehousesInvoicesActionError,
  true
).watcherFunction;

const watchInvoicesTollUpdateAssignments = submitFormActionTriad(
  WAREHOUSES_INVOICES_TOLL_UPDATE_ASSIGNMENTS,
  'PUT',
  warehousesInvoicesTollActionSuccess,
  warehousesInvoicesTollActionError,
  true
).watcherFunction;

const watchInvoicesTransportUpdateAssignments = submitFormActionTriad(
  WAREHOUSES_INVOICES_TRANSPORT_UPDATE_ASSIGNMENTS,
  'PUT',
  warehousesInvoicesTransportActionSuccess,
  warehousesInvoicesTransportActionError,
  true
).watcherFunction;

const watchWarehouseMovementDetail = fetchDetailActionTriad(
  WAREHOUSES_MOVEMENT_FETCH_DETAIL,
  warehousesMovementDetailFetchSuccess,
  warehousesMovementDetailError
).watcherFunction;

const watchWarehouseTransferDetail = fetchDetailActionTriad(
  WAREHOUSES_TRANSFER_FETCH_DETAIL,
  warehousesTransferDetailFetchSuccess,
  warehousesTransferDetailError
).watcherFunction;

const watchSubmitWarehouseMovementDetail = submitFormActionTriad(
  WAREHOUSES_MOVEMENT_SUBMIT_DETAIL,
  'PUT',
  warehousesMovementDetailSubmitSuccess,
  warehousesMovementDetailError
).watcherFunction;

const watchFetchAvailPurposes = fetchTableActionTriad(
  WAREHOUSES_FETCH_AVAIL_PURPOSES,
  'warehouse-logs/available-purposes/',
  warehousesFetchAvailPurposesSuccess,
  warehousesFetchAvailPurposesFailure
).watcherFunction;

const watchSubmitWarehouseTransfer = submitFormActionTriad(
  WAREHOUSES_SUBMIT_TRANSFER,
  'POST',
  warehouseMovementSubmitSuccess,
  warehouseMovementSubmitError
).watcherFunction;

export default function* rootSaga() {
  yield all([
    fork(watchSubmitWarehouseMovement),
    fork(watchFetchWarehouseMovementsList),
    fork(watchFetchWarehouseTransfersList),
    fork(watchFetchWarehousesInventoryList),
    fork(watchFetchMaterialDetail),
    fork(watchInvoicesListFetch),
    fork(watchInvoicesTollListFetch),
    fork(watchInvoicesTollCreateAction),
    fork(watchInvoicesTollUpdateAction),
    fork(watchInvoicesTollDeleteAction),
    fork(watchInvoicesTollDetailFetch),
    fork(watchInvoicesTransportListFetch),
    fork(watchInvoicesTransportCreateAction),
    fork(watchInvoicesTransportUpdateAction),
    fork(watchInvoicesTransportDeleteAction),
    fork(watchInvoicesTransportDetailFetch),
    fork(watchInvoicesDetailFetch),
    fork(watchInvoicesCreateAction),
    fork(watchInvoicesUpdateAction),
    fork(watchInvoicesDeleteAction),
    fork(watchInvoicesDeliveryListsFetcher),
    fork(watchInvoicesUpdateAssignments),
    fork(watchInvoicesTollUpdateAssignments),
    fork(watchInvoicesTransportUpdateAssignments),
    fork(watchWarehouseMovementDetail),
    fork(watchSubmitWarehouseMovementDetail),
    fork(watchSubmitChangeWarehouseMovement),
    fork(watchSubmitRevertWarehouseMovement),
    fork(watchFetchAvailPurposes),
    fork(watchSubmitWarehouseTransfer),
    fork(watchWarehouseTransferDetail),
  ]);
}
