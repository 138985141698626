import { createSlice } from '@reduxjs/toolkit';
import { interpretResponseCode } from '../../errorHandling';
/* eslint-disable no-param-reassign */
/* no-param-reassign required: https://redux-toolkit.js.org/usage/immer-reducers */

const createDisplayReducer = () => {
  const displaySlice = createSlice({
    name: 'display',
    initialState: {
      errorMessage: '',
      errorTitle: '',
    },
    reducers: {
      exception: (state, action) => {
        if (action.payload) {
          state.errorMessage = interpretResponseCode(action.payload);
          state.errorTitle = 'Chyba';
        }
      },
      clearMessage: (state) => {
        state.errorMessage = null;
        state.errorTitle = null;
      },
    },
  });
  return displaySlice.reducer;
};

export default createDisplayReducer;
