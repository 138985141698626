import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

// eslint-disable-next-line import/prefer-default-export
export function configureStore(initialState) {
  // const enableLogger =
  //   process.env.ENV !== 'production' && process.env.NODE_ENV !== 'production';
  const enableLogger = false;

  // Logger must be the last middleware in chain.
  if (enableLogger) {
    const logger = createLogger({
      timestamp: false,
      collapsed: true,
      duration: true,
      // diff: true // major performance issues
    });
    middlewares.push(logger);
  }

  const store = createStore(
    reducers,
    initialState,
    compose(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(sagas);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
