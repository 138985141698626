import makeRedux from '../redux_screen_wrap';

const deliveryListReduxWrapper = makeRedux(
  'WAREHOUSES_DELIVERY_LISTS',
  'deliveries/',
  ['number'],
  {
    dateStart: null,
    dateEnd: null, // moment().subtract(1, 'month'),
    dateType: null,
    status: 'all',
    expression: '',
  }
);

export default deliveryListReduxWrapper;
