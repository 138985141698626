import {
  WAREHOUSE_QUANTITIES_ACTION_ERROR,
  WAREHOUSE_QUANTITIES_ACTION_SUCCESS,
  WAREHOUSE_QUANTITIES_CREATE,
  WAREHOUSE_QUANTITIES_DELETE,
  WAREHOUSE_QUANTITIES_FETCH_DETAIL,
  WAREHOUSE_QUANTITIES_FETCH_DETAIL_SUCCESS,
  WAREHOUSE_QUANTITIES_FETCH_ERROR,
  WAREHOUSE_QUANTITIES_FETCH_LIST,
  WAREHOUSE_QUANTITIES_FETCH_LIST_SUCCESS,
  WAREHOUSE_QUANTITIES_UPDATE,
} from '../action_codes';

export const warehouseQuantitiesFetchList = (
  multiSort,
  page,
  limit,
  filter
) => ({
  type: WAREHOUSE_QUANTITIES_FETCH_LIST,
  payload: { multiSort, page, limit, filter },
});

export const warehouseQuantitiesFetchListSuccess = (data, meta) => ({
  type: WAREHOUSE_QUANTITIES_FETCH_LIST_SUCCESS,
  payload: { data, meta },
});

export const warehouseQuantitiesFetchError = (message) => ({
  type: WAREHOUSE_QUANTITIES_FETCH_ERROR,
  payload: { message },
});

export const warehouseQuantitiesFetchDetail = (url) => ({
  type: WAREHOUSE_QUANTITIES_FETCH_DETAIL,
  payload: { url },
});

export const warehouseQuantitiesFetchDetailSuccess = (data) => ({
  type: WAREHOUSE_QUANTITIES_FETCH_DETAIL_SUCCESS,
  payload: { data },
});

export const warehouseQuantitiesCreate = (url, data, onFinish) => ({
  type: WAREHOUSE_QUANTITIES_CREATE,
  payload: { url, data, onFinish },
});

export const warehouseQuantitiesUpdate = (url, data, onFinish) => ({
  type: WAREHOUSE_QUANTITIES_UPDATE,
  payload: { url, data, onFinish },
});

export const warehouseQuantitiesDelete = (url, onFinish) => ({
  type: WAREHOUSE_QUANTITIES_DELETE,
  payload: { url, onFinish },
});

export const warehouseQuantitiesActionSuccess = () => ({
  type: WAREHOUSE_QUANTITIES_ACTION_SUCCESS,
  payload: {},
});

export const warehouseQuantitiesActionError = (message, formErrors) => ({
  type: WAREHOUSE_QUANTITIES_ACTION_ERROR,
  payload: { message, formErrors },
});
