import { all, fork } from 'redux-saga/effects';
import {
  fetchDetailActionTriad,
  fetchTableActionTriad,
  submitFormActionTriad,
} from '../helpers';
import {
  SETTINGS_FETCH_ORDER_STATUS,
  SETTINGS_SUBMIT_ORDER_STATUS,
  SETTINGS_SUBMIT_NEW_ORDER_STATUS,
  SETTINGS_DELETE_ORDER_STATUS,
  SETTINGS_FETCH_TAG_DETAIL,
  SETTINGS_SUBMIT_UPDATE_TAG_DETAIL,
  SETTINGS_SUBMIT_NEW_TAG_DETAIL,
  SETTINGS_DELETE_TAG,
  MATERIAL_TYPES_FETCH_DETAIL,
  MATERIAL_TYPES_CREATE,
  MATERIAL_TYPES_UPDATE,
  MATERIAL_TYPES_DELETE,
  MATERIAL_TYPES_FETCH_LIST,
  MATERIALS_FETCH_LIST,
  MATERIALS_FETCH_DETAIL,
  MATERIALS_CREATE,
  MATERIALS_UPDATE,
  MATERIALS_DELETE,
  SUPPLIERS_FETCH_LIST,
  SUPPLIERS_FETCH_DETAIL,
  SUPPLIERS_CREATE,
  SUPPLIERS_UPDATE,
  SUPPLIERS_DELETE,
  MATERIAL_CODES_FETCH_LIST,
  MATERIAL_CODES_FETCH_DETAIL,
  MATERIAL_CODES_CREATE,
  MATERIAL_CODES_UPDATE,
  MATERIAL_CODES_DELETE,
  WAREHOUSES_SETTINGS_FETCH_LIST,
  WAREHOUSES_SETTINGS_FETCH_DETAIL,
  WAREHOUSES_SETTINGS_CREATE,
  WAREHOUSES_SETTINGS_UPDATE,
  WAREHOUSES_SETTINGS_DELETE,
  MATERIALS_DETAIL_FETCH_CODES,
  SETTINGS_TAG_FETCH_TABLE,
  SETTINGS_ORDER_STATUS_LIST,
  SETTINGS_PURPOSES_TABLE,
  SETTINGS_PURPOSES_DETAIL,
  SETTINGS_PURPOSES_CREATE,
  SETTINGS_PURPOSES_UPDATE,
  SETTINGS_PURPOSES_DELETE,
  SETTINGS_USERS_LIST_FETCH,
  SETTINGS_USERS_CREATE,
  SETTINGS_USERS_UPDATE,
  SETTINGS_USERS_DELETE,
  SETTINGS_USERS_DETAIL_FETCH,
} from '../action_codes';
import {
  deleteOrderStatusError,
  deleteOrderStatusSuccess,
  fetchOrderStatusFailure,
  fetchOrderStatusSuccess,
  fetchTagDetailSuccess,
  tagDetailError,
  submitOrderStatusError,
  submitOrderStatusSuccess,
  tagDetailSuccess,
  materialTypeFetchDetailSuccess,
  materialTypeError,
  materialTypeActionSuccess,
  materialTypesFetchListSuccess,
  materialTypesFetchListError,
  materialListFetchSuccess,
  materialListFetchError,
  materialListFetchDetailSuccess,
  materialListSuccess,
  materialListDetailError,
  suppliersFetchListSuccess,
  suppliersFetchListError,
  suppliersFetchDetailSuccess,
  suppliersFetchDetailError,
  suppliersActionSuccess,
  suppliersActionError,
  materialCodesFetchListSuccess,
  materialCodesFetchError,
  materialCodesActionSuccess,
  materialCodesActionError,
  materialCodesFetchDetailSuccess,
  warehousesSettingsFetchListSuccess,
  warehousesSettingsFetchError,
  warehousesSettingsFetchDetailSuccess,
  warehousesSettingsActionSuccess,
  warehousesSettingsActionError,
  materialDetailFetchCodesSuccess,
  materialDetailFetchCodesError,
  tagTableSuccess,
  tagTableError,
  fetchOrderStatusListSuccess,
  fetchOrderStatusListError,
  purposeSettingsTableFetchSuccess,
  purposeSettingsFetchError,
  purposeSettingsActionSuccess,
  purposeSettingsDetailSuccess,
  usersFetchTableSuccess,
  usersFetchError,
  usersFetchDetailSuccess,
  usersActionSuccess,
  usersActionError,
} from './actions';

const fetchOrderStatusDetailWatcher = fetchDetailActionTriad(
  SETTINGS_FETCH_ORDER_STATUS,
  fetchOrderStatusSuccess,
  fetchOrderStatusFailure
).watcherFunction;

const submitOrderStatusDetailWatcher = submitFormActionTriad(
  SETTINGS_SUBMIT_ORDER_STATUS,
  'PUT',
  submitOrderStatusSuccess,
  submitOrderStatusError
).watcherFunction;

const createOrderStatusDetailWatcher = submitFormActionTriad(
  SETTINGS_SUBMIT_NEW_ORDER_STATUS,
  'POST',
  submitOrderStatusSuccess,
  submitOrderStatusError
).watcherFunction;

const deleteOrderStatusWatcher = submitFormActionTriad(
  SETTINGS_DELETE_ORDER_STATUS,
  'DELETE',
  deleteOrderStatusSuccess,
  deleteOrderStatusError
).watcherFunction;

const fetchTagDetailWatcher = fetchDetailActionTriad(
  SETTINGS_FETCH_TAG_DETAIL,
  fetchTagDetailSuccess,
  tagDetailError
).watcherFunction;

const submitTagDetailWatcher = submitFormActionTriad(
  SETTINGS_SUBMIT_UPDATE_TAG_DETAIL,
  'PUT',
  tagDetailSuccess,
  tagDetailError
).watcherFunction;

const newTagDetailWatcher = submitFormActionTriad(
  SETTINGS_SUBMIT_NEW_TAG_DETAIL,
  'POST',
  tagDetailSuccess,
  tagDetailError
).watcherFunction;

const deleteTagDetailWatcher = submitFormActionTriad(
  SETTINGS_DELETE_TAG,
  'DELETE',
  tagDetailSuccess,
  tagDetailError
).watcherFunction;

const fetchTagListWatcher = fetchTableActionTriad(
  SETTINGS_TAG_FETCH_TABLE,
  'material-tags/',
  tagTableSuccess,
  tagTableError
).watcherFunction;

const fetchMaterialTypesListWatcher = fetchTableActionTriad(
  MATERIAL_TYPES_FETCH_LIST,
  'material-types/',
  materialTypesFetchListSuccess,
  materialTypesFetchListError
).watcherFunction;

const fetchMaterialTypeWatcher = fetchDetailActionTriad(
  MATERIAL_TYPES_FETCH_DETAIL,
  materialTypeFetchDetailSuccess,
  materialTypeError
).watcherFunction;

const submitNewMaterialTypeWatcher = submitFormActionTriad(
  MATERIAL_TYPES_CREATE,
  'POST',
  materialTypeActionSuccess,
  materialTypeError
).watcherFunction;

const submitUpdatedMaterialTypeWatcher = submitFormActionTriad(
  MATERIAL_TYPES_UPDATE,
  'PUT',
  materialTypeActionSuccess,
  materialTypeError
).watcherFunction;

const deleteMaterialTypeWatcher = submitFormActionTriad(
  MATERIAL_TYPES_DELETE,
  'DELETE',
  materialTypeActionSuccess,
  materialTypeError
).watcherFunction;

const fetchMaterialListWatcher = fetchTableActionTriad(
  MATERIALS_FETCH_LIST,
  'materials/',
  materialListFetchSuccess,
  materialListFetchError
).watcherFunction;

const fetchMaterialDetailWatcher = fetchDetailActionTriad(
  MATERIALS_FETCH_DETAIL,
  materialListFetchDetailSuccess,
  materialListDetailError
).watcherFunction;

const createMaterialWatcher = submitFormActionTriad(
  MATERIALS_CREATE,
  'POST',
  materialListSuccess,
  materialListDetailError
).watcherFunction;

const updateMaterialWatcher = submitFormActionTriad(
  MATERIALS_UPDATE,
  'PUT',
  materialListSuccess,
  materialListDetailError
).watcherFunction;

const deleteMaterialWatcher = submitFormActionTriad(
  MATERIALS_DELETE,
  'DELETE',
  materialListSuccess,
  materialListDetailError
).watcherFunction;

const fetchSuppliersListWatcher = fetchTableActionTriad(
  SUPPLIERS_FETCH_LIST,
  'suppliers/',
  suppliersFetchListSuccess,
  suppliersFetchListError
).watcherFunction;

const fetchSuppliersDetailWatcher = fetchDetailActionTriad(
  SUPPLIERS_FETCH_DETAIL,
  suppliersFetchDetailSuccess,
  suppliersFetchDetailError
).watcherFunction;

const createSupplierWatcher = submitFormActionTriad(
  SUPPLIERS_CREATE,
  'POST',
  suppliersActionSuccess,
  suppliersActionError
).watcherFunction;

const updateSupplierWatcher = submitFormActionTriad(
  SUPPLIERS_UPDATE,
  'PUT',
  suppliersActionSuccess,
  suppliersActionError
).watcherFunction;

const deleteSupplierWatcher = submitFormActionTriad(
  SUPPLIERS_DELETE,
  'DELETE',
  suppliersActionSuccess,
  suppliersActionError
).watcherFunction;

const materialCodesFetchListWatcher = fetchTableActionTriad(
  MATERIAL_CODES_FETCH_LIST,
  'material-codes/',
  materialCodesFetchListSuccess,
  materialCodesFetchError
).watcherFunction;

const materialCodesFetchDetailWatcher = fetchDetailActionTriad(
  MATERIAL_CODES_FETCH_DETAIL,
  materialCodesFetchDetailSuccess,
  materialCodesFetchError
).watcherFunction;

const materialCodesCreateWatcher = submitFormActionTriad(
  MATERIAL_CODES_CREATE,
  'POST',
  materialCodesActionSuccess,
  materialCodesActionError
).watcherFunction;

const materialCodesUpdateWatcher = submitFormActionTriad(
  MATERIAL_CODES_UPDATE,
  'PUT',
  materialCodesActionSuccess,
  materialCodesActionError
).watcherFunction;

const materialCodesDeleteWatcher = submitFormActionTriad(
  MATERIAL_CODES_DELETE,
  'DELETE',
  materialCodesActionSuccess,
  materialCodesActionError
).watcherFunction;

const warehousesSettingsFetchListWatcher = fetchTableActionTriad(
  WAREHOUSES_SETTINGS_FETCH_LIST,
  'warehouses/',
  warehousesSettingsFetchListSuccess,
  warehousesSettingsFetchError
).watcherFunction;

const warehousesSettingsFetchDetailWatcher = fetchDetailActionTriad(
  WAREHOUSES_SETTINGS_FETCH_DETAIL,
  warehousesSettingsFetchDetailSuccess,
  warehousesSettingsFetchError
).watcherFunction;

const warehousesSettingsCreateWatcher = submitFormActionTriad(
  WAREHOUSES_SETTINGS_CREATE,
  'POST',
  warehousesSettingsActionSuccess,
  warehousesSettingsActionError
).watcherFunction;

const warehousesSettingsUpdateWatcher = submitFormActionTriad(
  WAREHOUSES_SETTINGS_UPDATE,
  'PUT',
  warehousesSettingsActionSuccess,
  warehousesSettingsActionError
).watcherFunction;

const warehousesSettingsDeleteWatcher = submitFormActionTriad(
  WAREHOUSES_SETTINGS_DELETE,
  'DELETE',
  warehousesSettingsActionSuccess,
  warehousesSettingsActionError
).watcherFunction;

const materialDetailFetchCodesWatcher = fetchTableActionTriad(
  MATERIALS_DETAIL_FETCH_CODES,
  'material-codes/',
  materialDetailFetchCodesSuccess,
  materialDetailFetchCodesError
).watcherFunction;

const orderStatusFetchTableWatcher = fetchTableActionTriad(
  SETTINGS_ORDER_STATUS_LIST,
  'order-statuses/',
  fetchOrderStatusListSuccess,
  fetchOrderStatusListError
).watcherFunction;

const purposesTableFetchWatcher = fetchTableActionTriad(
  SETTINGS_PURPOSES_TABLE,
  'warehouse-logs/purposes/',
  purposeSettingsTableFetchSuccess,
  purposeSettingsFetchError
).watcherFunction;

const purposesDetailFetchWatcher = fetchDetailActionTriad(
  SETTINGS_PURPOSES_DETAIL,
  purposeSettingsDetailSuccess,
  purposeSettingsFetchError
).watcherFunction;

const purposesCreateWatcher = submitFormActionTriad(
  SETTINGS_PURPOSES_CREATE,
  'POST',
  purposeSettingsActionSuccess,
  purposeSettingsFetchError
).watcherFunction;

const purposesUpdateWatcher = submitFormActionTriad(
  SETTINGS_PURPOSES_UPDATE,
  'PUT',
  purposeSettingsActionSuccess,
  purposeSettingsFetchError
).watcherFunction;

const purposesDeleteWatcher = submitFormActionTriad(
  SETTINGS_PURPOSES_DELETE,
  'DELETE',
  purposeSettingsActionSuccess,
  purposeSettingsFetchError
).watcherFunction;

const usersFetchTableWatcher = fetchTableActionTriad(
  SETTINGS_USERS_LIST_FETCH,
  'users/',
  usersFetchTableSuccess,
  usersFetchError
).watcherFunction;

const usersFetchDetailWatcher = fetchDetailActionTriad(
  SETTINGS_USERS_DETAIL_FETCH,
  usersFetchDetailSuccess,
  usersFetchError
).watcherFunction;

const usersCreateActionWatcher = submitFormActionTriad(
  SETTINGS_USERS_CREATE,
  'POST',
  usersActionSuccess,
  usersActionError
).watcherFunction;

const usersUpdateActionWatcher = submitFormActionTriad(
  SETTINGS_USERS_UPDATE,
  'PUT',
  usersActionSuccess,
  usersActionError
).watcherFunction;

const usersDeleteActionWatcher = submitFormActionTriad(
  SETTINGS_USERS_DELETE,
  'DELETE',
  usersActionSuccess,
  usersActionError
).watcherFunction;

export default function* rootSaga() {
  yield all([
    fork(fetchOrderStatusDetailWatcher),
    fork(submitOrderStatusDetailWatcher),
    fork(createOrderStatusDetailWatcher),
    fork(deleteOrderStatusWatcher),
    fork(fetchTagDetailWatcher),
    fork(submitTagDetailWatcher),
    fork(fetchTagListWatcher),
    fork(newTagDetailWatcher),
    fork(deleteTagDetailWatcher),
    fork(fetchMaterialTypesListWatcher),
    fork(fetchMaterialTypeWatcher),
    fork(submitNewMaterialTypeWatcher),
    fork(submitUpdatedMaterialTypeWatcher),
    fork(deleteMaterialTypeWatcher),
    fork(fetchMaterialListWatcher),
    fork(fetchMaterialDetailWatcher),
    fork(createMaterialWatcher),
    fork(updateMaterialWatcher),
    fork(deleteMaterialWatcher),
    fork(fetchSuppliersListWatcher),
    fork(fetchSuppliersDetailWatcher),
    fork(createSupplierWatcher),
    fork(updateSupplierWatcher),
    fork(deleteSupplierWatcher),
    fork(materialCodesFetchListWatcher),
    fork(materialCodesFetchDetailWatcher),
    fork(materialCodesCreateWatcher),
    fork(materialCodesUpdateWatcher),
    fork(materialCodesDeleteWatcher),
    fork(warehousesSettingsFetchListWatcher),
    fork(warehousesSettingsFetchDetailWatcher),
    fork(warehousesSettingsCreateWatcher),
    fork(warehousesSettingsUpdateWatcher),
    fork(warehousesSettingsDeleteWatcher),
    fork(materialDetailFetchCodesWatcher),
    fork(orderStatusFetchTableWatcher),
    fork(purposesTableFetchWatcher),
    fork(purposesDetailFetchWatcher),
    fork(purposesCreateWatcher),
    fork(purposesUpdateWatcher),
    fork(purposesDeleteWatcher),
    fork(usersFetchTableWatcher),
    fork(usersFetchDetailWatcher),
    fork(usersCreateActionWatcher),
    fork(usersUpdateActionWatcher),
    fork(usersDeleteActionWatcher),
  ]);
}
