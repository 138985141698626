import {
  isAuthGuardActive,
  isPermissionGuardActive,
} from './constants/defaultValues';

const isPermitted = (permStr, user, permissionPrefix = 'core') => {
  const fullName = `${permissionPrefix}.${permStr}`;
  if (user.permissions === undefined) {
    return false;
  }
  return !!user.permissions[fullName];
};

const hasPermission = (testedPermission, user, forceCheck = false) => {
  if (!isAuthGuardActive) {
    return true;
  }
  if (user.is_superuser) {
    return true;
  }
  if (!isPermissionGuardActive && !forceCheck) {
    return true;
  }
  if (!testedPermission) {
    return true;
  }

  if (Array.isArray(testedPermission)) {
    let permitted = false;
    testedPermission.forEach((permStr) => {
      permitted = permitted || isPermitted(permStr, user);
    });
    return permitted;
  }
  if (
    typeof testedPermission === 'string' ||
    testedPermission instanceof String
  ) {
    return isPermitted(testedPermission, user);
  }
  console.error('Malformed permissions: ', testedPermission);
  return false;
};

const hasAllowedPermission = (testedPermission, user, forceCheck = false) => {
  if (!isAuthGuardActive) {
    return true;
  }
  if (user.is_superuser) {
    return true;
  }
  if (!isPermissionGuardActive && !forceCheck) {
    return true;
  }
  if (!testedPermission) {
    return true;
  }

  if (Array.isArray(testedPermission)) {
    const testedNotAllowed = testedPermission.filter(
      (permStr) => !isPermitted(permStr, user)
    );
    return !testedNotAllowed || testedNotAllowed.length === 0;
  }
  if (
    typeof testedPermission === 'string' ||
    testedPermission instanceof String
  ) {
    return isPermitted(testedPermission, user);
  }
  console.error('Malformed permissions: ', testedPermission);
  return false;
};

const tableRowPermission = (
  entity,
  action,
  currentUser,
  permissionPrefix = 'core',
  forceCheck = false
) => {
  if (!isPermissionGuardActive && !forceCheck) {
    return true;
  }
  if (action === 'add') {
    return isPermitted(`add_${entity}`, currentUser, permissionPrefix);
  }
  if (action === 'edit') {
    return isPermitted(`add_${entity}`, currentUser, permissionPrefix);
  }
  if (action === 'delete') {
    return isPermitted(`delete_${entity}`, currentUser, permissionPrefix);
  }
  return true;
};

export const accountantPermissionsSet = ['add_accountinglog'];

export { tableRowPermission, hasAllowedPermission };

export default hasPermission;
