import moment from 'moment';
import {
  AUDITS_FETCH_DETAIL,
  AUDITS_FETCH_DETAIL_SUCCESS,
  AUDITS_FETCH_ERROR,
  AUDITS_FETCH_TABLE,
  AUDITS_FETCH_TABLE_SUCCESS,
  AUDITS_FETCH_USERS,
  AUDITS_FETCH_USERS_SUCCESS,
  AUDITS_UPDATE_FILTER,
  AUDITS_UPDATE_SORT,
} from '../action_codes';
import { getDefaultPageSize } from '../../helpers/Utils';

const INIT_STATE = {
  users: null,
  loading: false,
  error: '',
  filter: {
    expression: '',
    user: null,
    created_at_gte: moment().toDate(),
    created_at_lte: moment().toDate(),
  },
  logData: null,
  logMeta: { page: 1, limit: getDefaultPageSize() },
  detail: null,
  multiSort: ['-created_at'],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUDITS_FETCH_USERS:
      return { ...state, loading: true };
    case AUDITS_FETCH_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case AUDITS_FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        users: action.payload.data,
      };
    case AUDITS_UPDATE_FILTER:
      return { ...state, filter: { ...action.payload.filter } };
    case AUDITS_UPDATE_SORT:
      return { ...state, multiSort: action.payload.multiSort };
    case AUDITS_FETCH_TABLE:
      return { ...state, loading: true };
    case AUDITS_FETCH_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        logData: action.payload.data,
        logMeta: action.payload.meta,
      };
    case AUDITS_FETCH_DETAIL:
      return {
        ...state,
        loading: true,
        detail: null,
      };
    case AUDITS_FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        detail: action.payload.data,
      };
    default:
      return { ...state };
  }
};
