import { all, fork } from 'redux-saga/effects';
import {
  fetchOrderStatesError,
  fetchOrderStatesSuccess,
  fetchWarehousesError,
  fetchWarehousesSuccess,
  fetchUnitsSuccess,
  fetchUnitsFailure,
  fetchSuppliersSuccess,
  fetchSuppliersFailure,
  fetchMaterialsError,
  fetchMaterialsSuccess,
  fetchTagsSuccess,
  fetchTagsError,
  fetchMaterialTypesSuccess,
  fetchMaterialTypesError,
  countriesFetchSuccess,
  countriesFetchError,
  purposesFetchSuccess,
  purposesFetchError,
  fetchGlobalProductsSuccess,
  fetchGlobalProductsError,
  fetchGlobalProductSuppliersSuccess,
  fetchGlobalProductSuppliersFailure,
  fetchRolesSuccess,
  fetchRolesError,
  citiesFetchSuccess,
  citiesFetchError,
  fetchPurposesDistinctSuccess,
  fetchPurposesDistinctError,
  fetchPurposesCompleteSuccess,
  fetchPurposesCompleteError,
  purposesFetchByDirectionSuccess,
  locationsFetchSuccess,
  locationsFetchError,
} from './actions';
import {
  FETCH_MATERIALS,
  FETCH_ORDER_STATES,
  FETCH_WAREHOUSES,
  FETCH_CITY_WAREHOUSES,
  FETCH_UNITS,
  FETCH_SUPPLIERS,
  FETCH_TAGS,
  FETCH_COUNTRIES,
  FETCH_MATERIAL_TYPES,
  FETCH_PURPOSES,
  FETCH_GLOBAL_PRODUCTS,
  FETCH_PRODUCT_SUPPLIERS,
  FETCH_GLOBAL_ROLES,
  FETCH_CITIES,
  FETCH_LOCATIONS,
  FETCH_PURPOSES_DISTINCT,
  FETCH_PURPOSES_COMPLETE,
  FETCH_PURPOSES_BY_DIRECTION,
} from '../action_codes';
import { fetchTableActionTriad } from '../helpers';

const fetchWarehousesWatcher = fetchTableActionTriad(
  FETCH_WAREHOUSES,
  'warehouses/',
  fetchWarehousesSuccess,
  fetchWarehousesError
).watcherFunction;

const fetchCityWarehousesWatcher = fetchTableActionTriad(
  FETCH_CITY_WAREHOUSES,
  ({ city }) => `warehouses/${city ? `?city=${city}` : ''}`,
  fetchWarehousesSuccess,
  fetchWarehousesError
).watcherFunction;

const fetchMaterialsWatcher = fetchTableActionTriad(
  FETCH_MATERIALS,
  'materials/',
  fetchMaterialsSuccess,
  fetchMaterialsError
).watcherFunction;

const fetchOrderStatesWatcher = fetchTableActionTriad(
  FETCH_ORDER_STATES,
  'order-statuses/',
  fetchOrderStatesSuccess,
  fetchOrderStatesError
).watcherFunction;

const fetchUnitsWatcher = fetchTableActionTriad(
  FETCH_UNITS,
  'units/',
  fetchUnitsSuccess,
  fetchUnitsFailure
).watcherFunction;

const fetchSuppliersWatcher = fetchTableActionTriad(
  FETCH_SUPPLIERS,
  'suppliers/',
  fetchSuppliersSuccess,
  fetchSuppliersFailure
).watcherFunction;

const fetchTagsWatcher = fetchTableActionTriad(
  FETCH_TAGS,
  'material-tags/',
  fetchTagsSuccess,
  fetchTagsError
).watcherFunction;

const fetchMaterialTypesWatcher = fetchTableActionTriad(
  FETCH_MATERIAL_TYPES,
  'material-types/',
  fetchMaterialTypesSuccess,
  fetchMaterialTypesError
).watcherFunction;

const fetchCountriesWatcher = fetchTableActionTriad(
  FETCH_COUNTRIES,
  'countries/',
  countriesFetchSuccess,
  countriesFetchError
).watcherFunction;

const fetchLocationsWatcher = fetchTableActionTriad(
  FETCH_LOCATIONS,
  'sites/',
  locationsFetchSuccess,
  locationsFetchError
).watcherFunction;

const fetchPurposesWatcher = fetchTableActionTriad(
  FETCH_PURPOSES,
  'warehouse-logs/purposes/',
  purposesFetchSuccess,
  purposesFetchError
).watcherFunction;

const fetchPurposesByDirectionWatcher = fetchTableActionTriad(
  FETCH_PURPOSES_BY_DIRECTION,
  ({ direction }) =>
    `warehouse-logs/purposes/${direction ? `?direction=${direction}` : ''}`,
  purposesFetchByDirectionSuccess,
  purposesFetchError
).watcherFunction;

const fetchProductsWatcher = fetchTableActionTriad(
  FETCH_GLOBAL_PRODUCTS,
  'materials/',
  fetchGlobalProductsSuccess,
  fetchGlobalProductsError
).watcherFunction;

const fetchProductSuppliersWatcher = fetchTableActionTriad(
  FETCH_PRODUCT_SUPPLIERS,
  'suppliers/',
  fetchGlobalProductSuppliersSuccess,
  fetchGlobalProductSuppliersFailure
).watcherFunction;

const fetchGlobalRolesWatcher = fetchTableActionTriad(
  FETCH_GLOBAL_ROLES,
  'auth-group/',
  fetchRolesSuccess,
  fetchRolesError
).watcherFunction;

const fetchCitiesWatcher = fetchTableActionTriad(
  FETCH_CITIES,
  'cities/',
  citiesFetchSuccess,
  citiesFetchError
).watcherFunction;

const fetchPurposesDistinctWatcher = fetchTableActionTriad(
  FETCH_PURPOSES_DISTINCT,
  'warehouse-logs/purposes/distinct/',
  fetchPurposesDistinctSuccess,
  fetchPurposesDistinctError
).watcherFunction;

const fetchPurposesCompleteWatcher = fetchTableActionTriad(
  FETCH_PURPOSES_COMPLETE,
  'warehouse-logs/purposes/',
  fetchPurposesCompleteSuccess,
  fetchPurposesCompleteError
).watcherFunction;

export default function* rootSaga() {
  yield all([
    fork(fetchMaterialsWatcher),
    fork(fetchWarehousesWatcher),
    fork(fetchCityWarehousesWatcher),
    fork(fetchOrderStatesWatcher),
    fork(fetchUnitsWatcher),
    fork(fetchSuppliersWatcher),
    fork(fetchTagsWatcher),
    fork(fetchMaterialTypesWatcher),
    fork(fetchCountriesWatcher),
    fork(fetchLocationsWatcher),
    fork(fetchPurposesWatcher),
    fork(fetchPurposesByDirectionWatcher),
    fork(fetchProductsWatcher),
    fork(fetchProductSuppliersWatcher),
    fork(fetchGlobalRolesWatcher),
    fork(fetchCitiesWatcher),
    fork(fetchPurposesDistinctWatcher),
    fork(fetchPurposesCompleteWatcher),
  ]);
}
