import { all, fork } from 'redux-saga/effects';
import { fetchTableActionTriad } from '../helpers';
import { ACCOUNTING_FETCH_INVENTORY } from '../action_codes';
import {
  accountingFetchInventorySuccess,
  accountingFetchInventoryError,
} from './actions';

const fetchInventoryWatcher = fetchTableActionTriad(
  ACCOUNTING_FETCH_INVENTORY,
  'reports/annual-inventory-prices/',
  accountingFetchInventorySuccess,
  accountingFetchInventoryError
).watcherFunction;

export default function* rootSaga() {
  yield all([fork(fetchInventoryWatcher)]);
}
