import {
  all,
  call,
  fork,
  put,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import moment from 'moment';
import {
  ordersDetailError,
  ordersDetailSubmitSuccess,
  ordersDetailSuccess,
  ordersListError,
  ordersListSuccess,
  submitNewOrdersSuccess,
  submitNewOrdersError,
  deleteOrderSuccess,
  deleteOrderError,
  orderDetailFetchHistorySuccess,
  orderDetailFetchHistoryError,
  orderDetailHistoryAddSuccess,
  orderDetailHistoryAddError,
} from './actions';
import {
  ORDERS_DETAIL,
  ORDERS_DETAIL_SUBMIT,
  ORDERS_LIST,
  ORDERS_CREATE_SUBMIT,
  ORDERS_DELETE_SUBMIT,
  ORDERS_DETAIL_FETCH_HISTORY,
  ORDERS_DETAIL_HISTORY_ADD,
} from '../action_codes';
import { GetApi, PostApi } from '../../api/api';
import { interpretResponseCode } from '../../errorHandling';
import { fetchTableActionTriad, submitFormActionTriad } from '../helpers';
import { toUrlParams } from '../factories/apiAdapter';

const daysBack = (days) => {
  return moment().subtract(days, 'days').format('YYYY-MM-DD');
};

const yearBack = () => {
  return moment().subtract(1, 'year').format('YYYY-MM-DD');
};

const filterDefinitions = {
  open: () => ({
    is_finished: 'False',
  }),
  closed: () => ({
    is_finished: 'True',
  }),
  last30days: () => ({
    created_at_after: daysBack(30),
  }),
  last60days: () => ({
    created_at_after: daysBack(60),
  }),
  last90days: () => ({
    created_at_after: daysBack(90),
  }),
  last120days: () => ({
    created_at_after: daysBack(120),
  }),
  last_year: () => ({
    created_at_after: yearBack(),
  }),
};

const extractDropdownFilter = (filterType) => {
  if (filterType) {
    return filterDefinitions[filterType]();
  }
  return null;
};

const fetchOrdersListAsync = async (multiSort, page, limit, filter) => {
  const timeSince = filter.type ? extractDropdownFilter(filter.type) : null;
  const sortParam =
    multiSort && multiSort.length > 0 ? multiSort.join(',') : null;

  const params = toUrlParams({
    page,
    limit,
    order_by: sortParam,
    ...timeSince,
    query: filter.expression || null,
  });

  return GetApi({
    url: 'orders/',
    params,
    handleResponse: (response) => response.data,
    handleError: (error) => error,
  });
};

function* fetchOrdersList({ payload }) {
  const { multiSort, page, limit, filter } = payload;
  try {
    const response = yield call(
      fetchOrdersListAsync,
      multiSort,
      page,
      limit,
      filter
    );
    if (response.message) {
      yield put(ordersListError(response.message));
    } else {
      const { items, metadata } = response;
      yield put(ordersListSuccess(items, metadata));
    }
  } catch (error) {
    const message = interpretResponseCode(error);
    yield put(ordersListError(message));
  }
}

const fetchOrderDetailAsync = async (orderId) => {
  return GetApi({
    url: `orders/${orderId}/`,
    handleResponse: (response) => response.data,
    handleError: (error) => error,
  });
};

function* fetchOrderDetail({ payload }) {
  const { orderId } = payload;
  const response = yield call(fetchOrderDetailAsync, orderId);
  try {
    if (response.message) {
      yield put(ordersDetailError(response.message));
    } else {
      yield put(ordersDetailSuccess(response.response));
    }
  } catch (error) {
    const message = interpretResponseCode(error);
    yield put(ordersDetailError(message));
  }
}

export function* watchOrdersList() {
  yield takeLatest(ORDERS_LIST, fetchOrdersList);
}

export function* watchOrderDetail() {
  yield takeEvery(ORDERS_DETAIL, fetchOrderDetail);
}

const submitNewOrdersAsync = async (orders) => {
  return PostApi({
    url: 'orders/',
    method: 'POST',
    data: { items: orders },
    handleResponse: (response) => response,
    handleError: (error) => error,
  });
};

function* submitNewOrdersGenerator({ payload }) {
  const { orders, onFinish } = payload;
  try {
    const response = yield call(submitNewOrdersAsync, orders);
    if (response.message) {
      yield put(submitNewOrdersError(response.message));
      onFinish(false);
    } else {
      yield put(submitNewOrdersSuccess());
      onFinish(true);
    }
  } catch (error) {
    const message = interpretResponseCode(error);
    yield put(submitNewOrdersError(message));
    onFinish(false);
  }
}

const watchSubmitOrderDetail = submitFormActionTriad(
  ORDERS_DETAIL_SUBMIT,
  'PUT',
  ordersDetailSubmitSuccess,
  ordersDetailError
).watcherFunction;

export function* watchCreateOrders() {
  yield takeEvery(ORDERS_CREATE_SUBMIT, submitNewOrdersGenerator);
}

const watchDeleteOrder = submitFormActionTriad(
  ORDERS_DELETE_SUBMIT,
  'DELETE',
  deleteOrderSuccess,
  deleteOrderError
).watcherFunction;

const watchFetchOrderHistory = fetchTableActionTriad(
  ORDERS_DETAIL_FETCH_HISTORY,
  (payload) => `orders/${payload.id}/order_update/`,
  orderDetailFetchHistorySuccess,
  orderDetailFetchHistoryError
).watcherFunction;

const watchSubmitAddOrderHistory = submitFormActionTriad(
  ORDERS_DETAIL_HISTORY_ADD,
  'POST',
  orderDetailHistoryAddSuccess,
  orderDetailHistoryAddError
).watcherFunction;

export default function* rootSaga() {
  yield all([
    fork(watchOrdersList),
    fork(watchOrderDetail),
    fork(watchSubmitOrderDetail),
    fork(watchCreateOrders),
    fork(watchDeleteOrder),
    fork(watchFetchOrderHistory),
    fork(watchSubmitAddOrderHistory),
  ]);
}
