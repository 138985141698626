import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable no-param-reassign */
/* no-param-reassign required: https://redux-toolkit.js.org/usage/immer-reducers */

const createCrudReducerSlice = ({
  sliceName,
  extraFields,
  extraReducers,
  initialSort = ['-created_at'],
}) => {
  const theSlice = createSlice({
    name: sliceName,
    initialState: {
      tableData: null,
      tableMeta: {},
      objectDetail: null,
      detailLoading: false,
      requestParams: {},
      tableLoading: false,
      multiSort: initialSort,
      filter: {},
      ...extraFields,
    },
    reducers: {
      setTable: (state, action) => {
        // console.log('setTable', action);
        state.tableData = action.payload.items;
        state.tableMeta = { ...state.tableMeta, ...action.payload.metadata };
        state.tableLoading = false;
      },
      updateRequestParams: (state, action) => {
        state.requestParams = { ...state.requestParams, ...action.payload };
      },
      fetchData: (state) => {
        state.tableLoading = true;
      },
      fetchDetail: (state) => {
        state.detailLoading = true;
      },
      setObjectDetail: (state, action) => {
        state.objectDetail = action.payload.response;
        state.detailLoading = false;
      },
      clearDetail: (state) => {
        state.objectDetail = null;
      },
      clearList: (state) => {
        state.tableData = null;
        state.tableMeta = null;
      },
      updateFilter: (state, action) => {
        state.filter = { ...action.payload };
      },
      updateSort: (state, action) => {
        state.multiSort = action.payload.multiSort;
      },
      ...extraReducers,
    },
  });
  return theSlice.reducer;
};

export default createCrudReducerSlice;
