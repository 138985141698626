import {
  ORDERS_LIST,
  ORDERS_LIST_ERROR,
  ORDERS_LIST_SUCCESS,
  ORDERS_LIST_UPDATE_META,
  ORDERS_DETAIL,
  ORDERS_DETAIL_ERROR,
  ORDERS_DETAIL_SUCCESS,
  ORDERS_DETAIL_SUBMIT,
  ORDERS_DETAIL_SUBMIT_SUCCESS,
  ORDERS_CREATE_SUBMIT,
  ORDERS_CREATE_SUBMIT_SUCCESS,
  ORDERS_CREATE_SUBMIT_ERROR,
  ORDERS_DELETE_SUBMIT,
  ORDERS_DELETE_SUCCESS,
  ORDERS_DELETE_ERROR,
  ORDERS_DETAIL_FETCH_HISTORY,
  ORDERS_DETAIL_FETCH_HISTORY_SUCCESS,
  ORDERS_DETAIL_FETCH_HISTORY_ERROR,
  ORDERS_DETAIL_HISTORY_ADD,
  ORDERS_DETAIL_HISTORY_ADD_SUCCESS,
  ORDERS_DETAIL_HISTORY_ADD_ERROR,
} from '../action_codes';

export const ordersList = (multiSort, page, limit, filter) => ({
  type: ORDERS_LIST,
  payload: { multiSort, page, limit, filter },
});

export const ordersListSuccess = (items, paging) => {
  return {
    type: ORDERS_LIST_SUCCESS,
    payload: { items, paging },
  };
};

export const ordersListError = (message) => ({
  type: ORDERS_LIST_ERROR,
  payload: { message },
});

export const ordersListUpdateMeta = (multiSort, page, limit, filter) => ({
  type: ORDERS_LIST_UPDATE_META,
  payload: { multiSort, page, limit, filter },
});

export const ordersDetail = (orderId) => ({
  type: ORDERS_DETAIL,
  payload: { orderId },
});

export const ordersDetailSuccess = (order) => ({
  type: ORDERS_DETAIL_SUCCESS,
  payload: { order },
});

export const ordersDetailError = (message) => ({
  type: ORDERS_DETAIL_ERROR,
  payload: { message },
});

export const ordersDetailSubmit = (url, data, onFinish) => ({
  type: ORDERS_DETAIL_SUBMIT,
  payload: { url, data, onFinish },
});

export const ordersDetailSubmitSuccess = () => ({
  type: ORDERS_DETAIL_SUBMIT_SUCCESS,
  payload: {},
});

export const submitNewOrders = (orders, onFinish) => ({
  type: ORDERS_CREATE_SUBMIT,
  payload: { orders, onFinish },
});

export const submitNewOrdersSuccess = () => ({
  type: ORDERS_CREATE_SUBMIT_SUCCESS,
  payload: {},
});

export const submitNewOrdersError = (message) => ({
  type: ORDERS_CREATE_SUBMIT_ERROR,
  payload: { message },
});

export const deleteOrderSubmit = (url, onFinish) => ({
  type: ORDERS_DELETE_SUBMIT,
  payload: { url, data: {}, onFinish },
});

export const deleteOrderSuccess = () => ({
  type: ORDERS_DELETE_SUCCESS,
  payload: {},
});

export const deleteOrderError = (message) => ({
  type: ORDERS_DELETE_ERROR,
  payload: { message },
});

export const orderDetailFetchHistory = (orderId) => ({
  type: ORDERS_DETAIL_FETCH_HISTORY,
  payload: { multiSort: ['-created_at'], page: 1, limit: null, id: orderId },
});

export const orderDetailFetchHistorySuccess = (data) => ({
  type: ORDERS_DETAIL_FETCH_HISTORY_SUCCESS,
  payload: { data },
});

export const orderDetailFetchHistoryError = (message) => ({
  type: ORDERS_DETAIL_FETCH_HISTORY_ERROR,
  payload: { message },
});

export const orderDetailHistoryAdd = (url, data, onFinish) => ({
  type: ORDERS_DETAIL_HISTORY_ADD,
  payload: { data, url, onFinish },
});

export const orderDetailHistoryAddSuccess = () => ({
  type: ORDERS_DETAIL_HISTORY_ADD_SUCCESS,
  payload: {},
});

export const orderDetailHistoryAddError = (message, formErrors) => ({
  type: ORDERS_DETAIL_HISTORY_ADD_ERROR,
  payload: { message, formErrors },
});
