import { all, fork } from 'redux-saga/effects';
import { fetchTableActionTriad } from '../helpers';
import { REPORTS_FETCH } from '../action_codes';
import { reportsFetchError, reportsFetchSuccess } from './actions';

const reportDataWatcher = fetchTableActionTriad(
  REPORTS_FETCH,
  'reports/list-of-stock/',
  reportsFetchSuccess,
  reportsFetchError
).watcherFunction;

export default function* rootSaga() {
  yield all([fork(reportDataWatcher)]);
}
