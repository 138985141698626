import {
  CHANGE_LOCALE,
  MATERIAL_CODES_ACTION_ERROR,
  MATERIAL_CODES_ACTION_SUCCESS,
  MATERIAL_CODES_CREATE,
  MATERIAL_CODES_DELETE,
  MATERIAL_CODES_FETCH_DETAIL,
  MATERIAL_CODES_FETCH_DETAIL_SUCCESS,
  MATERIAL_CODES_FETCH_ERROR,
  MATERIAL_CODES_FETCH_LIST,
  MATERIAL_CODES_FETCH_LIST_SUCCESS,
  MATERIAL_CODES_UPDATE,
  MATERIAL_TYPES_ACTION_SUCCESS,
  MATERIAL_TYPES_CHANGE_SORT,
  MATERIAL_TYPES_CREATE,
  MATERIAL_TYPES_DELETE,
  MATERIAL_TYPES_ERROR,
  MATERIAL_TYPES_FETCH_DETAIL,
  MATERIAL_TYPES_FETCH_DETAIL_SUCCESS,
  MATERIAL_TYPES_FETCH_LIST,
  MATERIAL_TYPES_FETCH_LIST_ERROR,
  MATERIAL_TYPES_FETCH_LIST_SUCCESS,
  MATERIAL_TYPES_UPDATE,
  MATERIAL_TYPES_UPDATE_FILTER,
  MATERIALS_CHANGE_FILTER,
  MATERIALS_CHANGE_SORT,
  MATERIALS_CREATE,
  MATERIALS_DELETE,
  MATERIALS_DETAIL_CODES_CHANGE_SORT,
  MATERIALS_DETAIL_ERROR,
  MATERIALS_DETAIL_FETCH_CODES,
  MATERIALS_DETAIL_FETCH_CODES_ERROR,
  MATERIALS_DETAIL_FETCH_CODES_SUCCESS,
  MATERIALS_DETAIL_QUANTITIES_CHANGE_SORT,
  MATERIALS_FETCH_DETAIL,
  MATERIALS_FETCH_DETAIL_SUCCESS,
  MATERIALS_FETCH_LIST,
  MATERIALS_FETCH_LIST_ERROR,
  MATERIALS_FETCH_LIST_SUCCESS,
  MATERIALS_SUCCESS,
  MATERIALS_UPDATE,
  SETTINGS_DELETE_ORDER_STATUS,
  SETTINGS_DELETE_ORDER_STATUS_ERROR,
  SETTINGS_DELETE_ORDER_STATUS_SUCCESS,
  SETTINGS_FETCH_ORDER_STATUS,
  SETTINGS_FETCH_ORDER_STATUS_ERROR,
  SETTINGS_FETCH_ORDER_STATUS_SUCCESS,
  SETTINGS_FETCH_TAG_DETAIL,
  SETTINGS_FETCH_TAG_DETAIL_SUCCESS,
  SETTINGS_ORDER_STATUS_CHANGE_SORT,
  SETTINGS_ORDER_STATUS_LIST,
  SETTINGS_ORDER_STATUS_LIST_ERROR,
  SETTINGS_ORDER_STATUS_LIST_SUCCESS,
  SETTINGS_PURPOSES_ACTION_SUCCESS,
  SETTINGS_PURPOSES_CHANGE_SORT,
  SETTINGS_PURPOSES_CREATE,
  SETTINGS_PURPOSES_DELETE,
  SETTINGS_PURPOSES_DETAIL,
  SETTINGS_PURPOSES_DETAIL_SUCCESS,
  SETTINGS_PURPOSES_ERROR,
  SETTINGS_PURPOSES_TABLE,
  SETTINGS_PURPOSES_TABLE_SUCCESS,
  SETTINGS_PURPOSES_UPDATE,
  SETTINGS_SUBMIT_NEW_TAG_DETAIL,
  SETTINGS_SUBMIT_UPDATE_TAG_DETAIL,
  SETTINGS_TAG_CHANGE_SORT,
  SETTINGS_TAG_DETAIL_ERROR,
  SETTINGS_TAG_FETCH_TABLE,
  SETTINGS_TAG_FETCH_TABLE_ERROR,
  SETTINGS_TAG_FETCH_TABLE_SUCCESS,
  SETTINGS_USERS_ACTION_ERROR,
  SETTINGS_USERS_ACTION_SUCCESS,
  SETTINGS_USERS_CREATE,
  SETTINGS_USERS_DELETE,
  SETTINGS_USERS_DETAIL_FETCH,
  SETTINGS_USERS_DETAIL_FETCH_SUCCESS,
  SETTINGS_USERS_FETCH_ERROR,
  SETTINGS_USERS_LIST_FETCH,
  SETTINGS_USERS_LIST_FETCH_SUCCESS,
  SETTINGS_USERS_UPDATE,
  SETTINGS_USERS_UPDATE_FILTER,
  SETTINGS_USERS_UPDATE_SORT,
  SUPPLIERS_ACTION_ERROR,
  SUPPLIERS_ACTION_SUCCESS,
  SUPPLIERS_CHANGE_SORT,
  SUPPLIERS_CREATE,
  SUPPLIERS_DELETE,
  SUPPLIERS_FETCH_DETAIL,
  SUPPLIERS_FETCH_DETAIL_ERROR,
  SUPPLIERS_FETCH_DETAIL_SUCCESS,
  SUPPLIERS_FETCH_LIST,
  SUPPLIERS_FETCH_LIST_ERROR,
  SUPPLIERS_FETCH_LIST_SUCCESS,
  SUPPLIERS_UPDATE,
  SUPPLIERS_UPDATE_FILTER,
  WAREHOUSES_SETTINGS_ACTION_ERROR,
  WAREHOUSES_SETTINGS_ACTION_SUCCESS,
  WAREHOUSES_SETTINGS_CHANGE_SORT,
  WAREHOUSES_SETTINGS_CREATE,
  WAREHOUSES_SETTINGS_DELETE,
  WAREHOUSES_SETTINGS_FETCH_DETAIL,
  WAREHOUSES_SETTINGS_FETCH_DETAIL_SUCCESS,
  WAREHOUSES_SETTINGS_FETCH_ERROR,
  WAREHOUSES_SETTINGS_FETCH_LIST,
  WAREHOUSES_SETTINGS_FETCH_LIST_SUCCESS,
  WAREHOUSES_SETTINGS_UPDATE,
  WAREHOUSES_SETTINGS_UPDATE_FILTER,
} from '../action_codes';
import { getCurrentLanguage, getDefaultPageSize } from '../../helpers/Utils';
import { managementScreenActions } from '../helpers';

const INIT_STATE = {
  locale: getCurrentLanguage(),
  orderStatus: {
    list: null,
    data: null,
    loading: false,
    error: '',
    multiSort: ['name'],
  },
  tag: {
    list: null,
    data: null,
    loading: false,
    error: '',
    multiSort: ['name'],
  },
  materialType: {
    list: null,
    loading: false,
    error: '',
    meta: { page: 1, limit: null },
    filter: { query: '' },
    multiSort: ['name'],
  },
  materials: {
    list: null,
    loading: false,
    error: '',
    detail: null,
    meta: { page: 1, limit: getDefaultPageSize() },
    filter: { query: '' },
    multiSort: ['name'],
    codesMultiSort: ['code'],
    quantitiesMultiSort: ['warehouse'],
  },
  suppliers: {
    list: null,
    detail: null,
    error: '',
    loading: false,
    meta: { page: 1, limit: getDefaultPageSize() },
    filter: { query: '' },
    multiSort: ['name'],
  },
  materialCodes: {
    list: null,
    detail: null,
    error: '',
    loading: false,
    meta: { multiSort: ['code'], page: 1, limit: getDefaultPageSize() },
  },
  materialCodeList: {
    list: null,
    meta: { multiSort: ['code'], page: 1, limit: getDefaultPageSize() },
    loading: false,
    error: '',
  },
  warehouses: {
    list: null,
    detail: null,
    error: '',
    loading: false,
    meta: { page: 1, limit: getDefaultPageSize() },
    filter: { query: '' },
    multiSort: ['name'],
  },
  purposes: {
    list: null,
    detail: null,
    error: '',
    loading: false,
    meta: { page: 1, limit: getDefaultPageSize() },
    multiSort: ['name'],
  },
  users: {
    list: null,
    detail: null,
    error: '',
    loading: false,
    meta: { page: 1, limit: getDefaultPageSize() },
    filter: { query: '', auth_source: 'db' },
    multiSort: ['email'],
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case SETTINGS_FETCH_ORDER_STATUS:
      return {
        ...state,
        orderStatus: { ...state.orderStatus, loading: true, error: '' },
      };
    case SETTINGS_FETCH_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        orderStatus: {
          ...state.orderStatus,
          loading: false,
          error: '',
          data: action.payload.data,
        },
      };
    case SETTINGS_FETCH_ORDER_STATUS_ERROR:
      return {
        ...state,
        orderStatus: {
          ...state.orderStatus,
          loading: false,
          error: action.payload.message,
        },
      };
    case SETTINGS_DELETE_ORDER_STATUS:
      return { ...state, orderStatus: { ...state.orderStatus, loading: true } };
    case SETTINGS_DELETE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        orderStatus: { ...state.orderStatus, loading: false, error: '' },
      };
    case SETTINGS_DELETE_ORDER_STATUS_ERROR:
      return {
        ...state,
        orderStatus: {
          ...state.orderStatus,
          loading: false,
          error: action.payload.message,
        },
      };
    case SETTINGS_ORDER_STATUS_LIST:
      return managementScreenActions.fetchList(state, 'orderStatus');
    case SETTINGS_ORDER_STATUS_LIST_SUCCESS:
      return managementScreenActions.fetchListSuccess(
        state,
        'orderStatus',
        action
      );
    case SETTINGS_ORDER_STATUS_LIST_ERROR:
      return managementScreenActions.fetchError(state, 'orderStatus', action);
    case SETTINGS_ORDER_STATUS_CHANGE_SORT:
      return managementScreenActions.updateSort(state, 'orderStatus', action);
    case SETTINGS_FETCH_TAG_DETAIL:
      return {
        ...state,
        tag: {
          ...state.tag,
          loading: true,
          error: '',
        },
      };
    case SETTINGS_FETCH_TAG_DETAIL_SUCCESS:
      return {
        ...state,
        tag: {
          ...state.tag,
          loading: false,
          error: '',
          data: action.payload.data,
        },
      };
    case SETTINGS_TAG_DETAIL_ERROR:
      return {
        ...state,
        tag: {
          ...state.tag,
          loading: false,
          error: action.payload.message,
        },
      };
    case SETTINGS_SUBMIT_NEW_TAG_DETAIL:
      return {
        ...state,
        tag: { ...state.tag, loading: true, error: '' },
      };
    case SETTINGS_SUBMIT_UPDATE_TAG_DETAIL:
      return {
        ...state,
        tag: { ...state.tag, loading: true, error: '' },
      };
    case SETTINGS_TAG_FETCH_TABLE:
      return managementScreenActions.fetchList(state, 'tag');
    case SETTINGS_TAG_FETCH_TABLE_SUCCESS:
      return managementScreenActions.fetchListSuccess(state, 'tag', action);
    case SETTINGS_TAG_FETCH_TABLE_ERROR:
      return managementScreenActions.fetchError(state, 'tag', action);
    case SETTINGS_TAG_CHANGE_SORT:
      return managementScreenActions.updateSort(state, 'tag', action);
    case MATERIAL_TYPES_FETCH_LIST:
      return managementScreenActions.fetchList(state, 'materialType');
    case MATERIAL_TYPES_FETCH_LIST_SUCCESS:
      return managementScreenActions.fetchListSuccess(
        state,
        'materialType',
        action
      );
    case MATERIAL_TYPES_FETCH_LIST_ERROR:
      return managementScreenActions.fetchError(state, 'materialType', action);
    case MATERIAL_TYPES_CHANGE_SORT:
      return managementScreenActions.updateSort(state, 'materialType', action);
    case MATERIAL_TYPES_FETCH_DETAIL:
      return {
        ...state,
        materialType: { ...state.materialType, loading: true, error: '' },
      };
    case MATERIAL_TYPES_FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        materialType: {
          ...state.materialType,
          loading: false,
          error: '',
          data: action.payload.data,
        },
      };
    case MATERIAL_TYPES_ERROR:
      return {
        ...state,
        materialType: {
          ...state.materialType,
          loading: false,
          error: action.payload.message,
        },
      };
    case MATERIAL_TYPES_CREATE:
      return {
        ...state,
        materialType: { ...state.materialType, loading: true },
      };
    case MATERIAL_TYPES_UPDATE:
      return {
        ...state,
        materialType: { ...state.materialType, loading: true },
      };
    case MATERIAL_TYPES_DELETE:
      return {
        ...state,
        materialType: { ...state.materialType, loading: true },
      };
    case MATERIAL_TYPES_ACTION_SUCCESS:
      return {
        ...state,
        materialType: {
          ...state.materialType,
          loading: false,
          list: null,
          detail: null,
        },
      };
    case MATERIAL_TYPES_UPDATE_FILTER:
      return {
        ...state,
        materialType: {
          ...state.materialType,
          filter: action.payload.filter,
        },
      };
    case MATERIALS_FETCH_LIST:
      return {
        ...state,
        materials: { ...state.materials, loading: true, error: '' },
      };
    case MATERIALS_FETCH_LIST_SUCCESS:
      return {
        ...state,
        materials: {
          ...state.materials,
          loading: false,
          error: '',
          list: action.payload.data,
          meta: action.payload.meta,
        },
      };
    case MATERIALS_FETCH_LIST_ERROR:
      return {
        ...state,
        materials: {
          ...state.materials,
          loading: false,
          error: action.payload.message,
        },
      };
    case MATERIALS_FETCH_DETAIL:
      return {
        ...state,
        materials: { ...state.materials, loading: true, error: '' },
      };
    case MATERIALS_FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        materials: {
          ...state.materials,
          loading: false,
          error: '',
          detail: action.payload.data,
        },
      };
    case MATERIALS_DETAIL_ERROR:
      return {
        ...state,
        materials: {
          ...state.materials,
          loading: false,
          error: action.payload.message,
        },
      };
    case MATERIALS_DETAIL_CODES_CHANGE_SORT:
      return {
        ...state,
        materials: {
          ...state.materials,
          codesMultiSort: action.payload.multiSort,
        },
      };
    case MATERIALS_DETAIL_QUANTITIES_CHANGE_SORT:
      return {
        ...state,
        materials: {
          ...state.materials,
          quantitiesMultiSort: action.payload.multiSort,
        },
      };
    case MATERIALS_CREATE:
      return {
        ...state,
        materials: { ...state.materials, loading: true, error: '' },
      };
    case MATERIALS_UPDATE:
      return {
        ...state,
        materials: { ...state.materials, loading: true, error: '' },
      };
    case MATERIALS_DELETE:
      return {
        ...state,
        materials: { ...state.materials, loading: true, error: '' },
      };
    case MATERIALS_SUCCESS:
      return {
        ...state,
        materials: {
          ...state.materials,
          loading: false,
          error: '',
          list: null,
          detail: null,
        },
      };
    case MATERIALS_CHANGE_FILTER:
      return {
        ...state,
        materials: {
          ...state.materials,
          filter: action.payload.filter,
        },
      };
    case MATERIALS_CHANGE_SORT:
      return {
        ...state,
        materials: {
          ...state.materials,
          multiSort: action.payload.multiSort,
        },
      };
    case SUPPLIERS_FETCH_LIST:
      return {
        ...state,
        suppliers: { ...state.suppliers, loading: true, error: '' },
      };
    case SUPPLIERS_FETCH_LIST_SUCCESS:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          loading: false,
          error: '',
          list: action.payload.data,
          meta: action.payload.meta,
        },
      };
    case SUPPLIERS_FETCH_LIST_ERROR:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          loading: false,
          error: action.payload.message,
        },
      };
    case SUPPLIERS_FETCH_DETAIL:
      return {
        ...state,
        suppliers: { ...state.suppliers, loading: true },
      };
    case SUPPLIERS_FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          loading: false,
          detail: action.payload.data,
          error: '',
        },
      };
    case SUPPLIERS_FETCH_DETAIL_ERROR:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          loading: false,
          error: action.payload.message,
        },
      };
    case SUPPLIERS_CREATE:
      return {
        ...state,
        suppliers: { ...state.suppliers, loading: true },
      };
    case SUPPLIERS_UPDATE:
      return {
        ...state,
        suppliers: { ...state.suppliers, loading: true },
      };
    case SUPPLIERS_DELETE:
      return {
        ...state,
        suppliers: { ...state.suppliers, loading: true },
      };
    case SUPPLIERS_ACTION_SUCCESS:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          loading: false,
          error: '',
          detail: null,
          list: null,
        },
      };
    case SUPPLIERS_ACTION_ERROR:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          loading: false,
          error: action.payload.message,
        },
      };
    case SUPPLIERS_UPDATE_FILTER:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          filter: action.payload.filter,
        },
      };
    case SUPPLIERS_CHANGE_SORT:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          multiSort: action.payload.multiSort,
        },
      };
    case MATERIAL_CODES_FETCH_LIST:
      return {
        ...state,
        materialCodes: {
          ...state.materialCodes,
          loading: true,
          error: '',
        },
      };
    case MATERIAL_CODES_FETCH_LIST_SUCCESS:
      return {
        ...state,
        materialCodes: {
          ...state.materialCodes,
          loading: false,
          error: '',
          list: action.payload.data,
          meta: action.payload.meta,
        },
      };
    case MATERIAL_CODES_FETCH_ERROR:
      return {
        ...state,
        materialCodes: {
          ...state.materialCodes,
          loading: false,
          error: action.payload.message,
        },
      };
    case MATERIAL_CODES_FETCH_DETAIL:
      return {
        ...state,
        materialCodes: {
          ...state.materialCodes,
          loading: true,
        },
      };
    case MATERIAL_CODES_FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        materialCodes: {
          ...state.materialCodes,
          loading: false,
          detail: action.payload.data,
        },
      };
    case MATERIAL_CODES_CREATE:
      return {
        ...state,
        materialCodes: {
          ...state.materialCodes,
          loading: true,
        },
        materialCodeList: {
          ...state.materialCodeList,
          list: null,
        },
      };
    case MATERIAL_CODES_UPDATE:
      return {
        ...state,
        materialCodes: {
          ...state.materialCodes,
          loading: true,
        },
        materialCodeList: {
          ...state.materialCodeList,
          list: null,
        },
      };
    case MATERIAL_CODES_DELETE:
      return {
        ...state,
        materialCodes: {
          ...state.materialCodes,
          loading: true,
        },
        materialCodeList: {
          ...state.materialCodeList,
          list: null,
        },
      };
    case MATERIAL_CODES_ACTION_SUCCESS:
      return {
        ...state,
        materialCodes: {
          ...state.materialCodes,
          loading: false,
          list: null,
          detail: null,
        },
      };
    case MATERIAL_CODES_ACTION_ERROR:
      return {
        ...state,
        materialCodes: {
          ...state.materialCodes,
          loading: false,
          error: action.payload.message,
        },
      };
    case WAREHOUSES_SETTINGS_FETCH_LIST:
      return managementScreenActions.fetchList(state, 'warehouses');
    case WAREHOUSES_SETTINGS_FETCH_LIST_SUCCESS:
      return managementScreenActions.fetchListSuccess(
        state,
        'warehouses',
        action
      );
    case WAREHOUSES_SETTINGS_FETCH_ERROR:
      return managementScreenActions.fetchError(state, 'warehouses', action);
    case WAREHOUSES_SETTINGS_FETCH_DETAIL:
      return managementScreenActions.fetchDetail(state, 'warehouses');
    case WAREHOUSES_SETTINGS_FETCH_DETAIL_SUCCESS:
      return managementScreenActions.fetchDetailSuccess(
        state,
        'warehouses',
        action
      );
    case WAREHOUSES_SETTINGS_CREATE:
      return managementScreenActions.createAction(state, 'warehouses');
    case WAREHOUSES_SETTINGS_UPDATE:
      return managementScreenActions.updateAction(state, 'warehouses');
    case WAREHOUSES_SETTINGS_DELETE:
      return managementScreenActions.deleteAction(state, 'warehouses');
    case WAREHOUSES_SETTINGS_ACTION_SUCCESS:
      return managementScreenActions.actionSuccess(state, 'warehouses');
    case WAREHOUSES_SETTINGS_ACTION_ERROR:
      return managementScreenActions.actionSuccess(state, 'warehouses', action);
    case WAREHOUSES_SETTINGS_UPDATE_FILTER:
      return managementScreenActions.updateFilter(state, 'warehouses', action);
    case WAREHOUSES_SETTINGS_CHANGE_SORT:
      return managementScreenActions.updateSort(state, 'warehouses', action);
    case MATERIALS_DETAIL_FETCH_CODES:
      return managementScreenActions.fetchList(state, 'materialCodeList');
    case MATERIALS_DETAIL_FETCH_CODES_SUCCESS:
      return managementScreenActions.fetchListSuccess(
        state,
        'materialCodeList',
        action
      );
    case MATERIALS_DETAIL_FETCH_CODES_ERROR:
      return managementScreenActions.fetchError(
        state,
        'materialCodeList',
        action
      );
    case SETTINGS_PURPOSES_TABLE:
      return managementScreenActions.fetchList(state, 'purposes');
    case SETTINGS_PURPOSES_TABLE_SUCCESS:
      return managementScreenActions.fetchListSuccess(
        state,
        'purposes',
        action
      );
    case SETTINGS_PURPOSES_ERROR:
      return managementScreenActions.fetchError(state, 'purposes', action);
    case SETTINGS_PURPOSES_CHANGE_SORT:
      return managementScreenActions.updateSort(state, 'purposes', action);
    case SETTINGS_PURPOSES_DETAIL:
      return managementScreenActions.fetchDetail(state, 'purposes');
    case SETTINGS_PURPOSES_DETAIL_SUCCESS:
      return managementScreenActions.fetchDetailSuccess(
        state,
        'purposes',
        action
      );
    case SETTINGS_PURPOSES_CREATE:
      return managementScreenActions.createAction(state, 'purposes');
    case SETTINGS_PURPOSES_UPDATE:
      return managementScreenActions.updateAction(state, 'purposes');
    case SETTINGS_PURPOSES_DELETE:
      return managementScreenActions.deleteAction(state, 'purposes');
    case SETTINGS_PURPOSES_ACTION_SUCCESS:
      return managementScreenActions.actionSuccess(state, 'purposes');

    case SETTINGS_USERS_LIST_FETCH:
      return managementScreenActions.fetchList(state, 'users');
    case SETTINGS_USERS_LIST_FETCH_SUCCESS:
      return managementScreenActions.fetchListSuccess(state, 'users', action);
    case SETTINGS_USERS_FETCH_ERROR:
      return managementScreenActions.fetchError(state, 'users', action);
    case SETTINGS_USERS_DETAIL_FETCH:
      return managementScreenActions.fetchDetail(state, 'users');
    case SETTINGS_USERS_DETAIL_FETCH_SUCCESS:
      return managementScreenActions.fetchDetailSuccess(state, 'users', action);
    case SETTINGS_USERS_CREATE:
      return managementScreenActions.createAction(state, 'users');
    case SETTINGS_USERS_UPDATE:
      return managementScreenActions.updateAction(state, 'users');
    case SETTINGS_USERS_DELETE:
      return managementScreenActions.deleteAction(state, 'users');
    case SETTINGS_USERS_ACTION_SUCCESS:
      return managementScreenActions.actionSuccess(state, 'users');
    case SETTINGS_USERS_ACTION_ERROR:
      return managementScreenActions.actionError(state, 'users', action);
    case SETTINGS_USERS_UPDATE_FILTER:
      return managementScreenActions.updateFilter(state, 'users', action);
    case SETTINGS_USERS_UPDATE_SORT:
      return managementScreenActions.updateSort(state, 'users', action);
    default:
      return { ...state };
  }
};
