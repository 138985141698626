import { createSlice } from '@reduxjs/toolkit';
import { fork, all, takeLatest, put } from 'redux-saga/effects';
import { pathOr } from 'rambda';
import { fetchActionAsync, submitActionAsync } from './factories/apiAdapter';
import { defaultWlfFields } from '../views/app/profile/WarehouseLogFieldsSelector';
import { defaultAlfFields } from '../views/app/profile/AccountingLogFieldsSelector';

/* eslint-disable no-param-reassign */
/* no-param-reassign required: https://redux-toolkit.js.org/usage/immer-reducers */

const currentUserSettingsVersion = '1';

const supplyMissingColumns = (source, available) => {
  const allColumns = { ...source };
  available.forEach((columnName) => {
    if (!allColumns[columnName]) {
      allColumns[columnName] = {
        order: Object.keys(allColumns).length,
        visible: true,
      };
    }
  });
  return allColumns;
};

const convertV0 = (selectedColumns, columnOrder, allColumns) => {
  // build map from all columns
  const columnMap = {};
  allColumns.forEach((c) => {
    columnMap[c] = { visible: selectedColumns.includes(c), order: 1000 };
  });
  columnOrder.forEach((d, index) => {
    columnMap[d] = { ...columnMap[d], order: index };
  });
  return columnMap;
};

const createProfileReducer = () => {
  const slice = createSlice({
    name: 'profile',
    initialState: {
      currentUser: null,
      loading: false,
      movementColumns: {},
      accountingColumns: {},
    },
    reducers: {
      fetchUserInfo: (state) => {
        state.loading = true;
      },
      setUserInfo: (state, action) => {
        state.loading = false;
        state.currentUser = action.payload.response;
        const version = pathOr(
          null,
          'payload.response.settings.version',
          action
        );
        if (version) {
          state.movementColumns = pathOr(
            {},
            'payload.response.settings.warehouselog_fields',
            action
          );
          state.accountingColumns = pathOr(
            {},
            'payload.response.settings.accountinglog_fields',
            action
          );
        } else {
          const settings = pathOr({}, 'payload.response.settings', action);
          state.movementColumns = convertV0(
            pathOr(defaultWlfFields, 'warehouselog_fields', settings),
            pathOr(defaultWlfFields, 'warehouselog_fields_order', settings),
            defaultWlfFields
          );
          state.accountingColumns = convertV0(
            pathOr(defaultAlfFields, 'accountinglog_fields', settings),
            pathOr(defaultAlfFields, 'accountinglog_fields_order', settings),
            defaultAlfFields
          );
        }
        state.movementColumns = supplyMissingColumns(
          state.movementColumns,
          defaultWlfFields
        );
        state.accountingColumns = supplyMissingColumns(
          state.accountingColumns,
          defaultAlfFields
        );
      },
      updateMovementColumns: (state, action) => {
        state.movementColumns = action.payload.columns;
      },
      updateAccountingColumns: (state, action) => {
        state.accountingColumns = action.payload.columns;
      },
    },
  });
  return slice.reducer;
};

const createProfileSagas = () => {
  function* fetchUserInfoAsync(action) {
    yield fetchActionAsync(action, 'users/me/', 'profile/setUserInfo');
  }

  function* updateUserInfoAsync(action) {
    yield submitActionAsync(action, 'users/me/', null);
    yield put({ type: 'profile/fetchUserInfo' });
  }

  const fetchUserInfoWatcher = function* () {
    yield takeLatest('profile/fetchUserInfo', fetchUserInfoAsync);
  };

  const updateUserInfoWatcher = function* () {
    yield takeLatest('profile/updateUserInfo', updateUserInfoAsync);
  };

  return function* () {
    yield all([fork(fetchUserInfoWatcher), fork(updateUserInfoWatcher)]);
  };
};

export { createProfileReducer, createProfileSagas, currentUserSettingsVersion };
