/* eslint-disable import/no-cycle */
import {
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_ERROR,
  FETCH_USER_DETAILS_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  PROFILE_CHANGE_PASSWORD,
  PROFILE_CHANGE_PASSWORD_ERROR,
  PROFILE_CHANGE_PASSWORD_SUCCESS,
  PROFILE_UPDATE,
  PROFILE_UPDATE_ERROR,
  PROFILE_UPDATE_SUCCESS,
} from '../action_codes';

export const loginUser = (user, history, from) => ({
  type: LOGIN_USER,
  payload: { user, history, from },
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const fetchUserDetails = (history, from) => ({
  type: FETCH_USER_DETAILS,
  payload: { history, from },
});

export const userDetailsSuccess = (user) => ({
  type: FETCH_USER_DETAILS_SUCCESS,
  payload: { user },
});

export const userDetailsError = (message) => ({
  type: FETCH_USER_DETAILS_ERROR,
  payload: { message },
});

export const profileUpdate = (url, data, onFinish) => ({
  type: PROFILE_UPDATE,
  payload: { url, data, onFinish },
});

export const profileUpdateSuccess = () => ({
  type: PROFILE_UPDATE_SUCCESS,
  payload: {},
});

export const profileUpdateError = (message, formErrors) => ({
  type: PROFILE_UPDATE_ERROR,
  payload: { message, formErrors },
});

export const profileChangePassword = (url, data, onFinish) => ({
  type: PROFILE_CHANGE_PASSWORD,
  payload: { url, data, onFinish },
});

export const profileChangePasswordSuccess = () => ({
  type: PROFILE_CHANGE_PASSWORD_SUCCESS,
  payload: {},
});

export const profileChangePasswordError = ({ message }) => ({
  type: PROFILE_CHANGE_PASSWORD_ERROR,
  payload: { message },
});
