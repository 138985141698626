import {
  WAREHOUSES_SUBMIT_MOVEMENT,
  WAREHOUSES_SUBMIT_SUCCESS,
  WAREHOUSES_SUBMIT_ERROR,
  WAREHOUSES_FETCH_MOVEMENT_LIST,
  WAREHOUSES_FETCH_MOVEMENT_LIST_SUCCESS,
  WAREHOUSES_FETCH_MOVEMENT_LIST_ERROR,
  WAREHOUSES_FETCH_INVENTORY,
  WAREHOUSES_UPDATE_INVENTORY_FILTER,
  WAREHOUSES_FETCH_INVENTORY_SUCCESS,
  WAREHOUSES_FETCH_INVENTORY_ERROR,
  WAREHOUSES_LOOKUP_DETAILS,
  WAREHOUSES_LOOKUP_DETAILS_SUCCESS,
  WAREHOUSES_LOOKUP_DETAILS_ERROR,
  WAREHOUSES_INVOICES_FETCH_LIST,
  WAREHOUSES_INVOICES_FETCH_LIST_SUCCESS,
  WAREHOUSES_INVOICES_FETCH_ERROR,
  WAREHOUSES_INVOICES_FETCH_DETAIL,
  WAREHOUSES_INVOICES_FETCH_DETAIL_SUCCESS,
  WAREHOUSES_INVOICES_CREATE,
  WAREHOUSES_INVOICES_UPDATE,
  WAREHOUSES_INVOICES_DELETE,
  WAREHOUSES_INVOICES_ACTION_SUCCESS,
  WAREHOUSES_INVOICES_ACTION_ERROR,
  WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS,
  WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS_SUCCESS,
  WAREHOUSES_INVOICES_UPDATE_ASSIGNMENTS,
  WAREHOUSES_MOVEMENT_FETCH_DETAIL,
  WAREHOUSES_MOVEMENT_SUBMIT_DETAIL,
  WAREHOUSES_MOVEMENT_SUBMIT_SUCCESS,
  WAREHOUSES_MOVEMENT_FETCH_DETAIL_SUCCESS,
  WAREHOUSES_MOVEMENT_ERROR,
  WAREHOUSES_SUBMIT_CHANGE_MOVEMENT,
  WAREHOUSES_SUBMIT_REVERT_MOVEMENT,
  WAREHOUSES_MOVEMENTS_UPDATE_FILTER,
  WAREHOUSES_INVOICES_CLEAR,
  WAREHOUSES_INVOICES_UPDATE_FILTER,
  WAREHOUSES_INVOICES_TOLL_FETCH_LIST,
  WAREHOUSES_INVOICES_TOLL_FETCH_LIST_SUCCESS,
  WAREHOUSES_INVOICES_TOLL_UPDATE_FILTER,
  WAREHOUSES_INVOICES_TOLL_CREATE,
  WAREHOUSES_INVOICES_TOLL_UPDATE,
  WAREHOUSES_INVOICES_TOLL_DELETE,
  WAREHOUSES_INVOICES_TOLL_ACTION_SUCCESS,
  WAREHOUSES_INVOICES_TOLL_ACTION_ERROR,
  WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL,
  WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL_SUCCESS,
  WAREHOUSES_INVOICES_TOLL_CLEAR,
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST,
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST_SUCCESS,
  WAREHOUSES_INVOICES_TRANSPORT_UPDATE_FILTER,
  WAREHOUSES_INVOICES_TRANSPORT_CREATE,
  WAREHOUSES_INVOICES_TRANSPORT_UPDATE,
  WAREHOUSES_INVOICES_TRANSPORT_DELETE,
  WAREHOUSES_INVOICES_TRANSPORT_ACTION_SUCCESS,
  WAREHOUSES_INVOICES_TRANSPORT_ACTION_ERROR,
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL,
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL_SUCCESS,
  WAREHOUSES_INVOICES_TRANSPORT_CLEAR,
  WAREHOUSES_INVOICES_TOLL_UPDATE_ASSIGNMENTS,
  WAREHOUSES_INVOICES_TRANSPORT_UPDATE_ASSIGNMENTS,
  WAREHOUSES_FETCH_AVAIL_PURPOSES,
  WAREHOUSES_FETCH_AVAIL_PURPOSES_SUCCESS,
  WAREHOUSES_FETCH_AVAIL_PURPOSES_FAILURE,
  WAREHOUSES_SUBMIT_TRANSFER,
  WAREHOUSES_FETCH_TRANSFERS_LIST,
  WAREHOUSES_TRANSFERS_UPDATE_FILTER,
  WAREHOUSES_FETCH_TRANSFERS_LIST_SUCCESS,
  WAREHOUSES_FETCH_TRANSFERS_LIST_ERROR,
  WAREHOUSES_TRANSFER_FETCH_DETAIL,
  WAREHOUSES_TRANSFER_FETCH_DETAIL_SUCCESS,
  WAREHOUSES_TRANSFER_SUBMIT_DETAIL,
  WAREHOUSES_TRANSFER_SUBMIT_SUCCESS,
  WAREHOUSES_TRANSFER_ERROR,
  WAREHOUSES_MOVEMENT_LIST_SORT,
  WAREHOUSES_ACCOUNTING_LIST_SORT,
  WAREHOUSES_INVOICES_UPDATE_SORT,
  WAREHOUSES_INVOICES_TOLL_CHANGE_SORT,
  WAREHOUSES_INVOICES_TRANSPORT_CHANGE_SORT,
  WAREHOUSES_TRANSFER_LIST_CHANGE_SORT,
} from '../action_codes';
import { getDefaultPageSize } from '../../helpers/Utils';
import { managementScreenActions } from '../helpers';

const INIT_STATE = {
  movementSubmitting: false,
  movementSubmitError: false,
  movementsListData: null,
  movementsListLoading: false,
  movementsListError: '',
  movementsListFilter: {
    query: '',
    purpose_filter: null,
    started_at_gte: null,
    started_at_lt: null,
    material_type_id: null,
    tag_id: null,
  },
  movementsListMultiSort: ['-created_at'],
  movementsListMeta: {
    limit: getDefaultPageSize(),
    page: 1,
    multiSort: ['-created_at'],
  },
  transfersListData: null,
  transfersListLoading: false,
  transfersListError: '',
  transfersListFilter: {
    query: '',
    started_at_gte: null,
    started_at_lt: null,
  },
  transfersListMultiSort: ['-created_at'],
  transfersListMeta: {
    limit: getDefaultPageSize(),
    page: 1,
  },
  inventoryData: null,
  inventoryMeta: {
    limit: getDefaultPageSize(),
    page: 1,
    multiSort: [],
  },
  inventoryLoading: false,
  inventoryError: '',
  inventoryFilter: {
    material_id: [],
    warehouse_id: [],
    material_type_id: [],
    tag_id: [],
    created_at: null,
    has_quantity: false,
  },
  materialDetail: null,
  materialDetailLoading: false,
  materialDetailError: '',
  invoices: {
    list: null,
    detail: null,
    meta: { page: 1, limit: getDefaultPageSize() },
    loading: false,
    error: '',
    deliveryLists: '',
    multiSort: ['number'],
    filter: {
      dateType: null,
      dateStart: null,
      dateEnd: null,
      status: null,
      expression: '',
    },
  },
  invoicesToll: {
    list: null,
    detail: null,
    meta: { page: 1, limit: getDefaultPageSize() },
    loading: false,
    error: '',
    deliveryLists: '',
    multiSort: ['number'],
    filter: {
      dateType: null,
      dateStart: null,
      dateEnd: null,
      status: null,
    },
  },
  invoicesTransport: {
    list: null,
    detail: null,
    meta: { page: 1, limit: getDefaultPageSize() },
    loading: false,
    error: '',
    deliveryLists: '',
    filter: {
      dateType: null,
      dateStart: null,
      dateEnd: null,
      status: null,
    },
    multiSort: ['number'],
  },
  movementDetail: null,
  movementDetailLoading: false,
  movementDetailError: '',
  transferDetail: null,
  transferDetailLoading: false,
  transferDetailError: '',
  availablePurposes: null,
  availablePurposesLoading: false,
  availablePurposesError: '',
  accountingListMultiSort: ['created_at'],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WAREHOUSES_SUBMIT_MOVEMENT:
    case WAREHOUSES_SUBMIT_TRANSFER:
      return { ...state, movementSubmitting: true, movementSubmitError: '' };
    case WAREHOUSES_SUBMIT_REVERT_MOVEMENT:
      return { ...state, movementSubmitting: true, movementSubmitError: '' };
    case WAREHOUSES_SUBMIT_CHANGE_MOVEMENT:
      return { ...state, movementSubmitting: true, movementSubmitError: '' };
    case WAREHOUSES_SUBMIT_SUCCESS:
      return {
        ...state,
        movementSubmitting: false,
        movementSubmitError: '',
        movementsListData: null,
      };
    case WAREHOUSES_SUBMIT_ERROR:
      return {
        ...state,
        movementSubmitting: false,
        movementSubmitError: action.payload.message,
      };
    case WAREHOUSES_FETCH_MOVEMENT_LIST:
      return {
        ...state,
        movementsListLoading: true,
        movementsListError: '',
      };
    case WAREHOUSES_MOVEMENTS_UPDATE_FILTER:
      return {
        ...state,
        movementsListFilter: action.payload.filter,
      };
    case WAREHOUSES_ACCOUNTING_LIST_SORT:
      return {
        ...state,
        accountingListMultiSort: action.payload.multiSort,
      };
    case WAREHOUSES_FETCH_MOVEMENT_LIST_SUCCESS:
      return {
        ...state,
        movementsListLoading: false,
        movementsListError: '',
        movementsListData: action.payload.data,
        movementsListMeta: action.payload.meta,
      };
    case WAREHOUSES_FETCH_MOVEMENT_LIST_ERROR:
      return {
        ...state,
        movementsListError: action.payload.message,
        movementsListLoading: false,
      };
    case WAREHOUSES_MOVEMENT_LIST_SORT:
      return {
        ...state,
        movementsListMultiSort: action.payload.multiSort,
      };

    case WAREHOUSES_FETCH_TRANSFERS_LIST:
      return {
        ...state,
        transfersListLoading: true,
        transfersListError: '',
      };
    case WAREHOUSES_TRANSFERS_UPDATE_FILTER:
      return {
        ...state,
        transfersListFilter: action.payload.filter,
      };
    case WAREHOUSES_FETCH_TRANSFERS_LIST_SUCCESS:
      return {
        ...state,
        transfersListLoading: false,
        transfersListError: '',
        transfersListData: action.payload.data,
        transfersListMeta: action.payload.meta,
      };
    case WAREHOUSES_FETCH_TRANSFERS_LIST_ERROR:
      return {
        ...state,
        transfersListError: action.payload.message,
        transfersListLoading: false,
      };

    case WAREHOUSES_UPDATE_INVENTORY_FILTER:
      return {
        ...state,
        inventoryFilter: action.payload.filter,
      };
    case WAREHOUSES_FETCH_INVENTORY:
      return {
        ...state,
        inventoryLoading: true,
        inventoryError: '',
      };
    case WAREHOUSES_FETCH_INVENTORY_SUCCESS:
      return {
        ...state,
        inventoryData: action.payload.data,
        inventoryMeta: action.payload.meta,
        inventoryLoading: false,
        inventoryError: '',
      };
    case WAREHOUSES_FETCH_INVENTORY_ERROR:
      return {
        ...state,
        inventoryLoading: false,
        inventoryError: action.payload.message,
      };
    case WAREHOUSES_LOOKUP_DETAILS:
      return {
        ...state,
        materialDetailLoading: true,
      };
    case WAREHOUSES_LOOKUP_DETAILS_SUCCESS:
      return {
        ...state,
        materialDetail: action.payload.data,
        materialDetailLoading: false,
        materialDetailError: '',
      };
    case WAREHOUSES_LOOKUP_DETAILS_ERROR:
      return {
        ...state,
        materialDetailLoading: false,
        materialDetailError: action.payload.message,
      };
    case WAREHOUSES_INVOICES_CLEAR:
      return {
        ...state,
        invoices: { ...state.invoices, list: null },
      };
    case WAREHOUSES_INVOICES_FETCH_LIST:
      return managementScreenActions.fetchList(state, 'invoices');
    case WAREHOUSES_INVOICES_FETCH_LIST_SUCCESS:
      return managementScreenActions.fetchListSuccess(
        state,
        'invoices',
        action
      );
    case WAREHOUSES_INVOICES_UPDATE_SORT:
      return managementScreenActions.updateSort(state, 'invoices', action);
    case WAREHOUSES_INVOICES_TOLL_FETCH_LIST:
      return managementScreenActions.fetchList(state, 'invoicesToll');
    case WAREHOUSES_INVOICES_TOLL_FETCH_LIST_SUCCESS:
      return managementScreenActions.fetchListSuccess(
        state,
        'invoicesToll',
        action
      );
    case WAREHOUSES_INVOICES_TOLL_CHANGE_SORT:
      return managementScreenActions.updateSort(state, 'invoicesToll', action);
    case WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL:
      return managementScreenActions.fetchDetail(state, 'invoicesToll');
    case WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL_SUCCESS:
      return managementScreenActions.fetchDetailSuccess(
        state,
        'invoicesToll',
        action
      );
    case WAREHOUSES_INVOICES_TOLL_CLEAR:
      return {
        ...state,
        invoicesToll: { ...state.invoicesToll, list: null },
      };
    case WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST:
      return managementScreenActions.fetchList(state, 'invoicesTransport');
    case WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST_SUCCESS:
      return managementScreenActions.fetchListSuccess(
        state,
        'invoicesTransport',
        action
      );
    case WAREHOUSES_INVOICES_TRANSPORT_CHANGE_SORT:
      return managementScreenActions.updateSort(
        state,
        'invoicesTransport',
        action
      );
    case WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL:
      return managementScreenActions.fetchDetail(state, 'invoicesTransport');
    case WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL_SUCCESS:
      return managementScreenActions.fetchDetailSuccess(
        state,
        'invoicesTransport',
        action
      );
    case WAREHOUSES_INVOICES_TRANSPORT_CLEAR:
      return {
        ...state,
        invoicesTransport: { ...state.invoicesTransport, list: null },
      };
    case WAREHOUSES_INVOICES_FETCH_ERROR:
      return managementScreenActions.fetchError(state, 'invoices', action);
    case WAREHOUSES_INVOICES_FETCH_DETAIL:
      return managementScreenActions.fetchDetail(state, 'invoices');
    case WAREHOUSES_INVOICES_FETCH_DETAIL_SUCCESS:
      return managementScreenActions.fetchDetailSuccess(
        state,
        'invoices',
        action
      );
    case WAREHOUSES_INVOICES_CREATE:
      return managementScreenActions.createAction(state, 'invoices');
    case WAREHOUSES_INVOICES_UPDATE:
      return managementScreenActions.updateAction(state, 'invoices');
    case WAREHOUSES_INVOICES_DELETE:
      return managementScreenActions.deleteAction(state, 'invoices');
    case WAREHOUSES_INVOICES_ACTION_SUCCESS:
      return managementScreenActions.actionSuccess(state, 'invoices');
    case WAREHOUSES_INVOICES_ACTION_ERROR:
      return managementScreenActions.actionError(state, 'invoices', action);
    case WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS:
      return {
        ...state,
        invoices: { ...state.invoices, loading: true, error: '' },
      };
    case WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS_SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: false,
          error: '',
          deliveryLists: action.payload.data,
        },
      };
    case WAREHOUSES_INVOICES_UPDATE_ASSIGNMENTS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: true,
          error: '',
        },
      };
    case WAREHOUSES_INVOICES_TOLL_UPDATE_ASSIGNMENTS:
      return {
        ...state,
        invoicesToll: {
          ...state.invoicesToll,
          loading: true,
          error: '',
        },
      };
    case WAREHOUSES_INVOICES_TRANSPORT_UPDATE_ASSIGNMENTS:
      return {
        ...state,
        invoicesTransport: {
          ...state.invoicesTransport,
          loading: true,
          error: '',
        },
      };
    case WAREHOUSES_INVOICES_UPDATE_FILTER:
      return {
        ...state,
        invoices: { ...state.invoices, filter: action.payload.filter },
      };
    case WAREHOUSES_INVOICES_TOLL_UPDATE_FILTER:
      return {
        ...state,
        invoicesToll: { ...state.invoicesToll, filter: action.payload.filter },
      };
    case WAREHOUSES_INVOICES_TOLL_CREATE:
      return managementScreenActions.createAction(state, 'invoicesToll');
    case WAREHOUSES_INVOICES_TOLL_UPDATE:
      return managementScreenActions.updateAction(state, 'invoicesToll');
    case WAREHOUSES_INVOICES_TOLL_DELETE:
      return managementScreenActions.deleteAction(state, 'invoicesToll');
    case WAREHOUSES_INVOICES_TOLL_ACTION_SUCCESS:
      return managementScreenActions.actionSuccess(state, 'invoicesToll');
    case WAREHOUSES_INVOICES_TOLL_ACTION_ERROR:
      return managementScreenActions.actionError(state, 'invoicesToll', action);
    case WAREHOUSES_INVOICES_TRANSPORT_UPDATE_FILTER:
      return {
        ...state,
        invoicesTransport: {
          ...state.invoicesTransport,
          filter: action.payload.filter,
        },
      };
    case WAREHOUSES_INVOICES_TRANSPORT_CREATE:
      return managementScreenActions.createAction(state, 'invoicesTransport');
    case WAREHOUSES_INVOICES_TRANSPORT_UPDATE:
      return managementScreenActions.updateAction(state, 'invoicesTransport');
    case WAREHOUSES_INVOICES_TRANSPORT_DELETE:
      return managementScreenActions.deleteAction(state, 'invoicesTransport');
    case WAREHOUSES_INVOICES_TRANSPORT_ACTION_SUCCESS:
      return managementScreenActions.actionSuccess(state, 'invoicesTransport');
    case WAREHOUSES_INVOICES_TRANSPORT_ACTION_ERROR:
      return managementScreenActions.actionError(
        state,
        'invoicesTransport',
        action
      );
    case WAREHOUSES_MOVEMENT_FETCH_DETAIL:
      return { ...state, movementDetail: null, movementDetailLoading: true };
    case WAREHOUSES_MOVEMENT_FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        movementDetail: action.payload.data,
        movementDetailLoading: false,
        movementDetailError: '',
      };
    case WAREHOUSES_MOVEMENT_SUBMIT_DETAIL:
      return { ...state, movementDetailLoading: true };
    case WAREHOUSES_MOVEMENT_SUBMIT_SUCCESS:
      return {
        ...state,
        movementDetailLoading: false,
        movementDetailError: '',
        movementsListData: null,
      };
    case WAREHOUSES_MOVEMENT_ERROR:
      return {
        ...state,
        movementDetailLoading: false,
        movementDetailError: action.payload.message,
      };
    case WAREHOUSES_TRANSFER_FETCH_DETAIL:
      return { ...state, transferDetail: null, transferDetailLoading: true };
    case WAREHOUSES_TRANSFER_FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        transferDetail: action.payload.data,
        transferDetailLoading: false,
        transferDetailError: '',
      };
    case WAREHOUSES_TRANSFER_SUBMIT_DETAIL:
      return { ...state, transferDetailLoading: true };
    case WAREHOUSES_TRANSFER_SUBMIT_SUCCESS:
      return {
        ...state,
        transferDetailLoading: false,
        transferDetailError: '',
        transferListData: null,
      };
    case WAREHOUSES_TRANSFER_ERROR:
      return {
        ...state,
        transferDetailLoading: false,
        transferDetailError: action.payload.message,
      };
    case WAREHOUSES_TRANSFER_LIST_CHANGE_SORT:
      return {
        ...state,
        transfersListMultiSort: action.payload.multiSort,
      };
    case WAREHOUSES_FETCH_AVAIL_PURPOSES:
      return {
        ...state,
        availablePurposesLoading: true,
        availablePurposesError: '',
      };
    case WAREHOUSES_FETCH_AVAIL_PURPOSES_SUCCESS:
      return {
        ...state,
        availablePurposes: action.payload.data,
      };
    case WAREHOUSES_FETCH_AVAIL_PURPOSES_FAILURE:
      return {
        ...state,
        availablePurposesLoading: false,
        availablePurposesError: action.payload.message,
      };
    default:
      return { ...state };
  }
};
