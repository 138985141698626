import React from 'react';
import { Label } from 'reactstrap';
import { Field } from 'formik';
import Switch from 'rc-switch';

const FormSwitch = ({ label, name, aligned, disabled }) => {
  return (
    <Field name={name}>
      {({ field, form: { isSubmitting, setFieldValue } }) => (
        <div
          className={`switch-wrapper${
            aligned ? ' switch-wrapper-aligned' : ''
          }`}
        >
          <input type="hidden" name="name" value={field.value} />
          <Switch
            loadingIcon={null}
            disabled={isSubmitting || disabled}
            className="custom-switch custom-switch-primary mr-3"
            checked={field.value}
            onChange={(v) => {
              setFieldValue(name, v);
            }}
          />
          {label && <Label className="m-0">{label}</Label>}
        </div>
      )}
    </Field>
  );
};

export default FormSwitch;
