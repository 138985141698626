import moment from 'moment';
import {
  AUDITS_FETCH_DETAIL,
  AUDITS_FETCH_DETAIL_SUCCESS,
  AUDITS_FETCH_ERROR,
  AUDITS_FETCH_TABLE,
  AUDITS_FETCH_TABLE_SUCCESS,
  AUDITS_FETCH_USERS,
  AUDITS_FETCH_USERS_SUCCESS,
  AUDITS_UPDATE_FILTER,
  AUDITS_UPDATE_SORT,
} from '../action_codes';

export const auditsFetchUsers = () => ({
  type: AUDITS_FETCH_USERS,
  payload: { meta: { page: 1, limit: null, multiSort: ['email'] } },
});

export const auditsFetchError = (message) => ({
  type: AUDITS_FETCH_ERROR,
  payload: { message },
});

export const auditsUpdateSort = (multiSort) => ({
  type: AUDITS_UPDATE_SORT,
  payload: { multiSort },
});

export const auditsFetchUsersSuccess = (data) => ({
  type: AUDITS_FETCH_USERS_SUCCESS,
  payload: { data },
});

export const auditsUpdateFilter = (filter) => ({
  type: AUDITS_UPDATE_FILTER,
  payload: { filter },
});

export const auditsFetchTable = (multiSort, page, limit, filter) => {
  const loBoundary = moment(filter.created_at_gte).startOf('day').toISOString();
  const hiBoundary = moment(filter.created_at_lte).endOf('day').toISOString();
  const filterWithDates = {
    ...filter,
    created_at_gte: loBoundary,
    created_at_lte: hiBoundary,
  };
  return {
    type: AUDITS_FETCH_TABLE,
    payload: { multiSort, page, limit, filter: filterWithDates },
  };
};

export const auditsFetchTableSuccess = (data, meta) => ({
  type: AUDITS_FETCH_TABLE_SUCCESS,
  payload: { data, meta },
});

export const auditsFetchDetail = (url) => ({
  type: AUDITS_FETCH_DETAIL,
  payload: { url },
});

export const auditsFetchDetailSuccess = (data) => ({
  type: AUDITS_FETCH_DETAIL_SUCCESS,
  payload: { data },
});
