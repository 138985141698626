import {
  REPORTS_FETCH,
  REPORTS_FETCH_SUCCESS,
  REPORTS_FETCH_FAILURE,
} from '../action_codes';

export const reportsFetch = (sort, page, limit) => ({
  type: REPORTS_FETCH,
  payload: { sort, page, limit },
});

export const reportsFetchSuccess = (data, meta) => ({
  type: REPORTS_FETCH_SUCCESS,
  payload: { data, meta },
});

export const reportsFetchError = (message) => ({
  type: REPORTS_FETCH_FAILURE,
  payload: { message },
});
