// eslint-disable-next-line import/no-cycle
import {
  FETCH_MATERIALS,
  FETCH_MATERIALS_SUCCESS,
  FETCH_MATERIALS_ERROR,
  FETCH_WAREHOUSES,
  FETCH_CITY_WAREHOUSES,
  FETCH_WAREHOUSES_SUCCESS,
  FETCH_WAREHOUSES_ERROR,
  FETCH_ORDER_STATES,
  FETCH_ORDER_STATES_SUCCESS,
  FETCH_ORDER_STATES_ERROR,
  FETCH_UNITS,
  FETCH_UNITS_SUCCESS,
  FETCH_UNITS_FAILURE,
  FETCH_SUPPLIERS,
  FETCH_SUPPLIERS_SUCCESS,
  FETCH_SUPPLIERS_FAILURE,
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_ERROR,
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_ERROR,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_ERROR,
  FETCH_MATERIAL_TYPES,
  FETCH_MATERIAL_TYPES_ERROR,
  FETCH_MATERIAL_TYPES_SUCCESS,
  FETCH_PURPOSES,
  FETCH_PURPOSES_SUCCESS,
  FETCH_PURPOSES_ERROR,
  FETCH_GLOBAL_PRODUCTS,
  FETCH_GLOBAL_PRODUCTS_SUCCESS,
  FETCH_GLOBAL_PRODUCTS_ERROR,
  CLEAR_GLOBALS,
  FETCH_PRODUCT_SUPPLIERS,
  FETCH_PRODUCT_SUPPLIERS_SUCCESS,
  FETCH_PRODUCT_SUPPLIERS_FAILURE,
  FETCH_GLOBAL_ROLES,
  FETCH_GLOBAL_ROLES_SUCCESS,
  FETCH_GLOBAL_ROLES_ERROR,
  FETCH_CITIES,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_ERROR,
  FETCH_PURPOSES_DISTINCT,
  FETCH_PURPOSES_DISTINCT_SUCCESS,
  FETCH_PURPOSES_DISTINCT_ERROR,
  FETCH_PURPOSES_COMPLETE,
  FETCH_PURPOSES_COMPLETE_SUCCESS,
  FETCH_PURPOSES_COMPLETE_ERROR,
  FETCH_PURPOSES_BY_DIRECTION,
  FETCH_PURPOSES_BY_DIRECTION_SUCCESS,
} from '../action_codes';

export const fetchMaterials = () => ({
  type: FETCH_MATERIALS,
  payload: { multiSort: ['name'] },
});

export const fetchMaterialsSuccess = (materials) => ({
  type: FETCH_MATERIALS_SUCCESS,
  payload: { materials },
});

export const fetchMaterialsError = (message) => ({
  type: FETCH_MATERIALS_ERROR,
  payload: { message },
});

export const fetchWarehouses = () => ({
  type: FETCH_WAREHOUSES,
  payload: { multiSort: ['name'] },
});

export const fetchCityWarehouses = (city) => ({
  type: FETCH_CITY_WAREHOUSES,
  payload: { city, multiSort: ['name'] },
});

export const fetchWarehousesSuccess = (warehouses) => ({
  type: FETCH_WAREHOUSES_SUCCESS,
  payload: { warehouses },
});

export const fetchWarehousesError = (message) => ({
  type: FETCH_WAREHOUSES_ERROR,
  payload: { message },
});

export const fetchOrderStates = () => ({
  type: FETCH_ORDER_STATES,
  payload: { multiSort: ['name'] },
});

export const fetchOrderStatesSuccess = (states) => ({
  type: FETCH_ORDER_STATES_SUCCESS,
  payload: { states },
});

export const fetchOrderStatesError = (message) => ({
  type: FETCH_ORDER_STATES_ERROR,
  payload: { message },
});

export const fetchUnits = () => ({
  type: FETCH_UNITS,
  payload: { multiSort: ['name'] },
});

export const fetchUnitsSuccess = (units) => ({
  type: FETCH_UNITS_SUCCESS,
  payload: { units },
});

export const fetchUnitsFailure = (message) => ({
  type: FETCH_UNITS_FAILURE,
  payload: { message },
});

export const fetchSuppliers = () => ({
  type: FETCH_SUPPLIERS,
  payload: { multiSort: ['name'] },
});

export const fetchSuppliersSuccess = (suppliers) => ({
  type: FETCH_SUPPLIERS_SUCCESS,
  payload: { suppliers },
});

export const fetchSuppliersFailure = (message) => ({
  type: FETCH_SUPPLIERS_FAILURE,
  payload: { message },
});

export const fetchTags = () => ({
  type: FETCH_TAGS,
  payload: { multiSort: ['name'] },
});

export const fetchTagsSuccess = (data) => ({
  type: FETCH_TAGS_SUCCESS,
  payload: { data },
});

export const fetchTagsError = (message) => ({
  type: FETCH_TAGS_ERROR,
  payload: { message },
});

export const fetchMaterialTypes = () => ({
  type: FETCH_MATERIAL_TYPES,
  payload: { multiSort: ['name'], page: 1, limit: null },
});

export const fetchMaterialTypesSuccess = (data) => ({
  type: FETCH_MATERIAL_TYPES_SUCCESS,
  payload: { data },
});

export const fetchMaterialTypesError = (message) => ({
  type: FETCH_MATERIAL_TYPES_ERROR,
  payload: { message },
});

export const countriesFetch = () => ({
  type: FETCH_COUNTRIES,
  payload: {},
});

export const locationsFetch = () => ({
  type: FETCH_LOCATIONS,
  payload: {},
});

export const locationsFetchSuccess = (data) => ({
  type: FETCH_LOCATIONS_SUCCESS,
  payload: { data },
});

export const locationsFetchError = (message) => ({
  type: FETCH_LOCATIONS_ERROR,
  payload: { message },
});

export const countriesFetchSuccess = (data) => ({
  type: FETCH_COUNTRIES_SUCCESS,
  payload: { data },
});

export const countriesFetchError = (message) => ({
  type: FETCH_COUNTRIES_ERROR,
  payload: { message },
});

export const purposesFetch = () => ({
  type: FETCH_PURPOSES,
  payload: { multiSort: ['code'] },
});

export const purposesFetchSuccess = (data) => ({
  type: FETCH_PURPOSES_SUCCESS,
  payload: { data },
});

export const purposesByDirectionFetch = (direction) => ({
  type: FETCH_PURPOSES_BY_DIRECTION,
  payload: { direction, multiSort: ['code'] },
});

export const purposesFetchByDirectionSuccess = (data) => ({
  type: FETCH_PURPOSES_BY_DIRECTION_SUCCESS,
  payload: { data },
});

export const purposesFetchError = (message) => ({
  type: FETCH_PURPOSES_ERROR,
  payload: { message },
});

export const fetchGlobalProducts = () => ({
  type: FETCH_GLOBAL_PRODUCTS,
  payload: { multiSort: ['name'], filter: { is_product: 'true' } },
});

export const fetchGlobalProductsSuccess = (data) => ({
  type: FETCH_GLOBAL_PRODUCTS_SUCCESS,
  payload: { data },
});

export const fetchGlobalProductsError = (message) => ({
  type: FETCH_GLOBAL_PRODUCTS_ERROR,
  payload: { message },
});

export const clearGlobals = () => ({
  type: CLEAR_GLOBALS,
  payload: {},
});

export const fetchGlobalProductSuppliers = (multiSort, filter) => ({
  type: FETCH_PRODUCT_SUPPLIERS,
  payload: { multiSort, filter },
});

export const fetchGlobalProductSuppliersSuccess = (data) => ({
  type: FETCH_PRODUCT_SUPPLIERS_SUCCESS,
  payload: { data },
});

export const fetchGlobalProductSuppliersFailure = (message) => ({
  type: FETCH_PRODUCT_SUPPLIERS_FAILURE,
  payload: { message },
});

export const fetchRoles = () => ({
  type: FETCH_GLOBAL_ROLES,
  payload: null,
});

export const fetchRolesSuccess = (data) => ({
  type: FETCH_GLOBAL_ROLES_SUCCESS,
  payload: { data },
});

export const fetchRolesError = (message) => ({
  type: FETCH_GLOBAL_ROLES_ERROR,
  payload: { message },
});

export const citiesFetch = () => ({
  type: FETCH_CITIES,
  payload: { multiSort: ['city'] },
});

export const citiesFetchSuccess = (data) => ({
  type: FETCH_CITIES_SUCCESS,
  payload: { data },
});

export const citiesFetchError = (message) => ({
  type: FETCH_CITIES_ERROR,
  payload: { message },
});

export const fetchPurposesDistinct = () => ({
  type: FETCH_PURPOSES_DISTINCT,
  payload: {},
});

export const fetchPurposesDistinctSuccess = (data) => ({
  type: FETCH_PURPOSES_DISTINCT_SUCCESS,
  payload: { data },
});

export const fetchPurposesDistinctError = (message) => ({
  type: FETCH_PURPOSES_DISTINCT_ERROR,
  payload: { message },
});

export const fetchPurposesComplete = () => ({
  type: FETCH_PURPOSES_COMPLETE,
  payload: {},
});

export const fetchPurposesCompleteSuccess = (data) => ({
  type: FETCH_PURPOSES_COMPLETE_SUCCESS,
  payload: { data },
});

export const fetchPurposesCompleteError = (message) => ({
  type: FETCH_PURPOSES_COMPLETE_ERROR,
  payload: { message },
});
