import {
  CHANGE_LOCALE,
  SETTINGS_DELETE_ORDER_STATUS,
  SETTINGS_DELETE_ORDER_STATUS_ERROR,
  SETTINGS_FETCH_ORDER_STATUS,
  SETTINGS_FETCH_ORDER_STATUS_SUCCESS,
  SETTINGS_SUBMIT_ORDER_STATUS,
  SETTINGS_SUBMIT_NEW_ORDER_STATUS,
  SETTINGS_SUBMIT_ORDER_STATUS_SUCCESS,
  SETTINGS_ORDER_STATUS_LIST,
  SETTINGS_ORDER_STATUS_LIST_SUCCESS,
  SETTINGS_ORDER_STATUS_LIST_ERROR,
  SETTINGS_FETCH_TAG_DETAIL,
  SETTINGS_DELETE_TAG,
  SETTINGS_SUBMIT_NEW_TAG_DETAIL,
  SETTINGS_SUBMIT_UPDATE_TAG_DETAIL,
  SETTINGS_TAG_DETAIL_SUCCESS,
  SETTINGS_TAG_DETAIL_ERROR,
  SETTINGS_FETCH_TAG_DETAIL_SUCCESS,
  SETTINGS_TAG_FETCH_TABLE,
  SETTINGS_TAG_FETCH_TABLE_SUCCESS,
  SETTINGS_TAG_FETCH_TABLE_ERROR,
  MATERIAL_TYPES_FETCH_DETAIL,
  MATERIAL_TYPES_FETCH_DETAIL_SUCCESS,
  MATERIAL_TYPES_ERROR,
  MATERIAL_TYPES_CREATE,
  MATERIAL_TYPES_UPDATE,
  MATERIAL_TYPES_DELETE,
  MATERIALS_FETCH_LIST,
  MATERIALS_FETCH_LIST_SUCCESS,
  MATERIALS_FETCH_LIST_ERROR,
  MATERIALS_FETCH_DETAIL,
  MATERIALS_FETCH_DETAIL_SUCCESS,
  MATERIALS_DETAIL_ERROR,
  MATERIALS_CREATE,
  MATERIALS_UPDATE,
  MATERIALS_DELETE,
  MATERIALS_SUCCESS,
  SUPPLIERS_FETCH_LIST,
  SUPPLIERS_FETCH_LIST_SUCCESS,
  SUPPLIERS_FETCH_LIST_ERROR,
  SUPPLIERS_FETCH_DETAIL,
  SUPPLIERS_FETCH_DETAIL_SUCCESS,
  SUPPLIERS_FETCH_DETAIL_ERROR,
  SUPPLIERS_CREATE,
  SUPPLIERS_UPDATE,
  SUPPLIERS_DELETE,
  SUPPLIERS_ACTION_SUCCESS,
  SUPPLIERS_ACTION_ERROR,
  MATERIAL_CODES_FETCH_LIST,
  MATERIAL_CODES_FETCH_LIST_SUCCESS,
  MATERIAL_CODES_FETCH_ERROR,
  MATERIAL_CODES_FETCH_DETAIL,
  MATERIAL_CODES_FETCH_DETAIL_SUCCESS,
  MATERIAL_CODES_CREATE,
  MATERIAL_CODES_UPDATE,
  MATERIAL_CODES_DELETE,
  MATERIAL_CODES_ACTION_SUCCESS,
  MATERIAL_CODES_ACTION_ERROR,
  WAREHOUSES_SETTINGS_FETCH_LIST,
  WAREHOUSES_SETTINGS_FETCH_LIST_SUCCESS,
  WAREHOUSES_SETTINGS_FETCH_ERROR,
  WAREHOUSES_SETTINGS_FETCH_DETAIL,
  WAREHOUSES_SETTINGS_FETCH_DETAIL_SUCCESS,
  WAREHOUSES_SETTINGS_CREATE,
  WAREHOUSES_SETTINGS_UPDATE,
  WAREHOUSES_SETTINGS_DELETE,
  WAREHOUSES_SETTINGS_ACTION_SUCCESS,
  WAREHOUSES_SETTINGS_ACTION_ERROR,
  MATERIALS_DETAIL_FETCH_CODES,
  MATERIALS_DETAIL_FETCH_CODES_SUCCESS,
  MATERIALS_DETAIL_FETCH_CODES_ERROR,
  MATERIAL_TYPES_FETCH_LIST,
  MATERIAL_TYPES_FETCH_LIST_SUCCESS,
  MATERIAL_TYPES_FETCH_LIST_ERROR,
  SETTINGS_PURPOSES_TABLE,
  SETTINGS_PURPOSES_TABLE_SUCCESS,
  SETTINGS_PURPOSES_ERROR,
  SETTINGS_PURPOSES_DETAIL,
  SETTINGS_PURPOSES_CREATE,
  SETTINGS_PURPOSES_UPDATE,
  SETTINGS_PURPOSES_DELETE,
  SETTINGS_PURPOSES_ACTION_SUCCESS,
  SETTINGS_PURPOSES_DETAIL_SUCCESS,
  SETTINGS_USERS_LIST_FETCH,
  SETTINGS_USERS_LIST_FETCH_SUCCESS,
  SETTINGS_USERS_FETCH_ERROR,
  SETTINGS_USERS_DETAIL_FETCH,
  SETTINGS_USERS_DETAIL_FETCH_SUCCESS,
  SETTINGS_USERS_CREATE,
  SETTINGS_USERS_UPDATE,
  SETTINGS_USERS_DELETE,
  SETTINGS_USERS_ACTION_SUCCESS,
  SETTINGS_USERS_ACTION_ERROR,
  MATERIAL_TYPES_ACTION_SUCCESS,
  MATERIAL_TYPES_UPDATE_FILTER,
  MATERIALS_CHANGE_FILTER,
  MATERIALS_CHANGE_SORT,
  SUPPLIERS_UPDATE_FILTER,
  SUPPLIERS_CHANGE_SORT,
  WAREHOUSES_SETTINGS_UPDATE_FILTER,
  SETTINGS_USERS_UPDATE_FILTER,
  SETTINGS_ORDER_STATUS_CHANGE_SORT,
  SETTINGS_TAG_CHANGE_SORT,
  MATERIAL_TYPES_CHANGE_SORT,
  WAREHOUSES_SETTINGS_CHANGE_SORT,
  SETTINGS_PURPOSES_CHANGE_SORT,
  SETTINGS_USERS_UPDATE_SORT,
  MATERIALS_DETAIL_CODES_CHANGE_SORT,
  MATERIALS_DETAIL_QUANTITIES_CHANGE_SORT,
} from '../action_codes';
import { setCurrentLanguage } from '../../helpers/Utils';

export const changeLocale = (locale) => {
  setCurrentLanguage(locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};

export const fetchOrderStatusDetails = (url) => ({
  type: SETTINGS_FETCH_ORDER_STATUS,
  payload: { url },
});

export const fetchOrderStatusSuccess = (data) => ({
  type: SETTINGS_FETCH_ORDER_STATUS_SUCCESS,
  payload: { data },
});

export const fetchOrderStatusFailure = (message) => ({
  type: SETTINGS_DELETE_ORDER_STATUS_ERROR,
  payload: { message },
});

export const submitOrderStatusDetail = (url, data, onFinish) => ({
  type: SETTINGS_SUBMIT_ORDER_STATUS,
  payload: { url, data, onFinish },
});

export const submitNewOrderStatus = (url, data, onFinish) => ({
  type: SETTINGS_SUBMIT_NEW_ORDER_STATUS,
  payload: { url, data, onFinish },
});

export const submitOrderStatusSuccess = () => ({
  type: SETTINGS_SUBMIT_ORDER_STATUS_SUCCESS,
  payload: {},
});

export const submitOrderStatusError = (message, formErrors) => ({
  type: SETTINGS_DELETE_ORDER_STATUS_ERROR,
  payload: { message, formErrors },
});

export const deleteOrderStatus = (url, onFinish) => ({
  type: SETTINGS_DELETE_ORDER_STATUS,
  payload: { url, onFinish },
});

export const deleteOrderStatusSuccess = () => ({
  type: SETTINGS_SUBMIT_ORDER_STATUS_SUCCESS,
  payload: {},
});

export const deleteOrderStatusError = (message) => ({
  type: SETTINGS_DELETE_ORDER_STATUS_ERROR,
  payload: { message },
});

export const fetchOrderStatusList = (multiSort, filter) => ({
  type: SETTINGS_ORDER_STATUS_LIST,
  payload: { multiSort, page: 1, limit: null, filter },
});

export const fetchOrderStatusListSuccess = (data, meta) => ({
  type: SETTINGS_ORDER_STATUS_LIST_SUCCESS,
  payload: { data, meta },
});

export const fetchOrderStatusListError = (message) => ({
  type: SETTINGS_ORDER_STATUS_LIST_ERROR,
  payload: { message },
});

export const changeOrderStatusSort = (multiSort) => ({
  type: SETTINGS_ORDER_STATUS_CHANGE_SORT,
  payload: { multiSort },
});

export const fetchTagDetail = (url) => ({
  type: SETTINGS_FETCH_TAG_DETAIL,
  payload: { url },
});

export const fetchTagDetailSuccess = (data) => ({
  type: SETTINGS_FETCH_TAG_DETAIL_SUCCESS,
  payload: { data },
});

export const deleteTagDetail = (url, onFinish) => ({
  type: SETTINGS_DELETE_TAG,
  payload: { url, onFinish },
});

export const submitNewTag = (url, data, onFinish) => ({
  type: SETTINGS_SUBMIT_NEW_TAG_DETAIL,
  payload: { url, data, onFinish },
});

export const submitTagDetail = (url, data, onFinish) => ({
  type: SETTINGS_SUBMIT_UPDATE_TAG_DETAIL,
  payload: { url, data, onFinish },
});

export const tagTable = (multiSort) => ({
  type: SETTINGS_TAG_FETCH_TABLE,
  payload: { multiSort, page: 1, limit: null },
});

export const tagTableSuccess = (data, meta) => ({
  type: SETTINGS_TAG_FETCH_TABLE_SUCCESS,
  payload: { data, meta },
});

export const tagTableError = (message) => ({
  type: SETTINGS_TAG_FETCH_TABLE_ERROR,
  payload: { message },
});

export const sortTags = (multiSort) => ({
  type: SETTINGS_TAG_CHANGE_SORT,
  payload: { multiSort },
});

export const tagDetailSuccess = () => ({
  type: SETTINGS_TAG_DETAIL_SUCCESS,
  payload: {},
});

export const tagDetailError = (message, formErrors) => ({
  type: SETTINGS_TAG_DETAIL_ERROR,
  payload: { message, formErrors },
});

export const materialTypesFetchList = (multiSort, page, limit, filter) => ({
  type: MATERIAL_TYPES_FETCH_LIST,
  payload: { multiSort, filter },
});

export const materialTypesFetchListSuccess = (data, meta) => ({
  type: MATERIAL_TYPES_FETCH_LIST_SUCCESS,
  payload: { data, meta },
});

export const materialTypesSortChange = (multiSort) => ({
  type: MATERIAL_TYPES_CHANGE_SORT,
  payload: { multiSort },
});

export const materialTypesFetchListError = (message) => ({
  type: MATERIAL_TYPES_FETCH_LIST_ERROR,
  payload: { message },
});

export const materialTypeFetchDetail = (url) => ({
  type: MATERIAL_TYPES_FETCH_DETAIL,
  payload: { url },
});

export const materialTypeFetchDetailSuccess = (data) => ({
  type: MATERIAL_TYPES_FETCH_DETAIL_SUCCESS,
  payload: { data },
});

export const materialTypeError = (message) => ({
  type: MATERIAL_TYPES_ERROR,
  payload: { message },
});

export const materialTypeCreate = (url, data, onFinish) => ({
  type: MATERIAL_TYPES_CREATE,
  payload: { url, data, onFinish },
});

export const materialTypeUpdate = (url, data, onFinish) => ({
  type: MATERIAL_TYPES_UPDATE,
  payload: { url, data, onFinish },
});

export const materialTypeDelete = (url, onFinish) => ({
  type: MATERIAL_TYPES_DELETE,
  payload: { url, onFinish },
});

export const materialTypeActionSuccess = () => ({
  type: MATERIAL_TYPES_ACTION_SUCCESS,
  payload: {},
});

export const materialTypeUpdateFilter = (filter) => ({
  type: MATERIAL_TYPES_UPDATE_FILTER,
  payload: { filter },
});

export const materialListFetch = (multiSort, page, limit, filter) => ({
  type: MATERIALS_FETCH_LIST,
  payload: { multiSort, page, limit, filter },
});

export const materialListFetchSuccess = (data, meta) => ({
  type: MATERIALS_FETCH_LIST_SUCCESS,
  payload: { data, meta },
});

export const materialListFetchError = (message) => ({
  type: MATERIALS_FETCH_LIST_ERROR,
  payload: { message },
});

export const materialListFetchDetail = (url) => ({
  type: MATERIALS_FETCH_DETAIL,
  payload: { url },
});

export const materialListFetchDetailSuccess = (data) => ({
  type: MATERIALS_FETCH_DETAIL_SUCCESS,
  payload: { data },
});

export const materialListDetailError = (message, formErrors) => ({
  type: MATERIALS_DETAIL_ERROR,
  payload: { message, formErrors },
});

export const materialListCreate = (url, data, onFinish) => ({
  type: MATERIALS_CREATE,
  payload: { url, data, onFinish },
});

export const materialListUpdate = (url, data, onFinish) => ({
  type: MATERIALS_UPDATE,
  payload: { url, data, onFinish },
});

export const materialListDelete = (url, onFinish) => ({
  type: MATERIALS_DELETE,
  payload: { url, onFinish },
});

export const materialListSuccess = () => ({
  type: MATERIALS_SUCCESS,
  payload: {},
});

export const materialChangeFilter = (filter) => ({
  type: MATERIALS_CHANGE_FILTER,
  payload: { filter },
});

export const materialChangeSort = (multiSort) => ({
  type: MATERIALS_CHANGE_SORT,
  payload: { multiSort },
});

export const suppliersFetchList = (multiSort, page, limit, filter) => ({
  type: SUPPLIERS_FETCH_LIST,
  payload: { multiSort, page, limit, filter },
});

export const suppliersFetchListSuccess = (data, meta) => ({
  type: SUPPLIERS_FETCH_LIST_SUCCESS,
  payload: { data, meta },
});

export const suppliersFetchListError = (message) => ({
  type: SUPPLIERS_FETCH_LIST_ERROR,
  payload: { message },
});

export const suppliersFetchDetail = (url) => ({
  type: SUPPLIERS_FETCH_DETAIL,
  payload: { url },
});

export const suppliersFetchDetailSuccess = (data) => ({
  type: SUPPLIERS_FETCH_DETAIL_SUCCESS,
  payload: { data },
});

export const suppliersFetchDetailError = (message) => ({
  type: SUPPLIERS_FETCH_DETAIL_ERROR,
  payload: { message },
});

export const suppliersCreate = (url, data, onFinish) => ({
  type: SUPPLIERS_CREATE,
  payload: { url, data, onFinish },
});

export const suppliersUpdate = (url, data, onFinish) => ({
  type: SUPPLIERS_UPDATE,
  payload: { url, data, onFinish },
});

export const suppliersDelete = (url, onFinish) => ({
  type: SUPPLIERS_DELETE,
  payload: { url, onFinish },
});

export const suppliersActionSuccess = () => ({
  type: SUPPLIERS_ACTION_SUCCESS,
  payload: {},
});

export const suppliersActionError = (message, formErrors) => ({
  type: SUPPLIERS_ACTION_ERROR,
  payload: { message, formErrors },
});

export const suppliersUpdateFilter = (filter) => ({
  type: SUPPLIERS_UPDATE_FILTER,
  payload: { filter },
});

export const suppliersChangeSort = (multiSort) => ({
  type: SUPPLIERS_CHANGE_SORT,
  payload: { multiSort },
});

export const materialCodesFetchList = (multiSort, page, limit) => ({
  type: MATERIAL_CODES_FETCH_LIST,
  payload: { multiSort, page, limit },
});

export const materialCodesFetchListSuccess = (data, meta) => ({
  type: MATERIAL_CODES_FETCH_LIST_SUCCESS,
  payload: { data, meta },
});

export const materialCodesFetchError = (message) => ({
  type: MATERIAL_CODES_FETCH_ERROR,
  payload: { message },
});

export const materialCodesFetchDetail = (url) => ({
  type: MATERIAL_CODES_FETCH_DETAIL,
  payload: { url },
});

export const materialCodesFetchDetailSuccess = (data) => ({
  type: MATERIAL_CODES_FETCH_DETAIL_SUCCESS,
  payload: { data },
});

export const materialCodesCreate = (url, data, onFinish) => ({
  type: MATERIAL_CODES_CREATE,
  payload: { url, data, onFinish },
});

export const materialCodesUpdate = (url, data, onFinish) => ({
  type: MATERIAL_CODES_UPDATE,
  payload: { url, data, onFinish },
});

export const materialCodesDelete = (url, onFinish) => ({
  type: MATERIAL_CODES_DELETE,
  payload: { url, onFinish },
});

export const materialCodesActionSuccess = () => ({
  type: MATERIAL_CODES_ACTION_SUCCESS,
  payload: {},
});

export const materialCodesActionError = (message) => ({
  type: MATERIAL_CODES_ACTION_ERROR,
  payload: { message },
});

export const warehousesSettingsFetchList = (
  multiSort,
  page,
  limit,
  filter
) => ({
  type: WAREHOUSES_SETTINGS_FETCH_LIST,
  payload: { multiSort, page, limit, filter },
});

export const warehousesSettingsFetchListSuccess = (data, meta) => ({
  type: WAREHOUSES_SETTINGS_FETCH_LIST_SUCCESS,
  payload: { data, meta },
});

export const warehousesSettingsFetchError = (message) => ({
  type: WAREHOUSES_SETTINGS_FETCH_ERROR,
  payload: { message },
});

export const warehousesSettingsFetchDetail = (url) => ({
  type: WAREHOUSES_SETTINGS_FETCH_DETAIL,
  payload: { url },
});

export const warehousesSettingsFetchDetailSuccess = (data) => ({
  type: WAREHOUSES_SETTINGS_FETCH_DETAIL_SUCCESS,
  payload: { data },
});

export const warehousesSettingsCreate = (url, data, onFinish) => ({
  type: WAREHOUSES_SETTINGS_CREATE,
  payload: { url, data, onFinish },
});

export const warehousesSettingsUpdate = (url, data, onFinish) => ({
  type: WAREHOUSES_SETTINGS_UPDATE,
  payload: { url, data, onFinish },
});

export const warehousesSettingsDelete = (url, onFinish) => ({
  type: WAREHOUSES_SETTINGS_DELETE,
  payload: { url, onFinish },
});

export const warehousesSettingsActionSuccess = () => ({
  type: WAREHOUSES_SETTINGS_ACTION_SUCCESS,
  payload: {},
});

export const warehousesSettingsActionError = (message) => ({
  type: WAREHOUSES_SETTINGS_ACTION_ERROR,
  payload: { message },
});

export const warehousesSettingsUpdateFilter = (filter) => ({
  type: WAREHOUSES_SETTINGS_UPDATE_FILTER,
  payload: { filter },
});

export const warehousesSettingsChangeSort = (multiSort) => ({
  type: WAREHOUSES_SETTINGS_CHANGE_SORT,
  payload: { multiSort },
});

export const materialDetailFetchCodes = (multiSort, page, limit, filter) => ({
  type: MATERIALS_DETAIL_FETCH_CODES,
  payload: { multiSort, page, limit, filter },
});

export const materialDetailFetchCodesSuccess = (data, meta) => ({
  type: MATERIALS_DETAIL_FETCH_CODES_SUCCESS,
  payload: { data, meta },
});

export const materialDetailFetchCodesError = (message) => ({
  type: MATERIALS_DETAIL_FETCH_CODES_ERROR,
  payload: { message },
});

export const materialDetailCodesChangeSort = (multiSort) => ({
  type: MATERIALS_DETAIL_CODES_CHANGE_SORT,
  payload: { multiSort },
});

export const materialDetailQuantitiesChangeSort = (multiSort) => ({
  type: MATERIALS_DETAIL_QUANTITIES_CHANGE_SORT,
  payload: { multiSort },
});

export const purposeSettingsTableFetch = (multiSort, page, limit) => ({
  type: SETTINGS_PURPOSES_TABLE,
  payload: { multiSort, page, limit, filter: { alive_only: 'True' } },
});

export const purposeSettingsTableFetchSuccess = (data, meta) => ({
  type: SETTINGS_PURPOSES_TABLE_SUCCESS,
  payload: { data, meta },
});

export const purposesSettingsChangeSort = (multiSort) => ({
  type: SETTINGS_PURPOSES_CHANGE_SORT,
  payload: { multiSort },
});

export const purposeSettingsFetchError = (message) => ({
  type: SETTINGS_PURPOSES_ERROR,
  payload: { message },
});

export const purposeSettingsFetchDetail = (url) => ({
  type: SETTINGS_PURPOSES_DETAIL,
  payload: { url },
});

export const purposeSettingsDetailSuccess = (data) => ({
  type: SETTINGS_PURPOSES_DETAIL_SUCCESS,
  payload: { data },
});

export const purposeSettingsCreate = (url, data, onFinish) => ({
  type: SETTINGS_PURPOSES_CREATE,
  payload: { url, data, onFinish },
});

export const purposeSettingsUpdate = (url, data, onFinish) => ({
  type: SETTINGS_PURPOSES_UPDATE,
  payload: { url, data, onFinish },
});

export const purposeSettingsDelete = (url, onFinish) => ({
  type: SETTINGS_PURPOSES_DELETE,
  payload: { url, onFinish },
});

export const purposeSettingsActionSuccess = () => ({
  type: SETTINGS_PURPOSES_ACTION_SUCCESS,
  payload: {},
});

export const usersFetchTable = (multiSort, page, limit, filter) => ({
  type: SETTINGS_USERS_LIST_FETCH,
  payload: { multiSort, page, limit, filter },
});

export const usersFetchTableSuccess = (data, meta) => ({
  type: SETTINGS_USERS_LIST_FETCH_SUCCESS,
  payload: { data, meta },
});

export const usersFetchError = (message) => ({
  type: SETTINGS_USERS_FETCH_ERROR,
  payload: { message },
});

export const usersFetchDetail = (url) => ({
  type: SETTINGS_USERS_DETAIL_FETCH,
  payload: { url },
});

export const usersFetchDetailSuccess = (data) => ({
  type: SETTINGS_USERS_DETAIL_FETCH_SUCCESS,
  payload: { data },
});

export const usersCreateAction = (url, data, onFinish) => ({
  type: SETTINGS_USERS_CREATE,
  payload: { url, data, onFinish },
});

export const usersUpdateAction = (url, data, onFinish) => ({
  type: SETTINGS_USERS_UPDATE,
  payload: { url, data, onFinish },
});

export const usersDeleteAction = (url, onFinish) => ({
  type: SETTINGS_USERS_DELETE,
  payload: { url, onFinish },
});

export const usersActionSuccess = () => ({
  type: SETTINGS_USERS_ACTION_SUCCESS,
  payload: {},
});

export const usersActionError = (message) => ({
  type: SETTINGS_USERS_ACTION_ERROR,
  payload: { message },
});

export const usersUpdateFilter = (filter) => ({
  type: SETTINGS_USERS_UPDATE_FILTER,
  payload: { filter },
});

export const usersUpdateSort = (multiSort) => ({
  type: SETTINGS_USERS_UPDATE_SORT,
  payload: { multiSort },
});
