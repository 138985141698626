// eslint-disable-next-line import/no-cycle
import {
  WAREHOUSES_SUBMIT_MOVEMENT,
  WAREHOUSES_SUBMIT_SUCCESS,
  WAREHOUSES_SUBMIT_ERROR,
  WAREHOUSES_FETCH_MOVEMENT_LIST,
  WAREHOUSES_FETCH_MOVEMENT_LIST_ERROR,
  WAREHOUSES_FETCH_MOVEMENT_LIST_SUCCESS,
  WAREHOUSES_FETCH_INVENTORY,
  WAREHOUSES_UPDATE_INVENTORY_FILTER,
  WAREHOUSES_FETCH_INVENTORY_SUCCESS,
  WAREHOUSES_FETCH_INVENTORY_ERROR,
  WAREHOUSES_LOOKUP_DETAILS,
  WAREHOUSES_LOOKUP_DETAILS_SUCCESS,
  WAREHOUSES_LOOKUP_DETAILS_ERROR,
  WAREHOUSES_INVOICES_FETCH_LIST,
  WAREHOUSES_INVOICES_FETCH_LIST_SUCCESS,
  WAREHOUSES_INVOICES_FETCH_ERROR,
  WAREHOUSES_INVOICES_FETCH_DETAIL,
  WAREHOUSES_INVOICES_FETCH_DETAIL_SUCCESS,
  WAREHOUSES_INVOICES_CREATE,
  WAREHOUSES_INVOICES_UPDATE,
  WAREHOUSES_INVOICES_DELETE,
  WAREHOUSES_INVOICES_ACTION_SUCCESS,
  WAREHOUSES_INVOICES_ACTION_ERROR,
  WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS,
  WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS_SUCCESS,
  WAREHOUSES_INVOICES_UPDATE_ASSIGNMENTS,
  WAREHOUSES_INVOICES_UPDATE_FILTER,
  WAREHOUSES_MOVEMENT_FETCH_DETAIL,
  WAREHOUSES_MOVEMENT_SUBMIT_DETAIL,
  WAREHOUSES_MOVEMENT_SUBMIT_SUCCESS,
  WAREHOUSES_MOVEMENT_ERROR,
  WAREHOUSES_MOVEMENT_FETCH_DETAIL_SUCCESS,
  WAREHOUSES_SUBMIT_CHANGE_MOVEMENT,
  WAREHOUSES_SUBMIT_REVERT_MOVEMENT,
  WAREHOUSES_MOVEMENTS_UPDATE_FILTER,
  WAREHOUSES_INVOICES_CLEAR,
  WAREHOUSES_INVOICES_TOLL_FETCH_LIST,
  WAREHOUSES_INVOICES_TOLL_FETCH_LIST_SUCCESS,
  WAREHOUSES_INVOICES_TOLL_FETCH_ERROR,
  WAREHOUSES_INVOICES_TOLL_UPDATE_FILTER,
  WAREHOUSES_INVOICES_TOLL_CREATE,
  WAREHOUSES_INVOICES_TOLL_UPDATE,
  WAREHOUSES_INVOICES_TOLL_DELETE,
  WAREHOUSES_INVOICES_TOLL_ACTION_SUCCESS,
  WAREHOUSES_INVOICES_TOLL_ACTION_ERROR,
  WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL,
  WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL_SUCCESS,
  WAREHOUSES_INVOICES_TOLL_CLEAR,
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST,
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST_SUCCESS,
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_ERROR,
  WAREHOUSES_INVOICES_TRANSPORT_UPDATE_FILTER,
  WAREHOUSES_INVOICES_TRANSPORT_CREATE,
  WAREHOUSES_INVOICES_TRANSPORT_UPDATE,
  WAREHOUSES_INVOICES_TRANSPORT_DELETE,
  WAREHOUSES_INVOICES_TRANSPORT_ACTION_SUCCESS,
  WAREHOUSES_INVOICES_TRANSPORT_ACTION_ERROR,
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL,
  WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL_SUCCESS,
  WAREHOUSES_INVOICES_TRANSPORT_CLEAR,
  WAREHOUSES_INVOICES_TOLL_UPDATE_ASSIGNMENTS,
  WAREHOUSES_INVOICES_TRANSPORT_UPDATE_ASSIGNMENTS,
  WAREHOUSES_FETCH_AVAIL_PURPOSES,
  WAREHOUSES_FETCH_AVAIL_PURPOSES_SUCCESS,
  WAREHOUSES_FETCH_AVAIL_PURPOSES_FAILURE,
  WAREHOUSES_SUBMIT_TRANSFER,
  WAREHOUSES_FETCH_TRANSFERS_LIST,
  WAREHOUSES_FETCH_TRANSFERS_LIST_SUCCESS,
  WAREHOUSES_FETCH_TRANSFERS_LIST_ERROR,
  WAREHOUSES_TRANSFER_FETCH_DETAIL,
  WAREHOUSES_TRANSFER_FETCH_DETAIL_SUCCESS,
  WAREHOUSES_TRANSFER_SUBMIT_DETAIL,
  WAREHOUSES_TRANSFER_SUBMIT_SUCCESS,
  WAREHOUSES_TRANSFER_ERROR,
  WAREHOUSES_TRANSFERS_UPDATE_FILTER,
  WAREHOUSES_MOVEMENT_LIST_SORT,
  WAREHOUSES_ACCOUNTING_LIST_SORT,
  WAREHOUSES_INVOICES_UPDATE_SORT,
  WAREHOUSES_INVOICES_TOLL_CHANGE_SORT,
  WAREHOUSES_INVOICES_TRANSPORT_CHANGE_SORT,
  WAREHOUSES_TRANSFER_LIST_CHANGE_SORT,
} from '../action_codes';

export const warehouseMovementSubmit = (url, data, onFinish) => ({
  type: WAREHOUSES_SUBMIT_MOVEMENT,
  payload: { url, data, onFinish },
});

export const warehouseMovementChangeSubmit = (url, data, onFinish) => ({
  type: WAREHOUSES_SUBMIT_CHANGE_MOVEMENT,
  payload: { url, data, onFinish },
});

export const warehouseMovementRevert = (url, onFinish) => ({
  type: WAREHOUSES_SUBMIT_REVERT_MOVEMENT,
  payload: { url, onFinish },
});

export const warehouseMovementSubmitSuccess = (result) => ({
  type: WAREHOUSES_SUBMIT_SUCCESS,
  payload: { result },
});

export const warehouseMovementSubmitError = (message) => ({
  type: WAREHOUSES_SUBMIT_ERROR,
  payload: { message },
});

export const warehousesFetchMovementsList = (
  multiSort,
  page,
  limit,
  filter
) => ({
  type: WAREHOUSES_FETCH_MOVEMENT_LIST,
  payload: { multiSort, page, limit, filter },
});

export const warehousesMovementsChangeSort = (multiSort) => ({
  type: WAREHOUSES_MOVEMENT_LIST_SORT,
  payload: { multiSort },
});

export const warehousesAccountingChangeSort = (multiSort) => ({
  type: WAREHOUSES_ACCOUNTING_LIST_SORT,
  payload: { multiSort },
});

export const warehousesTransfersUpdateFilter = (filter) => ({
  type: WAREHOUSES_TRANSFERS_UPDATE_FILTER,
  payload: { filter },
});

export const warehousesFetchTransfersList = (
  multiSort,
  page,
  limit,
  filter
) => ({
  type: WAREHOUSES_FETCH_TRANSFERS_LIST,
  payload: { multiSort, page, limit, filter },
});

export const warehousesFetchTransfersListSuccess = (data, meta) => ({
  type: WAREHOUSES_FETCH_TRANSFERS_LIST_SUCCESS,
  payload: { data, meta },
});

export const warehousesFetchTransfersListError = (message) => ({
  type: WAREHOUSES_FETCH_TRANSFERS_LIST_ERROR,
  payload: { message },
});

export const warehousesAccountingFetchMovementsList = (
  multiSort,
  page,
  limit,
  filter
) => ({
  type: WAREHOUSES_FETCH_MOVEMENT_LIST,
  payload: { multiSort, page, limit, filter, isAccounting: true },
});

export const warehousesMovementsUpdateFilter = (filter) => ({
  type: WAREHOUSES_MOVEMENTS_UPDATE_FILTER,
  payload: { filter },
});

export const warehousesFetchMovementsListSuccess = (data, meta) => ({
  type: WAREHOUSES_FETCH_MOVEMENT_LIST_SUCCESS,
  payload: { data, meta },
});

export const warehousesFetchMovementsListError = (message) => ({
  type: WAREHOUSES_FETCH_MOVEMENT_LIST_ERROR,
  payload: { message },
});

export const warehousesFetchInventoryList = (
  multiSort,
  page,
  limit,
  filter
) => ({
  type: WAREHOUSES_FETCH_INVENTORY,
  payload: { multiSort, page, limit, filter },
});

export const warehousesUpdateInventoryFilter = (filter) => ({
  type: WAREHOUSES_UPDATE_INVENTORY_FILTER,
  payload: { filter },
});

export const warehousesFetchInventoryListSuccess = (data, meta) => ({
  type: WAREHOUSES_FETCH_INVENTORY_SUCCESS,
  payload: { data, meta },
});

export const warehousesFetchInventoryListError = (message) => ({
  type: WAREHOUSES_FETCH_INVENTORY_ERROR,
  payload: { message },
});

export const warehousesLookupDetails = (url) => ({
  type: WAREHOUSES_LOOKUP_DETAILS,
  payload: { url },
});

export const warehousesLookupDetailsSuccess = (data) => ({
  type: WAREHOUSES_LOOKUP_DETAILS_SUCCESS,
  payload: { data },
});

export const warehousesLookupDetailsError = (message) => ({
  type: WAREHOUSES_LOOKUP_DETAILS_ERROR,
  payload: { message },
});

export const warehousesInvoicesClear = () => ({
  type: WAREHOUSES_INVOICES_CLEAR,
  payload: null,
});

export const warehousesInvoicesFetchList = (
  multiSort,
  page,
  limit,
  filter
) => ({
  type: WAREHOUSES_INVOICES_FETCH_LIST,
  payload: { multiSort, page, limit, filter },
});

export const warehousesInvoicesFetchListSuccess = (data, meta) => ({
  type: WAREHOUSES_INVOICES_FETCH_LIST_SUCCESS,
  payload: { data, meta },
});

export const warehousesInvoicesFetchError = (message) => ({
  type: WAREHOUSES_INVOICES_FETCH_ERROR,
  payload: { message },
});

export const warehousesInvoicesUpdateSort = (multiSort) => ({
  type: WAREHOUSES_INVOICES_UPDATE_SORT,
  payload: { multiSort },
});

export const warehousesInvoicesTollFetchList = (
  multiSort,
  page,
  limit,
  filter
) => ({
  type: WAREHOUSES_INVOICES_TOLL_FETCH_LIST,
  payload: { multiSort, page, limit, filter },
});

export const warehousesInvoicesTollFetchListSuccess = (data, meta) => ({
  type: WAREHOUSES_INVOICES_TOLL_FETCH_LIST_SUCCESS,
  payload: { data, meta },
});

export const warehousesInvoicesTollFetchError = (message) => ({
  type: WAREHOUSES_INVOICES_TOLL_FETCH_ERROR,
  payload: { message },
});

export const warehousesInvoicesTollChangeSort = (multiSort) => ({
  type: WAREHOUSES_INVOICES_TOLL_CHANGE_SORT,
  payload: { multiSort },
});

export const warehousesInvoicesTollFetchDetail = (url) => ({
  type: WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL,
  payload: { url },
});

export const warehousesInvoicesTollFetchDetailSuccess = (data) => ({
  type: WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL_SUCCESS,
  payload: { data },
});
export const warehousesInvoicesTollClear = () => ({
  type: WAREHOUSES_INVOICES_TOLL_CLEAR,
  payload: null,
});

export const warehousesInvoicesTransportFetchList = (
  multiSort,
  page,
  limit,
  filter
) => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST,
  payload: { multiSort, page, limit, filter },
});

export const warehousesInvoicesTransportFetchListSuccess = (data, meta) => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST_SUCCESS,
  payload: { data, meta },
});

export const warehousesInvoicesTransportFetchError = (message) => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_FETCH_ERROR,
  payload: { message },
});

export const warehousesInvoicesTransportChangeSort = (multiSort) => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_CHANGE_SORT,
  payload: { multiSort },
});

export const warehousesInvoicesTransportFetchDetail = (url) => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL,
  payload: { url },
});

export const warehousesInvoicesTransportFetchDetailSuccess = (data) => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL_SUCCESS,
  payload: { data },
});
export const warehousesInvoicesTransportClear = () => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_CLEAR,
  payload: null,
});

export const warehousesInvoicesFetchDetail = (url) => ({
  type: WAREHOUSES_INVOICES_FETCH_DETAIL,
  payload: { url },
});

export const warehousesInvoicesFetchDetailSuccess = (data) => ({
  type: WAREHOUSES_INVOICES_FETCH_DETAIL_SUCCESS,
  payload: { data },
});

export const warehousesInvoicesCreate = (url, data, onFinish) => ({
  type: WAREHOUSES_INVOICES_CREATE,
  payload: { url, data, onFinish },
});

export const warehousesInvoicesUpdate = (url, data, onFinish) => ({
  type: WAREHOUSES_INVOICES_UPDATE,
  payload: { url, data, onFinish },
});

export const warehousesInvoicesDelete = (url, onFinish) => ({
  type: WAREHOUSES_INVOICES_DELETE,
  payload: { url, onFinish },
});

export const warehousesInvoicesActionSuccess = () => ({
  type: WAREHOUSES_INVOICES_ACTION_SUCCESS,
  payload: {},
});

export const warehousesInvoicesActionError = (message, formErrors) => ({
  type: WAREHOUSES_INVOICES_ACTION_ERROR,
  payload: { message, formErrors },
});

export const warehousesInvoicesUpdateFilter = (filter) => ({
  type: WAREHOUSES_INVOICES_UPDATE_FILTER,
  payload: { filter },
});

export const warehousesInvoicesTollUpdateFilter = (filter) => ({
  type: WAREHOUSES_INVOICES_TOLL_UPDATE_FILTER,
  payload: { filter },
});

export const warehousesInvoicesTollCreate = (url, data, onFinish) => ({
  type: WAREHOUSES_INVOICES_TOLL_CREATE,
  payload: { url, data, onFinish },
});

export const warehousesInvoicesTollUpdate = (url, data, onFinish) => ({
  type: WAREHOUSES_INVOICES_TOLL_UPDATE,
  payload: { url, data, onFinish },
});
export const warehousesInvoicesTollDelete = (url, onFinish) => ({
  type: WAREHOUSES_INVOICES_TOLL_DELETE,
  payload: { url, onFinish },
});

export const warehousesInvoicesTollActionSuccess = () => ({
  type: WAREHOUSES_INVOICES_TOLL_ACTION_SUCCESS,
  payload: {},
});

export const warehousesInvoicesTollActionError = (message, formErrors) => ({
  type: WAREHOUSES_INVOICES_TOLL_ACTION_ERROR,
  payload: { message, formErrors },
});

export const warehousesInvoicesTransportUpdateFilter = (filter) => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_UPDATE_FILTER,
  payload: { filter },
});

export const warehousesInvoicesTransportCreate = (url, data, onFinish) => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_CREATE,
  payload: { url, data, onFinish },
});

export const warehousesInvoicesTransportUpdate = (url, data, onFinish) => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_UPDATE,
  payload: { url, data, onFinish },
});
export const warehousesInvoicesTransportDelete = (url, onFinish) => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_DELETE,
  payload: { url, onFinish },
});

export const warehousesInvoicesTransportActionSuccess = () => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_ACTION_SUCCESS,
  payload: {},
});

export const warehousesInvoicesTransportActionError = (
  message,
  formErrors
) => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_ACTION_ERROR,
  payload: { message, formErrors },
});

export const warehousesInvoicesFetchDeliveryLists = (filter) => ({
  type: WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS,
  payload: { filter },
});

export const warehousesInvoicesFetchDeliveryListsSuccess = (data) => ({
  type: WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS_SUCCESS,
  payload: { data },
});

export const warehousesInvoicesUpdateAssignments = (url, data, onFinish) => ({
  type: WAREHOUSES_INVOICES_UPDATE_ASSIGNMENTS,
  payload: { url, data, onFinish },
});

export const warehousesInvoicesTollUpdateAssignments = (
  url,
  data,
  onFinish
) => ({
  type: WAREHOUSES_INVOICES_TOLL_UPDATE_ASSIGNMENTS,
  payload: { url, data, onFinish },
});

export const warehousesInvoicesTransportUpdateAssignments = (
  url,
  data,
  onFinish
) => ({
  type: WAREHOUSES_INVOICES_TRANSPORT_UPDATE_ASSIGNMENTS,
  payload: { url, data, onFinish },
});

export const warehousesMovementDetailFetch = (url) => ({
  type: WAREHOUSES_MOVEMENT_FETCH_DETAIL,
  payload: { url },
});

export const warehousesMovementDetailFetchSuccess = (data) => ({
  type: WAREHOUSES_MOVEMENT_FETCH_DETAIL_SUCCESS,
  payload: { data },
});

export const warehousesMovementDetailSubmit = (url, data, onFinish) => ({
  type: WAREHOUSES_MOVEMENT_SUBMIT_DETAIL,
  payload: { url, data, onFinish },
});

export const warehousesMovementDetailSubmitSuccess = () => ({
  type: WAREHOUSES_MOVEMENT_SUBMIT_SUCCESS,
  payload: {},
});

export const warehousesMovementDetailError = (message, formErrors) => ({
  type: WAREHOUSES_MOVEMENT_ERROR,
  payload: { message, formErrors },
});

export const warehousesFetchAvailPurposes = () => ({
  type: WAREHOUSES_FETCH_AVAIL_PURPOSES,
  payload: {},
});

export const warehousesFetchAvailPurposesSuccess = (data) => ({
  type: WAREHOUSES_FETCH_AVAIL_PURPOSES_SUCCESS,
  payload: { data },
});

export const warehousesFetchAvailPurposesFailure = (message) => ({
  type: WAREHOUSES_FETCH_AVAIL_PURPOSES_FAILURE,
  payload: { message },
});

export const warehouseTransferSubmit = (url, data, onFinish) => ({
  type: WAREHOUSES_SUBMIT_TRANSFER,
  payload: { url, data, onFinish },
});
export const warehousesTransferDetailFetch = (url) => ({
  type: WAREHOUSES_TRANSFER_FETCH_DETAIL,
  payload: { url },
});

export const warehousesTransferDetailFetchSuccess = (data) => ({
  type: WAREHOUSES_TRANSFER_FETCH_DETAIL_SUCCESS,
  payload: { data },
});

export const warehousesTransferDetailSubmit = (url, data, onFinish) => ({
  type: WAREHOUSES_TRANSFER_SUBMIT_DETAIL,
  payload: { url, data, onFinish },
});

export const warehousesTransferDetailSubmitSuccess = () => ({
  type: WAREHOUSES_TRANSFER_SUBMIT_SUCCESS,
  payload: {},
});

export const warehousesTransferDetailError = (message, formErrors) => ({
  type: WAREHOUSES_TRANSFER_ERROR,
  payload: { message, formErrors },
});

export const warehousesTransfersChangeSort = (multiSort) => ({
  type: WAREHOUSES_TRANSFER_LIST_CHANGE_SORT,
  payload: { multiSort },
});
