import { all, fork } from 'redux-saga/effects';
import { fetchDetailActionTriad, fetchTableActionTriad } from '../helpers';
import {
  MOVEMENT_LOOKUP_ALL_MATERIALS,
  MOVEMENT_LOOKUP_BATCHES,
  MOVEMENT_LOOKUP_CODE,
  MOVEMENT_LOOKUP_DETAIL,
  MOVEMENT_LOOKUP_MATERIAL,
  MOVEMENT_LOOKUP_SUPPLIERS,
  MOVEMENT_LOOKUP_WAREHOUSES,
} from '../action_codes';
import {
  movementLookupAllMaterialsSuccess,
  movementLookupError,
  movementLookupMaterialSuccess,
  movementLookupSuppliersSuccess,
  movementLookupWarehousesSuccess,
  movementLookupCodeSuccess,
  movementLookupCodeError,
  movementLookupBatchesSuccess,
  movementLookupDetailSuccess,
  movementLookupDetailError,
} from './actions';

const fetchLookupMaterialsWatcher = fetchTableActionTriad(
  MOVEMENT_LOOKUP_ALL_MATERIALS,
  'warehouse-quantities/materials/',
  movementLookupAllMaterialsSuccess,
  movementLookupError
).watcherFunction;

const fetchLookupMaterialDetailWatcher = fetchDetailActionTriad(
  MOVEMENT_LOOKUP_MATERIAL,
  movementLookupMaterialSuccess,
  movementLookupError
).watcherFunction;

const fetchLookupMaterialWarehousesWatcher = fetchTableActionTriad(
  MOVEMENT_LOOKUP_WAREHOUSES,
  'warehouse-quantities/warehouses/',
  movementLookupWarehousesSuccess,
  movementLookupError
).watcherFunction;

const fetchLookupMaterialSupplierWatcher = fetchTableActionTriad(
  MOVEMENT_LOOKUP_SUPPLIERS,
  (payload) =>
    `warehouse-quantities/suppliers/${payload.materialId}/${payload.warehouseId}/`,
  movementLookupSuppliersSuccess,
  movementLookupError
).watcherFunction;

const fetchLookupMaterialBatchesWatcher = fetchTableActionTriad(
  MOVEMENT_LOOKUP_BATCHES,
  (payload) =>
    `warehouse-quantities/batches/${payload.materialId}/${payload.warehouseId}/`,
  movementLookupBatchesSuccess,
  movementLookupError
).watcherFunction;

const watchLookupMaterialCode = fetchTableActionTriad(
  MOVEMENT_LOOKUP_CODE,
  (payload) => `material-codes/lookup/${payload.code}/`,
  movementLookupCodeSuccess,
  movementLookupCodeError
).watcherFunction;

const watchLookupDetail = fetchDetailActionTriad(
  MOVEMENT_LOOKUP_DETAIL,
  movementLookupDetailSuccess,
  movementLookupDetailError
).watcherFunction;

export default function* rootSaga() {
  yield all([
    fork(fetchLookupMaterialsWatcher),
    fork(fetchLookupMaterialDetailWatcher),
    fork(fetchLookupMaterialWarehousesWatcher),
    fork(fetchLookupMaterialSupplierWatcher),
    fork(fetchLookupMaterialBatchesWatcher),
    fork(watchLookupMaterialCode),
    fork(watchLookupDetail),
  ]);
}
