import {
  WAREHOUSE_QUANTITIES_ACTION_ERROR,
  WAREHOUSE_QUANTITIES_ACTION_SUCCESS,
  WAREHOUSE_QUANTITIES_CREATE,
  WAREHOUSE_QUANTITIES_DELETE,
  WAREHOUSE_QUANTITIES_FETCH_DETAIL,
  WAREHOUSE_QUANTITIES_FETCH_DETAIL_SUCCESS,
  WAREHOUSE_QUANTITIES_FETCH_ERROR,
  WAREHOUSE_QUANTITIES_FETCH_LIST,
  WAREHOUSE_QUANTITIES_FETCH_LIST_SUCCESS,
  WAREHOUSE_QUANTITIES_UPDATE,
} from '../action_codes';
import { getDefaultPageSize } from '../../helpers/Utils';

const INIT_STATE = {
  warehouseQuantitiesList: null,
  warehouseQuantitiesMeta: {
    multiSort: [],
    page: 0,
    limit: getDefaultPageSize(),
  },
  warehouseQuantitiesLoading: false,
  warehouseQuantitiesError: '',
  warehouseQuantitiesDetail: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WAREHOUSE_QUANTITIES_FETCH_LIST:
      return { ...state, warehouseQuantitiesLoading: true };
    case WAREHOUSE_QUANTITIES_FETCH_LIST_SUCCESS:
      return {
        ...state,
        warehouseQuantitiesLoading: false,
        warehouseQuantitiesError: '',
        warehouseQuantitiesList: action.payload.data,
        warehouseQuantitiesMeta: action.payload.meta,
      };
    case WAREHOUSE_QUANTITIES_FETCH_ERROR:
      return {
        ...state,
        warehouseQuantitiesError: action.payload.message,
        warehouseQuantitiesLoading: false,
      };
    case WAREHOUSE_QUANTITIES_FETCH_DETAIL:
      return {
        ...state,
        warehouseQuantitiesLoading: false,
      };
    case WAREHOUSE_QUANTITIES_FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        warehouseQuantitiesLoading: false,
        warehouseQuantitiesDetail: action.payload.data,
      };
    case WAREHOUSE_QUANTITIES_CREATE:
      return { ...state, warehouseQuantitiesLoading: true };
    case WAREHOUSE_QUANTITIES_UPDATE:
      return { ...state, warehouseQuantitiesLoading: true };
    case WAREHOUSE_QUANTITIES_DELETE:
      return { ...state, warehouseQuantitiesLoading: true };
    case WAREHOUSE_QUANTITIES_ACTION_SUCCESS:
      return {
        ...state,
        warehouseQuantitiesLoading: false,
        warehouseQuantitiesList: null,
        warehouseQuantitiesDetail: null,
      };
    case WAREHOUSE_QUANTITIES_ACTION_ERROR:
      return {
        ...state,
        warehouseQuantitiesLoading: false,
        warehouseQuantitiesError: action.payload.message,
      };
    default:
      return { ...state };
  }
};
