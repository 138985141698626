import React from 'react';
import TableFieldsSelector from './TableFieldsSelector';

const defaultWlfFields = [
  'created_at',
  'material',
  'supplier',
  'batch',
  'volume',
  'warehouse',
  'state',
  'state_batch',
  'state_supplier',
  'created_by',
  'delivery_list',
  'invoice_number',
  'invoice_toll_number',
  'invoice_transport_number',
  'note',
  'price_per_unit',
  'total_price',
  'toll_price',
  'transport_price',
  'total_cost',
  'cost_per_unit',
  'related_ids',
  // 'is_virtual',
];

const WarehouseLogFieldsSelector = ({ messages, columns }) => (
  <TableFieldsSelector
    columns={columns}
    messages={messages}
    updateAction="profile/updateMovementColumns"
    labelPrefix="warehouses.movements.table"
    namePrefix="warehouse_logs"
  />
);

export { defaultWlfFields };

export default WarehouseLogFieldsSelector;
