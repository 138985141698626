/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
// export const defaultMenuType = 'menu-default';

// export const subHiddenBreakpoint = 1440;
// export const menuHiddenBreakpoint = 768;
export const mobileScreenBoundary = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = 'axxence_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.blueolympic';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const isPermissionGuardActive = true;
export const colors = ['blueolympic'];
