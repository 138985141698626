import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { pathOr } from 'rambda';
import { logout as clearToken } from '../../api/common';
import { homePath, loginPath } from '../../routePaths';
import {
  fetchUserDetails,
  loginUserError,
  loginUserSuccess,
  profileChangePasswordError,
  profileChangePasswordSuccess,
  profileUpdateError,
  profileUpdateSuccess,
  userDetailsError,
  userDetailsSuccess,
} from './actions';
import {
  FETCH_USER_DETAILS,
  LOGIN_USER,
  LOGOUT_USER,
  PROFILE_CHANGE_PASSWORD,
  PROFILE_UPDATE,
} from '../action_codes';
import { setAuthToken, setCurrentUser } from '../../helpers/Utils';
import LoginApi from '../../api/login';
import { GetApi } from '../../api/api';
import { interpretResponseCode } from '../../errorHandling';
import { submitFormActionTriad } from '../helpers';
import { UserRole } from '../../helpers/authHelper';

const resolveRole = (user) => {
  if (user.is_superuser) {
    return UserRole.Admin;
  }
  return user.group;
};

const mapPermissions = (user) => {
  const rv = {};
  user.permissions.forEach((p) => {
    rv[p] = true;
  });
  return rv;
};

const loginWithEmailPasswordAsync = async (email, password) =>
  LoginApi({
    url: 'tokens/',
    data: { username: email, password },
    method: 'POST',
    handleResponse: (response) => response.data,
    handleError: (error) => error,
  });

function* loginWithEmailPassword({ payload }) {
  const {
    user: { email, password },
    from,
  } = payload;

  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      const token = pathOr('', 'data.response.token', loginUser);
      setAuthToken(token);
      yield put(loginUserSuccess({}));
      yield put(fetchUserDetails(payload.history, from));
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    const message = interpretResponseCode(error);
    yield put(loginUserError(message));
  }
}

function logout({ payload }) {
  const { history } = payload;
  clearToken();
  setCurrentUser();
  history.push(loginPath);
}

const fetchUserDetailAsync = async () => {
  return GetApi({
    url: 'users/me/',
    data: {},
    handleResponse: (response) => response.data,
    handleError: (error) => error,
  });
};

function* fetchUserDetailsGenerator({ payload }) {
  const { history, from } = payload;
  try {
    const userDetails = yield call(fetchUserDetailAsync);
    if (!userDetails.message) {
      const user = userDetails.response;
      const userWithRole = {
        ...user,
        role: resolveRole(user),
        permissions: mapPermissions(user),
      };
      setCurrentUser(userWithRole);
      yield put(userDetailsSuccess(userWithRole));
      if (history) {
        payload.history.push(from || homePath);
      }
    } else {
      yield put(userDetailsError(userDetails.message));
    }
  } catch (error) {
    const message = interpretResponseCode(error);
    yield put(userDetailsError(message));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchGetUserDetails() {
  yield takeEvery(FETCH_USER_DETAILS, fetchUserDetailsGenerator);
}

const profileChangePasswordWatcher = submitFormActionTriad(
  PROFILE_CHANGE_PASSWORD,
  'PUT',
  profileChangePasswordSuccess,
  profileChangePasswordError
).watcherFunction;

const profileUpdateWatcher = submitFormActionTriad(
  PROFILE_UPDATE,
  'PUT',
  profileUpdateSuccess,
  profileUpdateError
).watcherFunction;

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchGetUserDetails),
    fork(profileChangePasswordWatcher),
    fork(profileUpdateWatcher),
  ]);
}
