import {
  REPORTS_FETCH,
  REPORTS_FETCH_SUCCESS,
  REPORTS_FETCH_FAILURE,
} from '../action_codes';
import { getDefaultPageSize } from '../../helpers/Utils';

const INIT_STATE = {
  reportsData: null,
  reportsMeta: { sort: 'created_at', page: 1, limit: getDefaultPageSize() },
  reportsLoading: false,
  reportsError: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REPORTS_FETCH:
      return { ...state, reportsLoading: true, reportsError: '' };
    case REPORTS_FETCH_SUCCESS:
      return {
        ...state,
        reportsLoading: false,
        reportsError: '',
        reportsData: action.payload.data,
        reportsMeta: action.payload.meta,
      };
    case REPORTS_FETCH_FAILURE:
      return {
        ...state,
        reportsLoading: false,
        reportsError: action.payload.message,
      };
    default:
      return { ...state };
  }
};
