import {
  MENU_CLICK_MOBILE_MENU,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
  MENU_SCREEN_WIDTH,
  MENU_EXPAND_SUBMENU,
  MENU_SET_MOBILE_EXPANDED,
} from '../action_codes';

import { mobileScreenBoundary } from '../../constants/defaultValues';

const buildClassNames = ({
  isMobileScreen,
  isMobileMenuExpanded,
  selectedMenuHasSubItems,
  submenuExpanded,
}) => {
  const classNames = ['menu-default'];
  if (isMobileScreen) {
    classNames.push('menu-hidden');
    if (isMobileMenuExpanded) {
      classNames.push('main-show-temporary');
      if (selectedMenuHasSubItems && submenuExpanded) {
        classNames.push('sub-show-temporary');
      }
    }
  } else if (!selectedMenuHasSubItems || !submenuExpanded) {
    classNames.push('menu-sub-hidden');
  }
  return classNames.join(' ');
};

const INIT_STATE = {
  isMobileScreen: false,
  isMobileMenuExpanded: false,
  selectedMenuHasSubItems: false,
  submenuExpanded: false,
  classNames: 'menu-default',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MENU_CHANGE_HAS_SUB_ITEM_STATUS: {
      const newState = { ...state, selectedMenuHasSubItems: action.payload };
      newState.classNames = buildClassNames(newState);
      return newState;
    }
    case MENU_CLICK_MOBILE_MENU: {
      const newState = {
        ...state,
        isMobileMenuExpanded: !state.isMobileMenuExpanded,
      };
      newState.classNames = buildClassNames(newState);
      return newState;
    }
    case MENU_SCREEN_WIDTH: {
      const newState = {
        ...state,
        isMobileScreen: action.payload.screenWidth < mobileScreenBoundary,
      };
      newState.classNames = buildClassNames(newState);
      return newState;
    }
    case MENU_EXPAND_SUBMENU: {
      const newState = { ...state, submenuExpanded: action.payload };
      newState.classNames = buildClassNames(newState);
      return newState;
    }
    case MENU_SET_MOBILE_EXPANDED: {
      const newState = { ...state, isMobileMenuExpanded: action.payload };
      newState.classNames = buildClassNames(newState);
      return newState;
    }
    default:
      return { ...state };
  }
};
