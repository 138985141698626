import Axios from 'axios';
import {
  call,
  put,
  takeLatest,
  takeEvery,
  cancelled,
} from 'redux-saga/effects';
import { pathOr } from 'rambda';
import { interpretResponseCode } from '../errorHandling';
import { GetApi, PostApi } from '../api/api';
import { buildTableQueryParams } from '../api/urlQuery';

const fetchTableAsync = async (url, payload, cancelToken, filterConverter) => {
  // console.log('fetchTableAsync:', url, payload);
  let finalFilter = null;
  if (payload.filter) {
    finalFilter = filterConverter
      ? filterConverter(payload.filter)
      : payload.filter;
  }

  const params = buildTableQueryParams(
    payload.page,
    payload.limit,
    payload.multiSort,
    finalFilter
  );

  return GetApi({
    url,
    params,
    handleResponse: (response) => response.data,
    handleError: (error) => error,
    cancelToken,
  });
};

const fetchTableRoutine = (
  url,
  successAction,
  failureAction,
  trace,
  filterConverter
) => {
  return {
    *routine({ payload }) {
      if (payload.sort) {
        console.error(
          'Sort parameter no longer supported, use multiSort instead'
        );
      }
      if (payload.page === 0 && payload.limit) {
        console.error(payload);
        throw Error('Zero page not allowed, paging is 1-based');
      }
      const finalUrl = typeof url === 'function' ? url(payload) : url;
      const cancelSource = Axios.CancelToken.source();
      try {
        if (trace) {
          console.log('starting fetch table routine', finalUrl, payload);
        }
        const response = yield call(
          fetchTableAsync,
          finalUrl,
          payload,
          cancelSource.token,
          filterConverter
        );
        if (trace) {
          console.log('ended fetch table routine', finalUrl);
          console.log('fetched response', response);
        }
        if (response.message) {
          if (trace) {
            console.log('table fetch failure', response.message);
          }
          yield put(failureAction(response.message));
        } else {
          const { items, metadata } = response;
          if (trace) {
            console.log('table fetch success', items);
            console.log('table fetch meta', metadata);
          }
          yield put(successAction(items, metadata));
        }
      } catch (error) {
        if (trace) {
          console.log('table fetch error', error);
        }
        const message = interpretResponseCode(error);
        yield put(failureAction(message));
      } finally {
        if (yield cancelled()) {
          cancelSource.cancel();
          // console.log(cancelSource.token);
        }
      }
    },
  };
};

const fetchTableActionTriad = (
  fetchActionCode,
  url,
  successAction,
  failureAction,
  trace = false,
  filterConverter = null
) => {
  return {
    *watcherFunction() {
      yield takeLatest(
        fetchActionCode,
        fetchTableRoutine(
          url,
          successAction,
          failureAction,
          trace,
          filterConverter
        ).routine
      );
    },
  };
};

const submitFormAsync = async (url, method, data) => {
  return PostApi({
    url,
    method,
    data,
    handleResponse: (response) => response.data,
    handleError: (error) => error,
  });
};

function submitFormRoutine(method, successAction, failureAction, trace) {
  return {
    *routine({ payload }) {
      const { url, data, onFinish } = payload;
      if (trace) {
        console.log('Sending form data', url, method, data);
      }
      try {
        const response = yield call(submitFormAsync, url, method, data);
        if (trace) {
          console.log('Received response or error', response);
        }
        if (response.message) {
          let errors = pathOr(null, 'response.data.errors', response);
          if (!errors) {
            errors = pathOr(null, 'response.data.response', response);
            if (errors && errors instanceof Array) {
              yield put(
                failureAction(errors[0].message, {
                  __all__: errors.map((e) => e.message),
                })
              );
            }
            if (errors) {
              errors = { __all__: errors.map((e) => e.message) };
            } else {
              errors = { __all__: [interpretResponseCode(response)] };
            }
          }
          if (trace) {
            console.log('There are errors', errors);
          }
          const msg = errors.message || response.message;
          if (!errors.code || errors.code !== 409) {
            yield put(failureAction(msg, errors));
          }
          onFinish(false, errors);
        } else {
          if (trace) {
            console.log('Submitted successfully');
          }
          yield put(successAction(response.response));
          onFinish(true, null, response.response);
        }
      } catch (error) {
        if (trace) {
          console.log('There was an error while submitting data');
          console.error(error);
        }

        const message = interpretResponseCode(error);
        yield put(failureAction(message));
        onFinish(false);
      }
    },
  };
}

const submitFormActionTriad = (
  submitActionCode,
  method,
  successAction,
  failureAction,
  trace = false
) => {
  return {
    *watcherFunction() {
      yield takeEvery(
        submitActionCode,
        submitFormRoutine(method, successAction, failureAction, trace).routine
      );
    },
  };
};

const fetchDetailAsync = async (url) => {
  return GetApi({
    url,
    handleResponse: (response) => response.data,
    handleError: (error) => error,
  });
};

function fetchDetailRoutine(successAction, failureAction, trace) {
  return {
    *routine({ payload }) {
      const { url } = payload;
      if (trace) {
        console.log('fetch detail invoked: ', url);
      }
      try {
        const response = yield call(fetchDetailAsync, url);
        if (trace) {
          console.log('obtained response');
        }

        if (response.message) {
          if (trace) {
            console.log('fetch detail response error:', response.message);
          }
          yield put(failureAction(response.message));
        } else {
          if (trace) {
            console.log('fetch detail response OK', response);
          }
          if (trace) {
            console.log('fetch detail response data', response.response);
          }
          yield put(successAction(response.response));
        }
      } catch (error) {
        if (trace) {
          console.log('fetch detail failure', error);
        }
        const message = interpretResponseCode(error);
        yield put(failureAction(message));
      }
    },
  };
}

const fetchDetailActionTriad = (
  fetchActionCode,
  successAction,
  failureAction,
  trace = false
) => {
  return {
    *watcherFunction() {
      yield takeEvery(
        fetchActionCode,
        fetchDetailRoutine(successAction, failureAction, trace).routine
      );
    },
  };
};

const updateSubState = (state, member, update) => {
  return {
    ...state,
    [member]: { ...state[member], ...update },
  };
};

const managementScreenActions = {
  fetchList: (state, member) => {
    return updateSubState(state, member, { loading: true, error: '' });
  },
  fetchListSuccess: (state, member, action) => {
    return updateSubState(state, member, {
      loading: false,
      error: '',
      list: action.payload.data,
      meta: action.payload.meta,
    });
  },
  fetchError: (state, member, action) => {
    return updateSubState(state, member, {
      loading: false,
      error: action.payload.message,
    });
  },
  fetchDetail: (state, member) => {
    return updateSubState(state, member, { loading: true, error: '' });
  },
  fetchDetailSuccess: (state, member, action) => {
    return updateSubState(state, member, {
      loading: false,
      error: '',
      detail: action.payload.data,
    });
  },
  createAction: (state, member) => {
    return updateSubState(state, member, { loading: true });
  },
  updateAction: (state, member) => {
    return updateSubState(state, member, { loading: true });
  },
  deleteAction: (state, member) => {
    return updateSubState(state, member, { loading: true });
  },
  actionSuccess: (state, member) => {
    return updateSubState(state, member, {
      loading: false,
      error: '',
      list: null,
      detail: null,
    });
  },
  actionError: (state, member, action) => {
    return updateSubState(state, member, {
      loading: false,
      error: action.payload.message,
    });
  },
  updateFilter: (state, member, action) => {
    return updateSubState(state, member, {
      filter: action.payload.filter,
    });
  },
  updateSort: (state, member, action) => {
    return updateSubState(state, member, {
      multiSort: action.payload.multiSort,
    });
  },
};

export {
  fetchTableActionTriad,
  submitFormActionTriad,
  fetchDetailActionTriad,
  managementScreenActions,
  fetchTableAsync,
};
