import React from 'react';
import { connect } from 'react-redux';
import TableFieldsSelector from './TableFieldsSelector';

const defaultAlfFields = [
  'created_at',
  'material',
  'supplier',
  'batch',
  'volume',
  'warehouse',
  'state',
  'state_batch',
  'state_supplier',
  'created_by',
  'delivery_list',
  'invoice_number',
  'invoice_toll_number',
  'invoice_transport_number',
  'note',
  'price_per_unit',
  'total_price',
  'toll_price',
  'transport_price',
  'total_cost',
  'cost_per_unit',
  'purpose_name',
  'purpose_code',
  'is_virtual',
  'state_material_purpose',
  'state_material_purpose_code',
  'related_ids',
];

const AccountingLogFieldsSelector = ({ messages, columns }) => (
  <TableFieldsSelector
    messages={messages}
    columns={columns}
    updateAction="profile/updateAccountingColumns"
    namePrefix="accounting_logs"
    labelPrefix="accounting.movements.table"
  />
);

export { defaultAlfFields };

const mapStateToProps = ({ authUser }) => ({
  currentUser: authUser.currentUser,
});

export default connect(mapStateToProps, {})(AccountingLogFieldsSelector);
