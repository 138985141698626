import React from 'react';
import { useDispatch } from 'react-redux';
import { orderedColumns } from './helper';
import DnDSortableView from '../../../components/DnDSortableView';
import FormSwitch from '../../../components/forms/FormSwitch';

const TableFieldsSelector = ({
  messages,
  columns,
  updateAction,
  labelPrefix,
  namePrefix,
}) => {
  const dispatch = useDispatch();

  const sortedColumns = orderedColumns(columns);

  const onSort = (sortedItems) => {
    const newColumns = { ...columns };
    let modified = false;
    sortedItems.forEach((sortInfo, index) => {
      if (newColumns[sortInfo.id].order !== index) {
        newColumns[sortInfo.id] = { ...newColumns[sortInfo.id], order: index };
        modified = true;
      }
    });
    if (modified) {
      dispatch({
        type: updateAction,
        payload: { columns: newColumns },
      });
    }
  };

  return (
    <>
      <DnDSortableView onDrop={onSort}>
        {sortedColumns.map((column) => (
          <FormSwitch
            key={column.name}
            label={messages[`${labelPrefix}.${column.name}`]}
            name={`${namePrefix}__${column.name}`}
          />
        ))}
      </DnDSortableView>
    </>
  );
};

export default TableFieldsSelector;
