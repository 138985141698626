const orderedColumns = (columnsDict) => {
  const orderableColumns = Object.keys(columnsDict).map((name) => ({
    ...columnsDict[name],
    name,
  }));
  orderableColumns.sort((a, b) => a.order - b.order);
  return orderableColumns;
};

// eslint-disable-next-line import/prefer-default-export
export { orderedColumns };
