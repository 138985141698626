import { createSlice } from '@reduxjs/toolkit';
import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchActionAsync } from './factories/apiAdapter';

/* eslint-disable no-param-reassign */
/* no-param-reassign required: https://redux-toolkit.js.org/usage/immer-reducers */

const sliceName = 'relatedMovements';

const createRelatedMovementsSlice = () => {
  const theSlice = createSlice({
    name: sliceName,
    initialState: {
      detail: null,
      detailLoading: false,
    },
    reducers: {
      setObjectDetail: (state, action) => {
        state.detail = action.payload.response;
        state.detailLoading = false;
      },
      loadDetail: (state) => {
        state.detailLoading = true;
      },
    },
  });
  return theSlice.reducer;
};

const createRelatedMovementsSaga = () => {
  const fetchDetailAsync = function* (action) {
    yield fetchActionAsync(
      action,
      action.payload.url,
      `${sliceName}/setObjectDetail`
    );
  };

  const fetchDetailWatcher = function* () {
    yield takeLatest(`${sliceName}/fetchDetail`, fetchDetailAsync);
  };

  return function* () {
    yield all([fork(fetchDetailWatcher)]);
  };
};

export { createRelatedMovementsSlice, createRelatedMovementsSaga };
