/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const SETTINGS_FETCH_ORDER_STATUS = 'SETTINGS_FETCH_ORDER_STATUS';
export const SETTINGS_FETCH_ORDER_STATUS_SUCCESS =
  'SETTINGS_FETCH_ORDER_STATUS_SUCCESS';
export const SETTINGS_FETCH_ORDER_STATUS_ERROR =
  'SETTINGS_FETCH_ORDER_STATUS_ERROR';
export const SETTINGS_SUBMIT_ORDER_STATUS = 'SETTINGS_SUBMIT_ORDER_STATUS';
export const SETTINGS_SUBMIT_NEW_ORDER_STATUS =
  'SETTINGS_SUBMIT_NEW_ORDER_STATUS';
export const SETTINGS_SUBMIT_ORDER_STATUS_SUCCESS =
  'SETTINGS_SUBMIT_ORDER_STATUS_SUCCESS';
export const SETTINGS_SUBMIT_ORDER_STATUS_ERROR =
  'SETTINGS_SUBMIT_ORDER_STATUS_ERROR';
export const SETTINGS_DELETE_ORDER_STATUS = 'SETTINGS_DELETE_ORDER_STATUS';
export const SETTINGS_DELETE_ORDER_STATUS_SUCCESS =
  'SETTINGS_DELETE_ORDER_STATUS_SUCCESS';
export const SETTINGS_DELETE_ORDER_STATUS_ERROR =
  'SETTINGS_DELETE_ORDER_STATUS_ERROR';
export const SETTINGS_ORDER_STATUS_LIST = 'SETTINGS_ORDER_STATUS_LIST';
export const SETTINGS_ORDER_STATUS_LIST_SUCCESS =
  'SETTINGS_ORDER_STATUS_LIST_SUCCESS';
export const SETTINGS_ORDER_STATUS_LIST_ERROR =
  'SETTINGS_ORDER_STATUS_LIST_ERROR';
export const SETTINGS_ORDER_STATUS_CHANGE_SORT =
  'SETTINGS_ORDER_STATUS_CHANGE_SORT';
export const SETTINGS_FETCH_TAG_DETAIL = 'SETTINGS_FETCH_TAG_DETAIL';
export const SETTINGS_FETCH_TAG_DETAIL_SUCCESS =
  'SETTINS_FETCH_TAG_DETAIL_SUCCESS';
export const SETTINGS_SUBMIT_NEW_TAG_DETAIL = 'SETTINGS_SUBMIT_NEW_TAG_DETAIL';
export const SETTINGS_SUBMIT_UPDATE_TAG_DETAIL =
  'SETTINGS_SUBMIT_UPDATE_TAG_DETAIL';
export const SETTINGS_DELETE_TAG = 'SETTINS_DELETE_TAG';
export const SETTINGS_TAG_DETAIL_SUCCESS = 'SETTINGS_TAG_DETAIL_SUCCESS';
export const SETTINGS_TAG_DETAIL_ERROR = 'SETTINGS_TAG_DETAIL_ERROR';
export const SETTINGS_TAG_FETCH_TABLE = 'SETTINGS_TAG_FETCH_TABLE';
export const SETTINGS_TAG_FETCH_TABLE_SUCCESS =
  'SETTINGS_TAG_FETCH_TABLE_SUCCESS';
export const SETTINGS_TAG_FETCH_TABLE_ERROR = 'SETTINGS_TAG_FETCH_TABLE_ERROR';
export const SETTINGS_TAG_CHANGE_SORT = 'SETTINGS_TAG_CHANGE_SORT';
export const SETTINGS_PURPOSES_TABLE = 'SETTINGS_PURPOSES_TABLE';
export const SETTINGS_PURPOSES_TABLE_SUCCESS =
  'SETTINGS_PURPOSES_TABLE_SUCCESS';
export const SETTINGS_PURPOSES_ERROR = 'SETTINGS_PURPOSES_ERROR';
export const SETTINGS_PURPOSES_DETAIL = 'SETTINGS_PURPOSES_DETAIL';
export const SETTINGS_PURPOSES_DETAIL_SUCCESS =
  'SETTINGS_PURPOSES_DETAIL_SUCCESS';
export const SETTINGS_PURPOSES_CREATE = 'SETTINGS_PURPOSES_CREATE';
export const SETTINGS_PURPOSES_UPDATE = 'SETTINGS_PURPOSES_UPDATE';
export const SETTINGS_PURPOSES_DELETE = 'SETTINGS_PURPOSES_DELETE';
export const SETTINGS_PURPOSES_ACTION_SUCCESS =
  'SETTINGS_PURPOSES_ACTION_SUCCESS';
export const SETTINGS_PURPOSES_CHANGE_SORT = 'SETTINS_PURPOSES_CHANGE_SORT';

export const SETTINGS_USERS_LIST_FETCH = 'SETTINGS_USERS_LIST_FETCH';
export const SETTINGS_USERS_LIST_FETCH_SUCCESS =
  'SETTINGS_USERS_LIST_FETCH_SUCCESS';
export const SETTINGS_USERS_FETCH_ERROR = 'SETTINGS_USERS_FETCH_ERROR';
export const SETTINGS_USERS_DETAIL_FETCH = 'SETTINGS_USERS_DETAIL_FETCH';
export const SETTINGS_USERS_DETAIL_FETCH_SUCCESS =
  'SETTINGS_USERS_DETAIL_FETCH_SUCCESS';
export const SETTINGS_USERS_CREATE = 'SETTINGS_USERS_CREATE';
export const SETTINGS_USERS_UPDATE = 'SETTINGS_USERS_UPDATE';
export const SETTINGS_USERS_DELETE = 'SETTINGS_USERS_DELETE';
export const SETTINGS_USERS_ACTION_SUCCESS = 'SETTINGS_USERS_ACTION_SUCCESS';
export const SETTINGS_USERS_ACTION_ERROR = 'SETTINGS_USERS_ACTION_ERROR';
export const SETTINGS_USERS_UPDATE_FILTER = 'SETTINGS_USERS_UPDATE_FILTER';
export const SETTINGS_USERS_UPDATE_SORT = 'SETTINGS_USERS_UPDATE_SORT';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_ERROR = 'FETCH_USER_DETAILS_ERROR';
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';
export const PROFILE_CHANGE_PASSWORD = 'PROFILE_CHANGE_PASSWORD';
export const PROFILE_CHANGE_PASSWORD_SUCCESS =
  'PROFILE_CHANGE_PASSWORD_SUCCESS';
export const PROFILE_CHANGE_PASSWORD_ERROR = 'PROFILE_CHANGE_PASSWORD_ERROR';

/* MENU */
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';
export const MENU_SCREEN_WIDTH = 'MENU_SCREEN_WIDTH';
export const MENU_EXPAND_SUBMENU = 'MENU_EXPAND_SUBMENU';
export const MENU_SET_MOBILE_EXPANDED = 'MENU_SET_MOBILE_EXPANDED';

/* ORDERS */
export const ORDERS_LIST = 'ORDERS_LIST';
export const ORDERS_LIST_SUCCESS = 'ORDERS_LIST_SUCCESS';
export const ORDERS_LIST_ERROR = 'ORDERS_LIST_ERROR';
export const ORDERS_LIST_UPDATE_META = 'ORDERS_LIST_UPDATE_META';
export const ORDERS_DETAIL = 'ORDERS_DETAIL';
export const ORDERS_DETAIL_SUCCESS = 'ORDERS_DETAIL_SUCCESS';
export const ORDERS_DETAIL_ERROR = 'ORDERS_DETAIL_ERROR';
export const ORDERS_DETAIL_SUBMIT = 'ORDERS_DETAIL_SUBMIT';
export const ORDERS_DETAIL_SUBMIT_SUCCESS = 'ORDERS_DETAIL_SUBMIT_SUCCESS';
export const ORDERS_CREATE_SUBMIT = 'ORDERS_CREATE_SUBMIT';
export const ORDERS_CREATE_SUBMIT_SUCCESS = 'ORDERS_CREATE_SUBMIT_SUCCESS';
export const ORDERS_CREATE_SUBMIT_ERROR = 'ORDERS_CREATE_SUBMIT_ERROR';
export const ORDERS_DELETE_SUBMIT = 'ORDERS_DELETE_SUBMIT';
export const ORDERS_DELETE_SUCCESS = 'ORDERS_DELETE_SUCCESS';
export const ORDERS_DELETE_ERROR = 'ORDERS_DELETE_ERROR';
export const ORDERS_DETAIL_FETCH_HISTORY = 'ORDER_DETAIL_FETCH_HISTORY';
export const ORDERS_DETAIL_FETCH_HISTORY_SUCCESS =
  'ORDER_DETAIL_FETCH_HISTORY_SUCCESS';
export const ORDERS_DETAIL_FETCH_HISTORY_ERROR =
  'ORDER_DETAIL_FETCH_HISTORY_ERROR';
export const ORDERS_DETAIL_HISTORY_ADD = 'ORDERS_DETAIL_HISTORY_ADD';
export const ORDERS_DETAIL_HISTORY_ADD_SUCCESS =
  'ORDERS_DETAIL_HISTORY_ADD_SUCCESS';
export const ORDERS_DETAIL_HISTORY_ADD_ERROR =
  'ORDERS_DETAIL_HISTORY_ADD_ERROR';

/* GLOBALS */
export const FETCH_MATERIALS = 'FETCH_MATERIALS';
export const FETCH_MATERIALS_SUCCESS = 'FETCH_MATERIALS_SUCCESS';
export const FETCH_MATERIALS_ERROR = 'FETCH_MATERIALS_ERROR';
export const FETCH_MATERIAL_TYPES = 'FETCH_MATERIAL_TYPES';
export const FETCH_MATERIAL_TYPES_SUCCESS = 'FETCH_MATERIAL_TYPES_SUCCESS';
export const FETCH_MATERIAL_TYPES_ERROR = 'FETCH_MATERIAL_TYPES_ERROR';
export const FETCH_WAREHOUSES = 'FETCH_WAREHOUSES';
export const FETCH_CITY_WAREHOUSES = 'FETCH_CITY_WAREHOUSES';
export const FETCH_WAREHOUSES_SUCCESS = 'FETCH_WAREHOUSES_SUCCESS';
export const FETCH_WAREHOUSES_ERROR = 'FETCH_WAREHOUSES_ERROR';
export const FETCH_ORDER_STATES = 'FETCH_ORDER_STATES';
export const FETCH_ORDER_STATES_SUCCESS = 'FETCH_ORDER_STATES_SUCCESS';
export const FETCH_ORDER_STATES_ERROR = 'FETCH_ORDER_STATES_ERROR';
export const FETCH_UNITS = 'FETCH_UNITS';
export const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS';
export const FETCH_UNITS_FAILURE = 'FETCH_UNITS_FAILURE';
export const FETCH_SUPPLIERS = 'FETCH_SUPPLIERS';
export const FETCH_SUPPLIERS_SUCCESS = 'FETCH_SUPPLIERS_SUCCESS';
export const FETCH_SUPPLIERS_FAILURE = 'FETCH_SUPPLIERS_FAILURE';
export const FETCH_PRODUCT_SUPPLIERS = 'FETCH_PRODUCT_SUPPLIERS';
export const FETCH_PRODUCT_SUPPLIERS_SUCCESS =
  'FETCH_PRODUCT_SUPPLIERS_SUCCESS';
export const FETCH_PRODUCT_SUPPLIERS_FAILURE =
  'FETCH_PRODUCT_SUPPLIERS_FAILURE';
export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_ERROR = 'FETCH_COUNTRIES_ERROR';
export const FETCH_PURPOSES = 'FETCH_PURPOSES';
export const FETCH_PURPOSES_SUCCESS = 'FETCH_PURPOSES_SUCCESS';
export const FETCH_PURPOSES_BY_DIRECTION = 'FETCH_PURPOSES_BY_DIRECTION';
export const FETCH_PURPOSES_BY_DIRECTION_SUCCESS =
  'FETCH_PURPOSES_BY_DIRECTION_SUCCESS';
export const FETCH_PURPOSES_ERROR = 'FETCH_PURPOSES_ERROR';
export const FETCH_PURPOSES_DISTINCT = 'FETCH_PURPOSES_DISTINCT';
export const FETCH_PURPOSES_DISTINCT_SUCCESS =
  'FETCH_PURPOSES_DISTINCT_SUCCESS';
export const FETCH_PURPOSES_DISTINCT_ERROR = 'FETCH_PURPOSES_DISTINCT_ERROR';
export const FETCH_PURPOSES_COMPLETE = 'FETCH_PURPOSES_COMPLETE';
export const FETCH_PURPOSES_COMPLETE_SUCCESS =
  'FETCH_PURPOSES_COMPLETE_SUCCESS';
export const FETCH_PURPOSES_COMPLETE_ERROR = 'FETCH_PURPOSES_COMPLETE_ERROR';
export const FETCH_GLOBAL_PRODUCTS = 'FETCH_GLOBAL_PRODUCTS';
export const FETCH_GLOBAL_PRODUCTS_SUCCESS = 'FETCH_GLOBAL_PRODUCTS_SUCCESS';
export const FETCH_GLOBAL_PRODUCTS_ERROR = 'FETCH_GLOBAL_PRODUCTS_ERROR';
export const CLEAR_GLOBALS = 'CLEAR_GLOBALS';
export const FETCH_GLOBAL_ROLES = 'FETCH_GLOBAL_ROLES';
export const FETCH_GLOBAL_ROLES_SUCCESS = 'FETCH_GLOBAL_ROLES_SUCCESS';
export const FETCH_GLOBAL_ROLES_ERROR = 'FETCH_GLOBAL_ROLES_ERROR';
export const FETCH_CITIES = 'FETCH_CITIES';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_ERROR = 'FETCH_CITIES_ERROR';

/* WAREHOUSES */
export const WAREHOUSES_SUBMIT_MOVEMENT = 'WAREHOUSES_SUBMIT_MOVEMENT';
export const WAREHOUSES_SUBMIT_CHANGE_MOVEMENT =
  'WAREHOUSES_SUBMIT_CHANGE_MOVEMENT';
export const WAREHOUSES_SUBMIT_REVERT_MOVEMENT =
  'WAREHOUSES_SUBMIT_REVERT_MOVEMENT';
export const WAREHOUSES_SUBMIT_SUCCESS = 'WAREHOUSES_SUBMIT_SUCCESS';
export const WAREHOUSES_SUBMIT_ERROR = 'WAREHOUES_SUBMIT_ERROR';
export const WAREHOUSES_FETCH_MOVEMENT_LIST = 'WAREHOUSES_FETCH_MOVEMENT_LIST';
export const WAREHOUSES_FETCH_MOVEMENT_LIST_SUCCESS =
  'WAREHOUSES_FETCH_MOVEMENT_LIST_SUCCESS';
export const WAREHOUSES_FETCH_MOVEMENT_LIST_ERROR =
  'WAREHOUSES_FETCH_MOVEMENT_LIST_ERROR';
export const WAREHOUSES_MOVEMENT_LIST_SORT = 'WAREHOUSES_MOVEMENT_LIST_SORT';
export const WAREHOUSES_MOVEMENTS_UPDATE_FILTER =
  'WAREHOUSES_MOVEMENTS_UPDATE_FILTER';
export const WAREHOUSES_FETCH_TRANSFERS_LIST =
  'WAREHOUSES_FETCH_TRANSFERS_LIST';
export const WAREHOUSES_FETCH_TRANSFERS_LIST_SUCCESS =
  'WAREHOUSES_FETCH_TRANSFERS_LIST_SUCCESS';
export const WAREHOUSES_FETCH_TRANSFERS_LIST_ERROR =
  'WAREHOUSES_FETCH_TRANSFERS_LIST_ERROR';
export const WAREHOUSES_TRANSFERS_UPDATE_FILTER =
  'WAREHOUSES_TRANSFERS_UPDATE_FILTER';
export const WAREHOUSES_FETCH_INVENTORY = 'WAREHOUSES_FETCH_INVENTORY';
export const WAREHOUSES_UPDATE_INVENTORY_FILTER =
  'WAREHOUSES_UPDATE_INVENTORY_FILTER';
export const WAREHOUSES_FETCH_INVENTORY_SUCCESS =
  'WAREHOUESS_FETCH_INVENTORY_SUCCESS';
export const WAREHOUSES_FETCH_INVENTORY_ERROR =
  'WAREHOUSES_FETCH_INENTORY_ERROR';

export const WAREHOUSES_INVOICES_CLEAR = 'WAREHOUSES_INVOICES_CLEAR';
export const WAREHOUSES_INVOICES_UPDATE_SORT =
  'WAREHOUSES_INVOICES_UPDATE_SORT';
export const WAREHOUSES_INVOICES_FETCH_LIST = 'WAREHOUSES_INVOICES_FETCH_LIST';
export const WAREHOUSES_INVOICES_FETCH_LIST_SUCCESS =
  'WAREHOUSES_INVOICES_FETCH_LIST_SUCCESS';
export const WAREHOUSES_INVOICES_FETCH_ERROR =
  'WAREHOUSES_INVOICES_FETCH_ERROR';
export const WAREHOUSES_INVOICES_TOLL_FETCH_LIST =
  'WAREHOUSES_INVOICES_TOLL_FETCH_LIST';
export const WAREHOUSES_INVOICES_TOLL_FETCH_LIST_SUCCESS =
  'WAREHOUSES_INVOICES_TOLL_FETCH_LIST_SUCCESS';
export const WAREHOUSES_INVOICES_TOLL_FETCH_ERROR =
  'WAREHOUSES_INVOICES_TOLL_FETCH_ERROR';
export const WAREHOUSES_INVOICES_TOLL_CHANGE_SORT =
  'WAREHOUSES_INVOICES_TOLL_CHANGE_SORT';
export const WAREHOUSES_INVOICES_TOLL_UPDATE_FILTER =
  'WAREHOUSES_INVOICES_TOLL_UPDATE_FILTER';
export const WAREHOUSES_INVOICES_TOLL_CREATE =
  'WAREHOUSES_INVOICES_TOLL_CREATE';
export const WAREHOUSES_INVOICES_TOLL_UPDATE =
  'WAREHOUSES_INVOICES_TOLL_UPDATE';
export const WAREHOUSES_INVOICES_TOLL_DELETE =
  'WAREHOUSES_INVOICES_TOLL_DELETE';
export const WAREHOUSES_INVOICES_TOLL_ACTION_SUCCESS =
  'WAREHOUSES_INVOICES_TOLL_ACTION_SUCCESS';
export const WAREHOUSES_INVOICES_TOLL_ACTION_ERROR =
  'WAREHOUSES_INVOICES_TOLL_ACTION_ERROR';
export const WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL =
  'WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL';
export const WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL_SUCCESS =
  'WAREHOUSES_INVOICES_TOLL_FETCH_DETAIL_SUCCESS';
export const WAREHOUSES_INVOICES_TOLL_CLEAR = 'WAREHOUSES_INVOICES_TOLL_CLEAR';
export const WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST =
  'WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST';
export const WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST_SUCCESS =
  'WAREHOUSES_INVOICES_TRANSPORT_FETCH_LIST_SUCCESS';
export const WAREHOUSES_INVOICES_TRANSPORT_FETCH_ERROR =
  'WAREHOUSES_INVOICES_TRANSPORT_FETCH_ERROR';
export const WAREHOUSES_INVOICES_TRANSPORT_CHANGE_SORT =
  'WAREHOUSES_INVOICES_TRANSPORT_CHANGE_SORT';
export const WAREHOUSES_INVOICES_TRANSPORT_UPDATE_FILTER =
  'WAREHOUSES_INVOICES_TRANSPORT_UPDATE_FILTER';
export const WAREHOUSES_INVOICES_TRANSPORT_CREATE =
  'WAREHOUSES_INVOICES_TRANSPORT_CREATE';
export const WAREHOUSES_INVOICES_TRANSPORT_UPDATE =
  'WAREHOUSES_INVOICES_TRANSPORT_UPDATE';
export const WAREHOUSES_INVOICES_TRANSPORT_DELETE =
  'WAREHOUSES_INVOICES_TRANSPORT_DELETE';
export const WAREHOUSES_INVOICES_TRANSPORT_ACTION_SUCCESS =
  'WAREHOUSES_INVOICES_TRANSPORT_ACTION_SUCCESS';
export const WAREHOUSES_INVOICES_TRANSPORT_ACTION_ERROR =
  'WAREHOUSES_INVOICES_TRANSPORT_ACTION_ERROR';
export const WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL =
  'WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL';
export const WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL_SUCCESS =
  'WAREHOUSES_INVOICES_TRANSPORT_FETCH_DETAIL_SUCCESS';
export const WAREHOUSES_INVOICES_TRANSPORT_CLEAR =
  'WAREHOUSES_INVOICES_TRANSPORT_CLEAR';
export const WAREHOUSES_INVOICES_FETCH_DETAIL =
  'WAREHOUSES_INVOICES_FETCH_DETAIL';
export const WAREHOUSES_INVOICES_FETCH_DETAIL_SUCCESS =
  'WAREHOUSES_INVOICES_FETCH_DETAIL_SUCCESS';
export const WAREHOUSES_INVOICES_CREATE = 'WAREHOUSES_INVOICES_CREATE';
export const WAREHOUSES_INVOICES_UPDATE = 'WAREHOUSES_INVOICES_UPDATE';
export const WAREHOUSES_INVOICES_DELETE = 'WAREHOUSES_INVOICES_DELETE';
export const WAREHOUSES_INVOICES_ACTION_SUCCESS =
  'WAREHOUSES_INVOICES_ACTION_SUCCESS';
export const WAREHOUSES_INVOICES_ACTION_ERROR =
  'WAREHOUSES_INVOICES_ACTION_ERROR';
export const WAREHOUSES_INVOICES_UPDATE_FILTER =
  'WAREHOUSES_INVOICES_UPDATE_FILTER';
export const WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS =
  'WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS';
export const WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS_SUCCESS =
  'WAREHOUSES_INVOICES_FETCH_DELIVERY_LISTS_SUCCESS';
export const WAREHOUSES_INVOICES_UPDATE_ASSIGNMENTS =
  'WAREHOUSES_INVOICES_UPDATE_ASSIGNMENTS';
export const WAREHOUSES_INVOICES_TOLL_UPDATE_ASSIGNMENTS =
  'WAREHOUSES_INVOICES_TOLL_UPDATE_ASSIGNMENTS';
export const WAREHOUSES_INVOICES_TRANSPORT_UPDATE_ASSIGNMENTS =
  'WAREHOUSES_INVOICES_TRANSPORT_UPDATE_ASSIGNMENTS';
export const WAREHOUSES_MOVEMENT_FETCH_DETAIL =
  'WAREHOUSES_MOVEMENT_FETCH_DETAIL';
export const WAREHOUSES_MOVEMENT_FETCH_DETAIL_SUCCESS =
  'WAREHOUSES_MOVEMENT_FETCH_DETAIL_SUCCESS';
export const WAREHOUSES_MOVEMENT_SUBMIT_DETAIL =
  'WAREHOUSES_MOVEMENT_SUBMIT_DETAIL';
export const WAREHOUSES_MOVEMENT_SUBMIT_SUCCESS =
  'WAREHOUSES_MOVEMENT_SUBMIT_SUCCESS';
export const WAREHOUSES_MOVEMENT_ERROR = 'WAREHOUSES_MOVEMENT_ERROR';
export const WAREHOUSES_MOVEMENT_SET_SHOW_STATE =
  'WAREHOUSES_MOVEMENT_SET_SHOW_STATE';
export const WAREHOUSES_FETCH_AVAIL_PURPOSES =
  'WAREHOUSES_MOVEMENT_FETCH_AVAIL_PURPOSES';
export const WAREHOUSES_FETCH_AVAIL_PURPOSES_SUCCESS =
  'WAREHOUSES_FETCH_AVAIL_PURPOSES_SUCCESS';
export const WAREHOUSES_FETCH_AVAIL_PURPOSES_FAILURE =
  'WAREHOUSES_FETCH_AVAIL_PURPOSES_FAILURE';
export const WAREHOUSES_SUBMIT_TRANSFER = 'WAREHOUSES_SUBMIT_TRANSFER';
export const WAREHOUSES_TRANSFER_FETCH_DETAIL =
  'WAREHOUSES_TRANSFER_FETCH_DETAIL';
export const WAREHOUSES_TRANSFER_FETCH_DETAIL_SUCCESS =
  'WAREHOUSES_TRANSFER_FETCH_DETAIL_SUCCESS';
export const WAREHOUSES_TRANSFER_SUBMIT_DETAIL =
  'WAREHOUSES_TRANSFER_SUBMIT_DETAIL';
export const WAREHOUSES_TRANSFER_SUBMIT_SUCCESS =
  'WAREHOUSES_TRANSFER_SUBMIT_SUCCESS';
export const WAREHOUSES_TRANSFER_ERROR = 'WAREHOUSES_TRANSFER_ERROR';

/* REPORTS */
export const REPORTS_FETCH = 'REPORTS_FETCH';
export const REPORTS_FETCH_SUCCESS = 'REPORTS_FETCH_SUCCESS';
export const REPORTS_FETCH_FAILURE = 'REPORTS_FETCH_FAILURE';

/* MATERIAL TYPES */
export const MATERIAL_TYPES_FETCH_LIST = 'MATERIAL_TYPES_FETCH_LIST';
export const MATERIAL_TYPES_FETCH_LIST_SUCCESS =
  'MATERIAL_TYPES_FETCH_LIST_SUCCESS';
export const MATERIAL_TYPES_FETCH_LIST_ERROR = 'MATERIAL_TYPES_ERROR';
export const MATERIAL_TYPES_FETCH_DETAIL = 'MATERIAL_TYPES_FETCH_DETAIL';
export const MATERIAL_TYPES_FETCH_DETAIL_SUCCESS =
  'MATERIAL_TYPES_FETCH_DETAIL_SUCCESS';
export const MATERIAL_TYPES_CHANGE_SORT = 'MATERIAL_TYPES_CHANGE_SORT';
export const MATERIAL_TYPES_ERROR = 'MATERIAL_TYPES_ERROR';
export const MATERIAL_TYPES_CREATE = 'MATERIAL_TYPES_CREATE';
export const MATERIAL_TYPES_UPDATE = 'MATERIAL_TYPES_UPDATE';
export const MATERIAL_TYPES_DELETE = 'MATERIAL_TYPES_DELETE';
export const MATERIAL_TYPES_ACTION_SUCCESS = 'MATERIAL_TYPES_ACTION_SUCCESS';
export const MATERIAL_TYPES_UPDATE_FILTER = 'MATERIAL_TYPES_UPDATE_FILTER';

/* MATERIALS */
export const MATERIALS_FETCH_LIST = 'MATERIALS_FETCH_LIST';
export const MATERIALS_FETCH_LIST_SUCCESS = 'MATERIALS_FETCH_LIST_SUCCESS';
export const MATERIALS_FETCH_LIST_ERROR = 'MATERIALS_FETCH_LIST_ERROR';
export const MATERIALS_FETCH_DETAIL = 'MATERIALS_FETCH_DETAIL';
export const MATERIALS_FETCH_DETAIL_SUCCESS = 'MATERIALS_FETCH_DETAIL_SUCCESS';
export const MATERIALS_DETAIL_ERROR = 'MATERIALS_DETAIL_ERROR';
export const MATERIALS_CREATE = 'MATERIALS_CREATE';
export const MATERIALS_UPDATE = 'MATERIALS_UPDATE';
export const MATERIALS_DELETE = 'MATERIALS_DELETE';
export const MATERIALS_SUCCESS = 'MATERIALS_SUCCESS';
export const MATERIALS_DETAIL_FETCH_CODES = 'MATERIALS_DETAIL_FETCH_CODES';
export const MATERIALS_DETAIL_FETCH_CODES_SUCCESS =
  'MATERIALS_DETAIL_FETCH_CODES_SUCCESS';
export const MATERIALS_DETAIL_FETCH_CODES_ERROR =
  'MATERIALS_DETAIL_FETCH_CODES_ERROR';
export const MATERIALS_CHANGE_FILTER = 'MATERIALS_CHANGE_FILTER';
export const MATERIALS_CHANGE_SORT = 'MATERIALS_CHANGE_SORT';
export const MATERIALS_DETAIL_CODES_CHANGE_SORT =
  'MATERIALS_DETAIL_CODES_CHANGE_SORT';
export const MATERIALS_DETAIL_QUANTITIES_CHANGE_SORT =
  'MATERIALS_DETAIL_QUANTITIES_CHANGE_SORT';

/* SUPPLIERS */
export const SUPPLIERS_FETCH_LIST = 'SUPPLIERS_FETCH_LIST';
export const SUPPLIERS_FETCH_LIST_SUCCESS = 'SUPPLIERS_FETCH_LIST_SUCCESS';
export const SUPPLIERS_FETCH_LIST_ERROR = 'SUPPLIERS_FETCH_LIST_ERROR';
export const SUPPLIERS_FETCH_DETAIL = 'SUPPLIERS_FETCH_DETAIL';
export const SUPPLIERS_FETCH_DETAIL_SUCCESS = 'SUPPLIERS_FETCH_DETAIL_SUCCESS';
export const SUPPLIERS_FETCH_DETAIL_ERROR = 'SUPPLIERS_DETAIL_ERROR';
export const SUPPLIERS_CHANGE_SORT = 'SUPPLIERS_CHANGE_SORT';
export const SUPPLIERS_CREATE = 'SUPPLIERS_CREATE';
export const SUPPLIERS_UPDATE = 'SUPPLIERS_UPDATE';
export const SUPPLIERS_DELETE = 'SUPPLIERS_DELETE';
export const SUPPLIERS_ACTION_SUCCESS = 'SUPPLIERS_ACTION_SUCCESS';
export const SUPPLIERS_ACTION_ERROR = 'SUPPLIERS_ACTION_ERROR';
export const SUPPLIERS_UPDATE_FILTER = 'SUPPLIERS_UPDATE_FILTER';

/* MATERIAL_CODES */
export const MATERIAL_CODES_FETCH_LIST = 'MATERIAL_CODES_FETCH_LIST';
export const MATERIAL_CODES_FETCH_LIST_SUCCESS =
  'MATERIAL_CODES_FETCH_LIST_SUCCESS';
export const MATERIAL_CODES_FETCH_ERROR = 'MATERIAL_CODES_FETCH_ERROR';
export const MATERIAL_CODES_FETCH_DETAIL = 'MATERIAL_CODES_FETCH_DETAIL';
export const MATERIAL_CODES_FETCH_DETAIL_SUCCESS =
  'MATERIAL_CODES_FETCH_DETAIL_SUCCESS';
export const MATERIAL_CODES_CREATE = 'MATERIAL_CODES_CREATE';
export const MATERIAL_CODES_UPDATE = 'MATERIAL_CODES_UPDATE';
export const MATERIAL_CODES_DELETE = 'MATERIAL_CODES_DELETE';
export const MATERIAL_CODES_ACTION_SUCCESS = 'MATERIAL_CODES_ACTION_SUCCESS';
export const MATERIAL_CODES_ACTION_ERROR = 'MATERIAL_CODES_ACTION_ERROR';

/* WAREHOUSE_QUANTITIES */
export const WAREHOUSE_QUANTITIES_FETCH_LIST =
  'WAREHOUSE_QUANTITIES_FETCH_LIST';
export const WAREHOUSE_QUANTITIES_FETCH_LIST_SUCCESS =
  'WAREHOUSE_QUANTITIES_FETCH_LIST_SUCCESS';
export const WAREHOUSE_QUANTITIES_FETCH_ERROR =
  'WAREHOUSE_QUANTITIES_FETCH_ERROR';
export const WAREHOUSE_QUANTITIES_FETCH_DETAIL =
  'WAREHOUSE_QUANTITIES_FETCH_DETAIL';
export const WAREHOUSE_QUANTITIES_FETCH_DETAIL_SUCCESS =
  'WAREHOUSE_QUANTITIES_FETCH_DETAIL_SUCCESS';
export const WAREHOUSE_QUANTITIES_CREATE = 'WAREHOUSE_QUANTITIES_CREATE';
export const WAREHOUSE_QUANTITIES_UPDATE = 'WAREHOUSE_QUANTITIES_UPDATE';
export const WAREHOUSE_QUANTITIES_DELETE = 'WAREHOUSE_QUANTITIES_DELETE';
export const WAREHOUSE_QUANTITIES_ACTION_SUCCESS =
  'WAREHOUSE_QUANTITIES_ACTION_SUCCESS';
export const WAREHOUSE_QUANTITIES_ACTION_ERROR =
  'WAREHOUSE_QUANTITIES_ACTION_ERROR';

/* WAREHOUES */
export const WAREHOUSES_SETTINGS_FETCH_LIST = 'WAREHOUSES_SETTINGS_FETCH_LIST';
export const WAREHOUSES_SETTINGS_FETCH_LIST_SUCCESS =
  'WAREHOUSES_SETTINGS_FETCH_LIST_SUCCESS';
export const WAREHOUSES_SETTINGS_FETCH_ERROR =
  'WAREHOUSES_SETTINGS_FETCH_ERROR';
export const WAREHOUSES_SETTINGS_FETCH_DETAIL =
  'WAREHOUSES_SETTINGS_FETCH_DETAIL';
export const WAREHOUSES_SETTINGS_FETCH_DETAIL_SUCCESS =
  'WAREHOUSES_SETTINGS_FETCH_DETAIL_SUCCESS';
export const WAREHOUSES_SETTINGS_CREATE = 'WAREHOUSES_SETTINGS_CREATE';
export const WAREHOUSES_SETTINGS_UPDATE = 'WAREHOUSES_SETTINGS_UPDATE';
export const WAREHOUSES_SETTINGS_DELETE = 'WAREHOUSES_SETTINGS_DELETE';
export const WAREHOUSES_SETTINGS_ACTION_SUCCESS =
  'WAREHOUSES_SETTINGS_ACTION_SUCCESS';
export const WAREHOUSES_SETTINGS_ACTION_ERROR =
  'WAREHOUSES_SETTINGS_ACTION_ERROR';
export const WAREHOUSES_SETTINGS_UPDATE_FILTER =
  'WAREHOUSES_SETTINGS_UPDATE_FILTER';
export const WAREHOUSES_SETTINGS_CHANGE_SORT =
  'WAREHOUSES_SETTINGS_CHANGE_SORT';

export const WAREHOUSES_LOOKUP_DETAILS = 'WAREHOUSES_LOOKUP_DETAILS';
export const WAREHOUSES_LOOKUP_DETAILS_SUCCESS =
  'WAREHOUSES_LOOKUP_DETAILS_SUCCESS';
export const WAREHOUSES_LOOKUP_DETAILS_ERROR =
  'WAREHOUSES_LOOKUP_DETAILS_ERROR';

export const AUDITS_FETCH_USERS = 'AUDITS_FETCH_USERS';
export const AUDITS_FETCH_ERROR = 'AUDITS_FETCH_ERROR';
export const AUDITS_FETCH_USERS_SUCCESS = 'AUDITS_FETCH_USERS_SUCCESS';
export const AUDITS_UPDATE_FILTER = 'AUDITS_UPDATE_FILTER';
export const AUDITS_UPDATE_SORT = 'AUDITS_UPDATE_SORT';
export const AUDITS_FETCH_TABLE = 'AUDITS_FETCH_TABLE';
export const AUDITS_FETCH_TABLE_SUCCESS = 'AUDITS_FETCH_TABLE_SUCCESS';
export const AUDITS_FETCH_DETAIL = 'AUDITS_FETCH_DETAIL';
export const AUDITS_FETCH_DETAIL_SUCCESS = 'AUDITS_FETCH_DETAIL_SUCCESS';

export const MOVEMENT_LOOKUP_ALL_MATERIALS = 'MOVEMENT_LOOKUP_ALL_MATERIALS';
export const MOVEMENT_LOOKUP_ALL_MATERIALS_SUCCESS =
  'MOVEMENT_LOOKUP_ALL_MATERIALS_SUCCESS';
export const MOVEMENT_LOOKUP_MATERIAL = 'MOVEMENT_LOOKUP_MATERIAL';
export const MOVEMENT_LOOKUP_MATERIAL_SUCCESS =
  'MOVEMENT_LOOKUP_MATERIAL_SUCCESS';
export const MOVEMENT_LOOKUP_ERROR = 'MOVEMENT_LOOKUP_ERROR';
export const MOVEMENT_LOOKUP_WAREHOUSES = 'MOVEMENT_LOOKUP_WAREHOUSES';
export const MOVEMENT_LOOKUP_WAREHOUSES_SUCCESS =
  'MOVEMENT_LOOKUP_WAREHOUSES_SUCCESS';
export const MOVEMENT_LOOKUP_SUPPLIERS = 'MOVEMENT_LOOKUP_SUPPLIERS';
export const MOVEMENT_LOOKUP_SUPPLIERS_SUCCESS =
  'MOVEMENT_LOOKUP_SUPPLIERS_SUCCESS';
export const MOVEMENT_LOOKUP_CLEAR = 'MOVEMENT_LOOKUP_CLEAR';
export const MOVEMENT_LOOKUP_CODE = 'MOVEMENT_LOOKUP_CODE';
export const MOVEMENT_LOOKUP_CODE_SUCCESS = 'MOVEMENT_LOOKUP_CODE_SUCCESS';
export const MOVEMENT_LOOKUP_CODE_ERROR = 'MOVEMENT_LOOKUP_CODE_ERROR';
export const MOVEMENT_LOOKUP_BATCHES = 'MOVEMENT_LOOKUP_BATCHES';
export const MOVEMENT_LOOKUP_BATCHES_SUCCESS =
  'MOVEMENT_LOOKUP_BATCHES_SUCCESS';
export const MOVEMENT_LOOKUP_DETAIL = 'MOVEMENT_LOOKUP_DETAIL';
export const MOVEMENT_LOOKUP_DETAIL_SUCCESS = 'MOVEMENT_LOOKUP_DETAIL_SUCCESS';
export const MOVEMENT_LOOKUP_DETAIL_ERROR = 'MOVEMENT_LOOKUP_DETAIL_ERROR';

// production
export const INGREDIENTS_FETCH_LIST = 'INGREDIENTS_FETCH_LIST';
export const INGREDIENTS_FETCH_LIST_SUCCESS = 'INGREDIENTS_FETCH_LIST_SUCCESS';
export const INGREDIENTS_FETCH_ERROR = 'INGREDIENTS_FETCH_ERROR';
export const INGREDIENTS_FETCH_DETAIL = 'INGREDIENTS_FETCH_DETAIL';
export const INGREDIENTS_FETCH_DETAIL_SUCCESS =
  'INGREDIENTS_FETCH_DETAIL_SUCCESS';
export const INGREDIENTS_CREATE = 'INGREDIENTS_CREATE';
export const INGREDIENTS_UPDATE = 'INGREDIENTS_UPDATE';
export const INGREDIENTS_DELETE = 'INGREDIENTS_DELETE';
export const INGREDIENTS_ACTION_SUCCESS = 'INGREDIENTS_ACTION_ERROR';
export const INGREDIENTS_ACTION_ERROR = 'INGREDIENTS_ACTION_SUCCESS';

export const WAREHOUSES_ACCOUNTING_LIST_SORT =
  'WAREHOUSES_ACCOUNTING_LIST_SORT';
export const WAREHOUSES_TRANSFER_LIST_CHANGE_SORT =
  'WAREHOUSES_TRANSFER_LIST_CHANGE_SORT';

export const ACCOUNTING_FETCH_INVENTORY = 'ACCOUNTING_FETCH_INVENTORY';
export const ACCOUNTING_FETCH_INVENTORY_SUCCESS =
  'ACCOUNTING_FETCH_INVENTORY_SUCCESS';
export const ACCOUNTING_FETCH_INVENTORY_ERROR =
  'ACCOUNTING_FETCH_INVENTORY_ERROR';
export const ACCOUNTING_INVENTORY_UPDATE_FILTER =
  'ACCOUNTING_INVENTORY_UPDATE_FILTER';
