import { createSlice } from '@reduxjs/toolkit';
import { all, fork, takeLatest, call, put } from 'redux-saga/effects';
import { pathOr } from 'rambda';
import axios from 'axios';
import { fetchActionAsync, requestAsync } from './apiAdapter';

/* eslint-disable no-param-reassign */
/* no-param-reassign required: https://redux-toolkit.js.org/usage/immer-reducers */

function* postReportAsync(action, url, successActionName) {
  const cancelSource = axios.CancelToken.source();
  const query = pathOr({}, 'payload.query', action);
  const modifiedAction = {
    payload: { ...action, method: 'POST', url, params: query },
  };
  const response = yield call(requestAsync, modifiedAction, cancelSource.token);
  // console.log('response', response);
  if (response instanceof Error) {
    yield put({ type: 'display/exception', payload: response });
  } else {
    yield put({ type: successActionName, payload: response });
  }
}

const createReportReducer = ({ sliceName }) => {
  const theSlice = createSlice({
    name: sliceName,
    initialState: {
      reportMeta: null,
      filter: {},
      dropdownSelectors: {},
      results: [],
      loading: false,
    },
    reducers: {
      setReportMeta: (state, action) => {
        state.reportMeta = pathOr(null, 'payload.response.content', action);
      },
      setFilter: (state, action) => {
        state.filter = action.payload.filter;
      },
      setAllDropdownSelectorItems: (state, action) => {
        state.dropdownSelectors = action.payload.dropdownSelectors;
      },
      setDropdownSelector: (state, action) => {
        state.dropdownSelectors[action.payload.name] = action.payload.items;
      },
      setResults: (state, action) => {
        state.results = pathOr([], 'payload.response.blocks', action);
        state.loading = false;
      },
      fetchData: (state) => {
        state.loading = true;
      },
    },
  });
  return theSlice.reducer;
};

const createReportSaga = ({ sliceName }) => {
  function* fetchDataAsync(action) {
    const url = `reports/${action.payload.reportId}/`;
    yield postReportAsync(action, url, `${sliceName}/setResults`);
  }

  function* fetchMetaAsync(action) {
    const url = `reports/${action.payload.reportId}/`;
    yield fetchActionAsync(action, url, `${sliceName}/setReportMeta`);
  }

  const fetchTableWatcher = function* () {
    // console.log('fetchTableWatcher: ', `${sliceName}/fetchData`);
    yield takeLatest(`${sliceName}/fetchData`, fetchDataAsync);
  };

  const fetchMetaWatcher = function* () {
    yield takeLatest(`${sliceName}/fetchMeta`, fetchMetaAsync);
  };

  return function* () {
    yield all([fork(fetchTableWatcher), fork(fetchMetaWatcher)]);
  };
};

export { createReportReducer, createReportSaga };
