import {
  FETCH_MATERIALS,
  FETCH_MATERIALS_ERROR,
  FETCH_MATERIALS_SUCCESS,
  FETCH_ORDER_STATES,
  FETCH_ORDER_STATES_ERROR,
  FETCH_ORDER_STATES_SUCCESS,
  FETCH_WAREHOUSES,
  FETCH_CITY_WAREHOUSES,
  FETCH_WAREHOUSES_ERROR,
  FETCH_WAREHOUSES_SUCCESS,
  FETCH_UNITS,
  FETCH_UNITS_SUCCESS,
  FETCH_UNITS_FAILURE,
  FETCH_SUPPLIERS,
  FETCH_SUPPLIERS_FAILURE,
  FETCH_SUPPLIERS_SUCCESS,
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_ERROR,
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_ERROR,
  FETCH_MATERIAL_TYPES,
  FETCH_MATERIAL_TYPES_SUCCESS,
  FETCH_MATERIAL_TYPES_ERROR,
  FETCH_PURPOSES,
  FETCH_PURPOSES_SUCCESS,
  FETCH_PURPOSES_ERROR,
  FETCH_GLOBAL_PRODUCTS,
  FETCH_GLOBAL_PRODUCTS_SUCCESS,
  FETCH_GLOBAL_PRODUCTS_ERROR,
  CLEAR_GLOBALS,
  FETCH_PRODUCT_SUPPLIERS,
  FETCH_PRODUCT_SUPPLIERS_SUCCESS,
  FETCH_PRODUCT_SUPPLIERS_FAILURE,
  FETCH_GLOBAL_ROLES,
  FETCH_GLOBAL_ROLES_SUCCESS,
  FETCH_GLOBAL_ROLES_ERROR,
  FETCH_CITIES,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_ERROR,
  FETCH_PURPOSES_DISTINCT,
  FETCH_PURPOSES_DISTINCT_SUCCESS,
  FETCH_PURPOSES_DISTINCT_ERROR,
  FETCH_PURPOSES_COMPLETE,
  FETCH_PURPOSES_COMPLETE_ERROR,
  FETCH_PURPOSES_COMPLETE_SUCCESS,
  FETCH_PURPOSES_BY_DIRECTION,
  FETCH_PURPOSES_BY_DIRECTION_SUCCESS,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_ERROR,
} from '../action_codes';

const INIT_STATE = {
  materials: null,
  error: '',
  loading: false,
  warehouses: null,
  warehousesError: '',
  warehousesLoading: false,
  orderStates: null,
  orderStatesError: '',
  orderStatesLoading: false,
  orderStatesMeta: {},
  units: null,
  unitsLoading: false,
  unitsError: '',
  suppliers: null,
  suppliersLoading: false,
  suppliersError: '',
  tags: null,
  tagsLoading: false,
  tagsError: '',
  materialTypes: null,
  materialTypesLoading: false,
  materialTypesError: '',
  countries: null,
  countriesLoading: false,
  countriesError: '',
  locations: null,
  locationsLoading: false,
  locationsError: '',
  purposes: null,
  purposesByDirection: null,
  purposesLoading: false,
  purposesError: '',
  purposesDistinct: null,
  purposesDistinctLoading: false,
  purposesDistinctError: '',
  purposesComplete: null,
  purposesCompleteLoading: false,
  purposesCompleteError: '',
  products: null,
  productsLoading: false,
  productsError: '',
  productSuppliers: null,
  productSuppliersLoading: false,
  productSuppliersError: '',
  roles: null,
  rolesLoading: false,
  rolesError: '',
  cities: null,
  citiesLoading: false,
  citiesError: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_MATERIALS:
      return { ...state, loading: true, error: '' };
    case FETCH_MATERIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        materials: action.payload.materials,
      };
    case FETCH_MATERIALS_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case FETCH_WAREHOUSES:
    case FETCH_CITY_WAREHOUSES:
      return { ...state, warehousesLoading: true, warehousesError: '' };
    case FETCH_WAREHOUSES_SUCCESS:
      return {
        ...state,
        warehousesLoading: false,
        warehousesError: '',
        warehouses: action.payload.warehouses,
      };
    case FETCH_WAREHOUSES_ERROR:
      return {
        ...state,
        warehousesLoading: false,
        warehousesError: action.payload.message,
      };
    case FETCH_ORDER_STATES:
      return { ...state, orderStatesLoading: true, orderStatesError: '' };
    case FETCH_ORDER_STATES_SUCCESS:
      return {
        ...state,
        orderStates: action.payload.states,
        orderStatesLoading: false,
        orderStatesError: '',
      };
    case FETCH_ORDER_STATES_ERROR:
      return {
        ...state,
        orderStatesLoading: false,
        orderStatesError: action.payload.message,
      };
    case FETCH_UNITS:
      return { ...state, unitsLoading: true, unitsError: '' };
    case FETCH_UNITS_SUCCESS:
      return {
        ...state,
        units: action.payload.units,
        unitsLoading: false,
        unitsError: '',
      };
    case FETCH_UNITS_FAILURE:
      return {
        ...state,
        unitsLoading: false,
        unitsError: action.payload.message,
      };
    case FETCH_SUPPLIERS:
      return { ...state, suppliersLoading: true, suppliersError: '' };
    case FETCH_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliersLoading: false,
        suppliersError: '',
        suppliers: action.payload.suppliers,
      };
    case FETCH_SUPPLIERS_FAILURE:
      return {
        ...state,
        suppliersLoading: false,
        suppliersError: action.payload.message,
      };
    case FETCH_TAGS:
      return { ...state, tagsLoading: true, tagsError: '' };
    case FETCH_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload.data,
        tagsLoading: false,
        tagsError: '',
      };
    case FETCH_TAGS_ERROR:
      return {
        ...state,
        tagsLoading: false,
        tagsError: action.payload.message,
      };
    case FETCH_MATERIAL_TYPES:
      return {
        ...state,
        materialTypesLoading: true,
        materialTypesError: '',
      };
    case FETCH_MATERIAL_TYPES_SUCCESS:
      return {
        ...state,
        materialTypesLoading: false,
        materialTypesError: '',
        materialTypes: action.payload.data,
      };
    case FETCH_MATERIAL_TYPES_ERROR:
      return {
        ...state,
        materialTypesLoading: false,
        materialTypesError: action.payload.message,
      };
    case FETCH_COUNTRIES:
      return {
        ...state,
        countriesLoading: true,
        countriesError: '',
      };
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload.data,
        countriesLoading: false,
        countriesError: '',
      };
    case FETCH_COUNTRIES_ERROR:
      return {
        ...state,
        countriesLoading: false,
        countriesError: action.payload.message,
      };
    case FETCH_LOCATIONS:
      return {
        ...state,
        locationsLoading: true,
        locationsError: '',
      };
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locationsLoading: false,
        locations: action.payload.data,
        locationsError: '',
      };
    case FETCH_LOCATIONS_ERROR:
      return {
        ...state,
        locationsLoading: false,
        locationsError: action.payload.message,
      };
    case FETCH_PURPOSES:
    case FETCH_PURPOSES_BY_DIRECTION:
      return {
        ...state,
        purposesLoading: true,
      };
    case FETCH_PURPOSES_SUCCESS:
      return {
        ...state,
        purposesLoading: false,
        purposes: action.payload.data,
        purposesError: '',
      };
    case FETCH_PURPOSES_BY_DIRECTION_SUCCESS:
      return {
        ...state,
        purposesLoading: false,
        purposesByDirection: action.payload.data,
        purposesError: '',
      };
    case FETCH_PURPOSES_ERROR:
      return {
        ...state,
        purposesLoading: false,
        purposesError: action.payload.message,
      };
    case FETCH_GLOBAL_PRODUCTS:
      return {
        ...state,
        productsLoading: true,
      };
    case FETCH_GLOBAL_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsLoading: false,
        productsError: '',
        products: action.payload.data,
      };
    case FETCH_GLOBAL_PRODUCTS_ERROR:
      return {
        ...state,
        productsLoading: false,
        productsError: action.payload.message,
      };
    case FETCH_PRODUCT_SUPPLIERS:
      return {
        ...state,
        productSuppliersLoading: true,
      };
    case FETCH_PRODUCT_SUPPLIERS_SUCCESS:
      return {
        ...state,
        productSuppliers: action.payload.data,
        productSuppliersLoading: false,
        productSuppliersError: '',
      };
    case FETCH_PRODUCT_SUPPLIERS_FAILURE:
      return {
        ...state,
        productSuppliersLoading: false,
        productSuppliersError: action.payload.message,
      };
    case CLEAR_GLOBALS:
      return {
        ...state,
        materials: null,
        warehouses: null,
        orderStates: null,
        units: null,
        suppliers: null,
        tags: null,
        materialTypes: null,
        purposes: null,
        products: null,
        productSuppliers: null,
      };
    case FETCH_GLOBAL_ROLES:
      return { ...state, rolesLoading: true, rolesError: '' };
    case FETCH_GLOBAL_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.data,
        rolesLoading: false,
        rolesError: '',
      };
    case FETCH_GLOBAL_ROLES_ERROR:
      return {
        ...state,
        rolesLoading: false,
        rolesError: action.payload.message,
      };
    case FETCH_CITIES:
      return {
        ...state,
        citiesLoading: true,
      };
    case FETCH_CITIES_SUCCESS:
      return {
        ...state,
        citiesLoading: false,
        cities: action.payload.data,
        purposesCities: '',
      };
    case FETCH_CITIES_ERROR:
      return {
        ...state,
        citiesLoading: false,
        citiesError: action.payload.message,
      };
    case FETCH_PURPOSES_DISTINCT:
      return {
        ...state,
        purposesDistinctLoading: true,
      };
    case FETCH_PURPOSES_DISTINCT_SUCCESS:
      return {
        ...state,
        purposesDistinctLoading: false,
        purposesDistinct: action.payload.data,
        purposesDistinctError: '',
      };
    case FETCH_PURPOSES_DISTINCT_ERROR:
      return {
        ...state,
        purposesDistinctLoading: false,
        purposesDistinctError: action.payload.message,
      };
    case FETCH_PURPOSES_COMPLETE:
      return {
        ...state,
        purposesCompleteLoading: true,
      };
    case FETCH_PURPOSES_COMPLETE_SUCCESS:
      return {
        ...state,
        purposesCompleteLoading: false,
        purposesComplete: action.payload.data.map((p) => ({
          value: p.id,
          label: `${p.code} ${p.direction} ${p.name}`,
        })),
        purposesCompleteError: '',
      };
    case FETCH_PURPOSES_COMPLETE_ERROR:
      return {
        ...state,
        purposesCompleteLoading: false,
        purposesCompleteError: action.payload.message,
      };
    default:
      return { ...state };
  }
};
