import { takeLatest } from 'redux-saga/effects';
import createCrudSaga from '../factories/sagaFactories';
import { fetchActionAsync } from '../factories/apiAdapter';

function* fetchCountAsync(action) {
  yield fetchActionAsync(action, action.payload.url, 'recounts/setCount');
}

/* eslint-disable func-names */

const createRecountsSaga = () => {
  return createCrudSaga({
    sliceName: 'recounts',
    rootUrl: 'warehouse-logs/recounts/',
    extensions: [
      function* () {
        yield takeLatest('recounts/fetchCount', fetchCountAsync);
      },
    ],
  });
};

export default createRecountsSaga;
