import { all, fork } from 'redux-saga/effects';
import { fetchDetailActionTriad, fetchTableActionTriad } from '../helpers';
import {
  AUDITS_FETCH_DETAIL,
  AUDITS_FETCH_TABLE,
  AUDITS_FETCH_USERS,
} from '../action_codes';
import {
  auditsFetchDetailSuccess,
  auditsFetchError,
  auditsFetchTableSuccess,
  auditsFetchUsersSuccess,
} from './actions';

const fetchUsersWatcher = fetchTableActionTriad(
  AUDITS_FETCH_USERS,
  'users/',
  auditsFetchUsersSuccess,
  auditsFetchError
).watcherFunction;

const fetchLogDataWatcher = fetchTableActionTriad(
  AUDITS_FETCH_TABLE,
  'log-entries/',
  auditsFetchTableSuccess,
  auditsFetchError
).watcherFunction;

const fetchDetailWatcher = fetchDetailActionTriad(
  AUDITS_FETCH_DETAIL,
  auditsFetchDetailSuccess,
  auditsFetchError
).watcherFunction;

export default function* rootSaga() {
  yield all([
    fork(fetchUsersWatcher),
    fork(fetchLogDataWatcher),
    fork(fetchDetailWatcher),
  ]);
}
