import {
  ORDERS_LIST,
  ORDERS_LIST_SUCCESS,
  ORDERS_LIST_ERROR,
  ORDERS_LIST_UPDATE_META,
  ORDERS_DETAIL,
  ORDERS_DETAIL_SUCCESS,
  ORDERS_DETAIL_ERROR,
  ORDERS_DETAIL_SUBMIT,
  ORDERS_DETAIL_SUBMIT_SUCCESS,
  ORDERS_CREATE_SUBMIT,
  ORDERS_CREATE_SUBMIT_SUCCESS,
  ORDERS_CREATE_SUBMIT_ERROR,
  ORDERS_DELETE_SUBMIT,
  ORDERS_DELETE_SUCCESS,
  ORDERS_DELETE_ERROR,
  ORDERS_DETAIL_FETCH_HISTORY,
  ORDERS_DETAIL_FETCH_HISTORY_SUCCESS,
  ORDERS_DETAIL_FETCH_HISTORY_ERROR,
  ORDERS_DETAIL_HISTORY_ADD,
  ORDERS_DETAIL_HISTORY_ADD_SUCCESS,
  ORDERS_DETAIL_HISTORY_ADD_ERROR,
} from '../action_codes';
import { getDefaultPageSize } from '../../helpers/Utils';

const INIT_STATE = {
  orderList: {
    data: [],
    multiSort: ['-created_at'],
    page: 1,
    limit: getDefaultPageSize(),
    total: 0,
    filter: {
      type: 'open',
      expression: '',
    },
  },
  orderDetail: {
    id: null,
    order: null,
  },
  loading: false,
  error: '',
  formErrors: {},
  detailHistory: {
    items: null,
    loading: false,
    error: '',
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ORDERS_LIST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case ORDERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        orderList: {
          ...state.orderList,
          data: action.payload.items,
          total: action.payload.paging.total,
        },
      };
    case ORDERS_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case ORDERS_LIST_UPDATE_META:
      return {
        ...state,
        orderList: {
          ...state.orderList,
          multiSort: action.payload.multiSort,
          page: action.payload.page,
          limit: action.payload.limit,
          filter: { ...action.payload.filter },
        },
      };
    case ORDERS_DETAIL:
      return {
        ...state,
        loading: true,
        error: '',
        orderId: action.payload.orderId,
        detailHistory: { items: null, loading: true, error: false },
      };
    case ORDERS_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        order: action.payload.order,
      };
    case ORDERS_DETAIL_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case ORDERS_DETAIL_SUBMIT:
      return { ...state, loading: true, error: '' };
    case ORDERS_DETAIL_SUBMIT_SUCCESS:
      return { ...state, loading: false, error: '', order: null };
    case ORDERS_CREATE_SUBMIT:
      return { ...state, loading: true, error: '' };
    case ORDERS_CREATE_SUBMIT_SUCCESS:
      return { ...state, loading: false, error: '' };
    case ORDERS_CREATE_SUBMIT_ERROR:
      return { ...state, loading: false, error: action.payload.messages };
    case ORDERS_DELETE_SUBMIT:
      return { ...state, loading: true, error: '' };
    case ORDERS_DELETE_SUCCESS:
      return { ...state, loading: false, error: '' };
    case ORDERS_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case ORDERS_DETAIL_FETCH_HISTORY:
      return { ...state, detailHistory: { loading: true } };
    case ORDERS_DETAIL_FETCH_HISTORY_SUCCESS:
      return {
        ...state,
        detailHistory: {
          loading: false,
          error: '',
          items: action.payload.data,
        },
      };
    case ORDERS_DETAIL_FETCH_HISTORY_ERROR:
      return {
        ...state,
        detailHistory: {
          ...state.detailHistory,
          loading: false,
          error: action.payload.message,
        },
      };
    case ORDERS_DETAIL_HISTORY_ADD:
      return { ...state };
    case ORDERS_DETAIL_HISTORY_ADD_SUCCESS:
      // reset order detail to enforce reload
      return {
        ...state,
        orderDetail: { ...state.orderDetail, order: null },
        detailHistory: { items: null, loading: false, error: '' },
      };
    case ORDERS_DETAIL_HISTORY_ADD_ERROR:
      return {
        ...state,
        detailHistory: {
          ...state.detailHistory,
          error: action.payload.message,
        },
      };
    default:
      return { ...state };
  }
};
