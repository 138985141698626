import axios from 'axios';
import { call, cancelled, put } from 'redux-saga/effects';
import { pathOr } from 'rambda';
import { GetApi, PostApi } from '../../api/api';

const convertToQueryValue = (item) => {
  if (typeof item === 'boolean') {
    return item.toString();
  }
  return item;
};

const toUrlParams = (queryDict) => {
  if (queryDict) {
    const params = new URLSearchParams();
    Object.keys(queryDict).forEach((key) => {
      const value = queryDict[key];
      if (value instanceof Array) {
        value.forEach((item) => {
          params.append(key, convertToQueryValue(item));
        });
      } else if (value !== null && value !== '') {
        params.append(key, convertToQueryValue(value));
      }
    });
    return params;
  }
  return null;
};

const getAsync = async (url, params, cancelToken) => {
  return GetApi({
    url,
    params,
    cancelToken,
    handleResponse: (response) => response.data,
    handleError: (error) => error,
  });
};

const requestAsync = async (action, cancelToken) => {
  const { url, data, method, params } = action.payload;
  return PostApi({
    url,
    data,
    method,
    cancelToken,
    params,
    handleResponse: (response) => response.data,
    handleError: (error) => error,
  });
};

function* fetchActionAsync(action, url, successAction) {
  const cancelSource = axios.CancelToken.source();
  try {
    const query = pathOr(null, 'payload.query', action);
    let params = {};
    if (query) {
      const updatedQuery = { ...query };
      updatedQuery.order_by = query.multiSort || [];
      delete updatedQuery.multiSort;

      params = toUrlParams(updatedQuery);
    }

    const response = yield call(getAsync, url, params, cancelSource.token);
    if (response instanceof Error) {
      yield put({ type: 'display/exception', payload: response });
    } else {
      yield put({ type: successAction, payload: response });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: 'display/exception', payload: error });
  } finally {
    if (yield cancelled()) {
      console.log('cancelled: ', url);
    }
  }
}

function* submitActionAsync(action) {
  const { onFinish } = action.payload;
  const cancelSource = axios.CancelToken.source();
  try {
    const response = yield call(requestAsync, action, cancelSource.token);
    if (response instanceof Error) {
      yield put({ type: 'display/exception', payload: response });
    }
    const errors = pathOr(null, 'response.data.errors', response);
    if (errors) {
      console.log('errors occurred:', errors);
      onFinish(false, errors);
    } else {
      // TODO: support for response data, not needed now
      onFinish(true, null, response);
    }
  } catch (error) {
    yield put({ type: 'display/exception', payload: error });
    onFinish(false, null);
  } finally {
    if (yield cancelled()) {
      onFinish(false, null);
    }
  }
}

export { fetchActionAsync, submitActionAsync, toUrlParams, requestAsync };
