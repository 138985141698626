import { takeLatest } from 'redux-saga/effects';
import { fetchActionAsync, submitActionAsync } from '../factories/apiAdapter';

/* eslint-disable no-param-reassign, func-names */
/* no-param-reassign required: https://redux-toolkit.js.org/usage/immer-reducers */

function* fetchDetailItemsAsync(action) {
  yield fetchActionAsync(
    action,
    action.payload.url,
    'production/detailItemsLoaded'
  );
}

function* postUpdateProductionState(action) {
  yield submitActionAsync(action);
}

const productionCrudExtensions = {
  extraFields: {
    detailItemsList: null,
    detailItemsLoading: false,
    updatingProductionStatus: true,
  },
  extraReducers: {
    loadDetailItems: (state) => {
      state.detailItemsLoading = true;
    },
    detailItemsLoaded: (state, action) => {
      state.detailItemsLoading = false;
      state.detailItemsList = action.payload.items;
    },
    updateProductionState: (state) => {
      state.updatingProductionStatus = true;
    },
  },
  extraSagas: [
    function* () {
      yield takeLatest('production/loadDetailItems', fetchDetailItemsAsync);
    },
    function* () {
      yield takeLatest(
        'production/updateProductionState',
        postUpdateProductionState
      );
    },
  ],
};

export default productionCrudExtensions;
