import React, { useState, useCallback, useEffect } from 'react';
import { Card } from './Card';
import update from 'immutability-helper';
const style = {
  width: '100%',
  maxWidth: 400
};
export const Container = ({ children, onDrop }) => {
  const initialCardsContent = children ? children.map((child, index) => ({ id: child.key, content: child })) : [];

  const [cards, setCards] = useState(initialCardsContent);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    const dragCard = cards[dragIndex];

    setCards(update(cards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard]
      ]
    }));
  }, [cards]);

  const executeOnDrop = useCallback(() => {
    if(onDrop){
      onDrop(cards)
    }
  }, [cards, onDrop]);

  useEffect(() => {
    const timer = setTimeout(executeOnDrop, 1000);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [cards, executeOnDrop]);

  const renderCard = (card, index) => {
    return (<Card key={card.id} index={index} id={card.id} content={card.content} moveCard={moveCard} />);
  };
  return (
    <>
      <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
    </>
  );
};
