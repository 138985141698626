import {
  ACCOUNTING_FETCH_INVENTORY,
  ACCOUNTING_FETCH_INVENTORY_ERROR,
  ACCOUNTING_FETCH_INVENTORY_SUCCESS,
  ACCOUNTING_INVENTORY_UPDATE_FILTER,
} from '../action_codes';

const INIT_STATE = {
  list: null,
  loading: false,
  error: '',
  meta: { page: 1, limit: null },
  filter: { query: '' },
  multiSort: ['name'],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACCOUNTING_FETCH_INVENTORY:
      return { ...state, loading: true };
    case ACCOUNTING_FETCH_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        meta: action.payload.meta,
      };
    case ACCOUNTING_FETCH_INVENTORY_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case ACCOUNTING_INVENTORY_UPDATE_FILTER:
      return { ...state, filter: action.payload.filter };
    default:
      return { ...state };
  }
};
