import {
  MOVEMENT_LOOKUP_ALL_MATERIALS,
  MOVEMENT_LOOKUP_ALL_MATERIALS_SUCCESS,
  MOVEMENT_LOOKUP_BATCHES,
  MOVEMENT_LOOKUP_BATCHES_SUCCESS,
  MOVEMENT_LOOKUP_CLEAR,
  MOVEMENT_LOOKUP_CODE,
  MOVEMENT_LOOKUP_CODE_ERROR,
  MOVEMENT_LOOKUP_CODE_SUCCESS,
  MOVEMENT_LOOKUP_DETAIL,
  MOVEMENT_LOOKUP_DETAIL_ERROR,
  MOVEMENT_LOOKUP_DETAIL_SUCCESS,
  MOVEMENT_LOOKUP_ERROR,
  MOVEMENT_LOOKUP_MATERIAL,
  MOVEMENT_LOOKUP_MATERIAL_SUCCESS,
  MOVEMENT_LOOKUP_SUPPLIERS,
  MOVEMENT_LOOKUP_SUPPLIERS_SUCCESS,
  MOVEMENT_LOOKUP_WAREHOUSES,
  MOVEMENT_LOOKUP_WAREHOUSES_SUCCESS,
} from '../action_codes';

export const movementLookupAllMaterials = () => ({
  type: MOVEMENT_LOOKUP_ALL_MATERIALS,
  payload: {},
});

export const movementLookupAllMaterialsSuccess = (data) => ({
  type: MOVEMENT_LOOKUP_ALL_MATERIALS_SUCCESS,
  payload: { data },
});

export const movementLookupMaterial = (url) => ({
  type: MOVEMENT_LOOKUP_MATERIAL,
  payload: { url },
});

export const movementLookupMaterialSuccess = (data) => ({
  type: MOVEMENT_LOOKUP_MATERIAL_SUCCESS,
  payload: { data },
});

export const movementLookupError = (message) => ({
  type: MOVEMENT_LOOKUP_ERROR,
  payload: { message },
});

export const movementLookupWarehouses = (materialId, materialTypeId) => ({
  type: MOVEMENT_LOOKUP_WAREHOUSES,
  payload: {
    filter: { material_id: materialId, material_type_id: materialTypeId },
  },
});

export const movementLookupWarehousesSuccess = (data) => ({
  type: MOVEMENT_LOOKUP_WAREHOUSES_SUCCESS,
  payload: { data },
});

export const movementLookupSuppliers = (materialId, warehouseId) => ({
  type: MOVEMENT_LOOKUP_SUPPLIERS,
  payload: { materialId, warehouseId },
});

export const movementLookupSuppliersSuccess = (data) => ({
  type: MOVEMENT_LOOKUP_SUPPLIERS_SUCCESS,
  payload: { data },
});

export const movementLookupClear = () => ({
  type: MOVEMENT_LOOKUP_CLEAR,
  payload: {},
});

export const movementLookupCode = (code) => ({
  type: MOVEMENT_LOOKUP_CODE,
  payload: { code },
});

export const movementLookupCodeSuccess = (result) => ({
  type: MOVEMENT_LOOKUP_CODE_SUCCESS,
  payload: { result },
});

export const movementLookupCodeError = (message) => ({
  type: MOVEMENT_LOOKUP_CODE_ERROR,
  payload: { message },
});

export const movementLookupBatches = (materialId, warehouseId) => ({
  type: MOVEMENT_LOOKUP_BATCHES,
  payload: { materialId, warehouseId },
});

export const movementLookupBatchesSuccess = (data) => ({
  type: MOVEMENT_LOOKUP_BATCHES_SUCCESS,
  payload: { data },
});

export const movementLookupDetail = (url) => ({
  type: MOVEMENT_LOOKUP_DETAIL,
  payload: { url },
});

export const movementLookupDetailSuccess = (data) => ({
  type: MOVEMENT_LOOKUP_DETAIL_SUCCESS,
  payload: { data },
});

export const movementLookupDetailError = (message) => ({
  type: MOVEMENT_LOOKUP_DETAIL_ERROR,
  payload: { message },
});
