import { all, fork } from 'redux-saga/effects';
import { getDefaultPageSize } from '../helpers/Utils';
import {
  fetchDetailActionTriad,
  fetchTableActionTriad,
  submitFormActionTriad,
} from './helpers';

const makeRedux = (prefix, listUrl, initialSort, initialFilter = null) => {
  const actions = {
    clearListAction: () => ({
      type: `${prefix}_CLEAR_LIST`,
      payload: null,
    }),
    fetchList: (multiSort, page, limit, filter) => ({
      type: `${prefix}_FETCH_LIST`,
      payload: { multiSort, page, limit, filter },
    }),
    fetchListSuccess: (data, meta) => ({
      type: `${prefix}_FETCH_LIST_SUCCESS`,
      payload: { data, meta },
    }),
    fetchError: (message) => ({
      type: `${prefix}_FETCH_ERROR`,
      payload: { message },
    }),
    fetchDetail: (url) => ({
      type: `${prefix}_FETCH_DETAIL`,
      payload: { url },
    }),
    fetchDetailSuccess: (data) => ({
      type: `${prefix}_FETCH_DETAIL_SUCCESS`,
      payload: { data },
    }),
    createAction: (url, data, onFinish) => ({
      type: `${prefix}_CREATE`,
      payload: { url, data, onFinish },
    }),
    updateAction: (url, data, onFinish) => ({
      type: `${prefix}_UPDATE`,
      payload: { url, data, onFinish },
    }),
    deleteAction: (url, onFinish) => ({
      type: `${prefix}_DELETE`,
      payload: { url, onFinish },
    }),
    actionSuccess: () => ({
      type: `${prefix}_ACTION_SUCCESS`,
      payload: null,
    }),
    actionError: (message, errors) => ({
      type: `${prefix}_ACTION_ERROR`,
      payload: { message, errors },
    }),
    updateFilter: (filter) => ({
      type: `${prefix}_UPDATE_FILTER`,
      payload: { filter },
    }),
    changeSort: (multiSort) => ({
      type: `${prefix}_CHANGE_SORT`,
      payload: { multiSort },
    }),
  };

  const initialState = {
    loading: false,
    error: '',
    list: null,
    meta: { page: 1, limit: getDefaultPageSize() },
    filter: initialFilter,
    multiSort: initialSort,
  };
  const reducer = (state = initialState, action) => {
    if (action.type === `${prefix}_CLEAR_LIST`) {
      return { ...state, list: null };
    }
    if (action.type === `${prefix}_FETCH_LIST`) {
      return { ...state, loading: true, error: '' };
    }
    if (action.type === `${prefix}_FETCH_LIST_SUCCESS`) {
      return {
        ...state,
        loading: false,
        error: '',
        list: action.payload.data,
        meta: action.payload.meta,
      };
    }
    if (action.type === `${prefix}_CHANGE_SORT`) {
      return {
        ...state,
        multiSort: action.payload.multiSort,
      };
    }
    if (action.type === `${prefix}_FETCH_ERROR`) {
      return { ...state, loading: false, error: action.payload.message };
    }
    if (action.type === `${prefix}_FETCH_DETAIL`) {
      return { ...state, loading: true, error: '' };
    }
    if (action.type === `${prefix}_FETCH_DETAIL_SUCCESS`) {
      return {
        ...state,
        loading: false,
        error: '',
        detail: action.payload.data,
      };
    }
    if (action.type === `${prefix}_CREATE`) {
      return { ...state, loading: true };
    }
    if (action.type === `${prefix}_UPDATE`) {
      return { ...state, loading: true };
    }
    if (action.type === `${prefix}_DELETE`) {
      return { ...state, loading: true };
    }
    if (action.type === `${prefix}_ACTION_SUCCESS`) {
      return { ...state, loading: false, error: '', list: null, detail: null };
    }
    if (action.type === `${prefix}_ACTION_ERROR`) {
      return { ...state, loading: false, error: action.payload.message };
    }
    if (action.type === `${prefix}_UPDATE_FILTER`) {
      return { ...state, filter: action.payload.filter };
    }
    return { ...state };
  };

  const fetchTableWatcher = fetchTableActionTriad(
    `${prefix}_FETCH_LIST`,
    listUrl,
    actions.fetchListSuccess,
    actions.fetchError
  );

  const fetchDetailWatcher = fetchDetailActionTriad(
    `${prefix}_FETCH_DETAIL`,
    actions.fetchDetailSuccess,
    actions.fetchError
  );

  const createAction = submitFormActionTriad(
    `${prefix}_CREATE`,
    'POST',
    actions.actionSuccess,
    actions.actionError,
  );

  const updateAction = submitFormActionTriad(
    `${prefix}_UPDATE`,
    'PUT',
    actions.actionSuccess,
    actions.actionError
  );

  const deleteAction = submitFormActionTriad(
    `${prefix}_DELETE`,
    'DELETE',
    actions.actionSuccess,
    actions.actionError
  );

  const saga = function* rootSaga() {
    yield all([
      fork(fetchTableWatcher.watcherFunction),
      fork(fetchDetailWatcher.watcherFunction),
      fork(createAction.watcherFunction),
      fork(updateAction.watcherFunction),
      fork(deleteAction.watcherFunction),
    ]);
  };

  return { actions, reducer, saga };
};

export default makeRedux;
